<template>
	<div>
		<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark color="deep-orange" class="pl-3 pr-3 flex-grow-0">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Disclaimers
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="dialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 ma-0 mx-auto" style="max-width: 936px">
					<div class="c7 doc-content">
						<p class="c9 c5">
							<span class="c1" />
						</p>
						<table class="mt-6">
							<tr class="c3">
								<td class="c6 header" colspan="1" rowspan="1" width="16%">
									<p class="c4 c5">
										<span class="c1" />
									</p>
								</td>
								<td class="c0 header" colspan="1" rowspan="1" width="28%">
									<p class="c4">
										<span class="c1 font-weight-bold">Standard Residential Capital and Interest</span>
									</p>
								</td>
								<td class="c2 header" colspan="1" rowspan="1" width="28%">
									<p class="c4">
										<span class="c1 font-weight-bold">RIO </span>
									</p>
								</td>
								<td class="c2 header" colspan="1" rowspan="1" width="28%">
									<p class="c4">
										<span class="c1 font-weight-bold">Lifetime</span>
									</p>
								</td>
							</tr>
							<tr class="c3">
								<td class="c6" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1 font-weight-bold">Minimum Age</span>
									</p>
								</td>
								<td class="c0" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.standard.minAge }}</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.rio.minAge }}</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.lifetime.minAge }}</span>
									</p>
								</td>
							</tr>
							<tr class="c3">
								<td class="c6" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1 font-weight-bold">Maximum Age</span>
									</p>
								</td>
								<td class="c0" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.standard.maxAge }}</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.rio.maxAge }}</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.lifetime.maxAge }}</span>
									</p>
								</td>
							</tr>
							<tr class="c3">
								<td class="c6" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1 font-weight-bold">Maximum LTV</span>
									</p>
								</td>
								<td class="c0" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.standard.maxLtv }}%</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.rio.maxLtv }}%</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ lifetimeMaxLtv }}%</span>
									</p>
								</td>
							</tr>
							<tr class="c3">
								<td class="c6" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1 font-weight-bold">Affordability</span>
									</p>
								</td>
								<td class="c0" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">Affordability calculations are used to make sure the customer(s) can afford monthly repayments. </span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">Affordability calculations are used to make sure the customer(s) can afford monthly repayments.</span>
									</p>
									<p class="c4">
										<span class="c1">On a joint case, customers must be able to cover monthly repayments in the event of a death of the customer with the higher post-retirement income.</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">Affordability calculations are not factored in for this product</span>
									</p>
								</td>
							</tr>
							<tr class="c3">
								<td class="c6" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1 font-weight-bold">Term</span>
									</p>
								</td>
								<td class="c0" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">The assumed length of term for this product is 15 years</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">The assumed length of term for this product is 15 years</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">The assumed length of term for this product is 15 years</span>
									</p>
								</td>
							</tr>
							<tr class="c3">
								<td class="c6" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1 font-weight-bold">Interest Rate</span>
									</p>
								</td>
								<td class="c0" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.standard.aer }}%</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.rio.aer }}%</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">{{ site.data.lifetime.aer }}%</span>
									</p>
								</td>
							</tr>
							<tr class="c3">
								<td class="c6" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1 font-weight-bold">Monthly Repayments</span>
									</p>
								</td>
								<td class="c0" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">The monthly repayment amount is calculated to ensure that the borrowing amount plus the accrued interest is paid off at the end of the 15-year term.</span>
									</p>
									<p class="c4 c5">
										<span class="c1" />
									</p>
									<p class="c4">
										<span class="c1">Example </span>
									</p>
									<p class="c4">
										<span class="c1">On a borrowing amount of &pound;100,000</span>
									</p>
									<p class="c4">
										<span class="c1">The full 15-year term is calculated at 5% with a monthly repayment of &pound;790.79.</span>
									</p>
									<p class="c4">
										<span class="c1">This pays off the borrowing amount, the accrued interest of 35,787.62 to give a total cost of borrowing of 135,787.62</span>
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">The monthly repayment amount is calculated by using the interest rate and borrowing amount to provide the annual interest accrued, this is then divided by 12.</span>
									</p>
									<p class="c4 c5">
										<span class="c1" />
									</p>
									<p class="c4">
										<span class="c1">Example</span>
									</p>
									<p class="c4">
										<span class="c1">On a borrowing amount of &pound;100,000</span>
									</p>
									<p class="c4">
										<span class="c1">The full 15-year term is calculated at 5.45% with a monthly repayment of &pound;454.17.</span>
									</p>
									<p class="c4 c5">
										<span class="c1" />
									</p>
								</td>
								<td class="c2" colspan="1" rowspan="1">
									<p class="c4">
										<span class="c1">NA</span>
									</p>
								</td>
							</tr>
						</table>
						<p class="c5 c9">
							<span class="c1" />
						</p>
					</div>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-common-help-dialog',

		data() {
			return {
				dialog: false
			};
		},

		computed: {
			...mapState('App', ['site']),


			lifetimeMaxLtv() {
				return Math.max(...Object.values(this.site.data.ltv));
			}
		},

		methods: {
			open() {
				this.dialog = true;
			}
		}
	};
</script>

<style type="text/css" lang="scss" scoped>
	@import url(https://themes.googleusercontent.com/fonts/css?kit=wAPX1HepqA24RkYW1AuHYA);

	ol {
		margin: 0;
		padding: 0;
	}

	table {
		border-spacing: 0;
		border-collapse: collapse;
		margin-right: auto;

		td,
		th {
			padding: 8px !important;
			vertical-align: top !important;
			text-align: left !important;
			font-size: 1rem !important;
			line-height: 1.6rem !important;
			border: 1pt solid #000000 !important;

			&.header {
				background-color: #e7e6e6;
			}

			p {
				text-align: left !important;
				font-size: 0.9rem !important;
				line-height: 1.3rem !important;
				margin-bottom: 0 !important;
			}
		}
	}
</style>
