import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import filters from '@/filters';
import validator from '@/validator';
import { Notifier } from '@airbrake/browser';

if (process.env.NODE_ENV !== 'development') {
	const airbrake = new Notifier({
		environment: 'production',
		projectId: 520868,
		projectKey: 'c5b8598feda49d41d6e2b004612a96c6'
	});

	Vue.config.errorHandler = function(err, vm, info) {
		airbrake.notify({
			error: err,
			params: { info: info }
		});
	};
}

Vue.config.productionTip = false;
Vue.config.silent = true;

Vue.use(validator);
Vue.use(filters);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount('#app');
