/**
 * @namespace Filters
 * @exports Filters/formatDate
 * @description Format date dd/mm/yyyy
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default (date) => {
	if (!date) return;

	const [year, month, day] = date.split('-');

	return `${day}/${month}/${year}`;
};
