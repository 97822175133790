<!--
	@name common-dialog
	@description Display a dialog with headers and or footers
	@date 2020/03/04
	@license no license
	@copywrite Answers In Retirement Limited
-->

<!--
	@example

	<common-dialog ref="dialog" max-width="600" max-height="500" color="red">
		<template v-slot:header>Your Header</template>
		<template v-slot:body>
			<p>Some content</p>
		</template>
		<template v-slot:footer>Your Footer</template>
	</common-dialog>

	this.$refs.dialog.open();
-->

<template>
	<v-dialog v-model="active" scrollable :max-width="maxWidth" :max-height="maxHeight" :component="$options.name" :eager="eager" @input="close">
		<v-card>
			<v-toolbar :dense="dense" :dark="dark" :color="color">
				<div class="header">
					<slot name="header" />
				</div>
				<v-spacer />
				<slot v-show="!!$slots['header-action']" name="header-action" />
				<v-btn v-show="showClose" icon @click="close">
					<v-icon>mdi-close-box</v-icon>
				</v-btn>
			</v-toolbar>
			<v-divider />
			<v-card-text>
				<slot name="body" />
			</v-card-text>
			<v-divider />
			<div v-show="!!$slots['footer']" class="footer">
				<slot name="footer" />
			</div>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: 'common-dialog',

		props: {
			color: { type: String, default: 'blue-grey' },
			dark: { type: Boolean, default: true },
			eager: { type: Boolean, default: false },
			dense: { type: Boolean, default: false },
			maxWidth: { type: Number, default: 900 },
			maxHeight: { type: Number, default: 500 },
			showClose: { type: Boolean, default: true }
		},

		data: () => ({
			active: false
		}),

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.active = true;
			},

			/**
			 * @name close
			 * @description Close dialog and set properties
			 */
			close() {
				this.active = false;
				this.$emit('closed', true);
			}
		}
	};
</script>

<style scoped>
	.header {
		font-size: 1.1rem;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: normal;
	}

	.footer {
		background-color: #f4f4f4;
	}
</style>
