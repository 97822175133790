<!--
	@name common-base-dynamic-natural-language-form
	@description Natural language form
	@date 2020/03/28
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<form :class="textClass" :style="textStyle" @submit.prevent="submit">
		<span v-for="(field, index) in parsedSchema" :key="index">
			<span v-if="field.type === 'plain-text' && displayField(field)" :data-type="field.type" :data-display="field.display" :class="field.class" v-html="field.display" />
			<template v-if="(field.type === 'text' || field.type === 'number' || field.type === 'date') && displayField(field)">
				<span v-if="field.prefix_text">
					{{ field.prefix_text }}
				</span>
				<v-menu v-model="menu[field.name]" :close-on-content-click="false" transition="scale-transition" :nudge-width="250" :disabled="readonly">
					<template #activator="{ on }">
						<span v-if="field.currency" :class="{ 'inline-field': !readonly }" :style="`color: ${themeColor(field.name)}`" v-on="on"> {{ field.prefix }}{{ formData[field.name] | numFormat(field.format || '0,00.00') }} </span>
						<span v-else-if="field.percentage" :class="{ 'inline-field': !readonly }" :style="`color: ${themeColor(field.name)}`" v-on="on"> {{ formData[field.name] }}{{ field.suffix }} </span>
						<span v-else :class="{ 'inline-field': !readonly }" :style="`color: ${themeColor(field.name)}`" v-on="on"> {{ field.prefix }}{{ formData[field.name] }} </span>
					</template>
					<v-card>
						<validation-provider v-slot="{ errors }" :name="field.display" :rules="field.rules">
							<v-card-text>
								<v-text-field v-if="field.type === 'text'" v-model="menuField[field.name]" type="text" :label="field.display" :prefix="field.prefix" :hide-details="!errors.length" :error-messages="errors" />
								<v-text-field
									v-else-if="field.type === 'number'"
									v-model.number="menuField[field.name]"
									type="number"
									:label="field.display"
									:step="(field.rules || '').includes('decimal') ? 'any' : false"
									:prefix="field.prefix"
									:hide-details="!errors.length"
									:error-messages="errors"
								/>
								<template v-else-if="field.type === 'date'">
									<v-menu v-model="datePickerMenu[field.name]" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
										<template #activator="{ on }">
											<validation-provider v-slot="{ errors }" :name="field.display" :rules="field.rules">
												<v-text-field
													v-model="menuField[field.name]"
													:label="field.display"
													:hide-details="!errors.length"
													:error-messages="errors"
													dense
													autocomplete="off"
													class="pt-4"
													@input="parseDate(field.name)"
													@focus="dateFieldFocus(field)"
													v-on="on"
												/>
											</validation-provider>
										</template>

										<v-date-picker v-model="datePickerModel[field.name]" :disabled="field.disabled" :label="field.display" outlined dense @input="parseDateToText(field.name)" />
									</v-menu>
								</template>
							</v-card-text>

							<v-card-actions>
								<v-spacer />

								<v-btn text @click="menu[field.name] = false"> Cancel </v-btn>
								<v-btn :color="theme" text @click="updateField(field.name, errors)"> Save </v-btn>
							</v-card-actions>
						</validation-provider>
					</v-card>
				</v-menu>
			</template>
			<template v-if="field.type === 'select'">
				<v-menu v-model="menu[field.name]" close-on-content-click transition="scale-transition" :disabled="readonly">
					<template #activator="{ on }">
						<span :class="{ 'inline-field': !readonly }" :style="`color: ${themeColor(field.name)}`" v-on="on"> {{ getDisplayValue(field) }} </span>
					</template>
					<v-card @click="updateSelectField(field.name)">
						<v-list>
							<v-list-item-group v-model="formData[field.name]" color="primary">
								<v-list-item v-for="(item, index) in field.options" :key="index" :value="item.value" :disabled="item.value == formData[field.name]">
									<v-list-item-content>
										<v-list-item-title v-text="item.text" />
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-card>
				</v-menu>
			</template>
		</span>

		<div v-if="submitButton" class="text-center mt-6">
			<v-btn :color="submitButtonColor" :dark="submitButton.dark" :loading="submitButtonLoading" type="submit">
				<v-icon v-if="submitButton.icon" left>
					{{ submitButton.icon }}
				</v-icon>
				{{ submitButton.text }}
			</v-btn>
		</div>
	</form>
</template>

<script>
	import { ValidationProvider } from 'vee-validate';
	import colors from 'vuetify/lib/util/colors';
	import { get } from 'lodash';

	export default {
		name: 'common-base-dynamic-natural-language-form',

		components: {
			ValidationProvider
		},

		props: {
			formSchema: { type: Object, required: true },
			formData: { type: Object, required: true },
			submitButtonIcon: { type: [Boolean, String], default: false },
			submitButtonLoading: { type: Boolean, default: false },
			submitButton: { type: [Boolean, Object], default: () => ({ icon: false, text: 'Submit', color: null, dark: true }) },
			theme: { type: String, default: 'green.base' },
			textClass: { type: String, default: 'text-body-1' },
			textStyle: { type: String, default: '' },
			validated: { type: Boolean, default: false },
			readonly: { type: Boolean, default: false }
		},

		data() {
			return {
				menu: {},
				menuField: {},
				datePickerModel: {},
				datePickerMenu: {},
				validation: {}
			};
		},

		computed: {
			submitButtonColor() {
				if (this.submitButton.color) return get(colors, this.submitButton.color);
				return 'primary';
			},

			/**
			 * @name parsedSchema
			 * @description Parses form text into a proper form schema to generate the form
			 * @returns {Array} Form schema
			 */
			parsedSchema() {
				let schema = [];
				let fields = this.formSchema.text.split(/\{([^}]*)\}/g);

				fields.map((item) => {
					if (item) {
						let field = this.formSchema.fields.find((schema) => schema.name === item);
						if (field) schema.push(field);
						else if (item) {
							schema.push({
								type: 'plain-text',
								display: item
							});
						}
					}
				});

				return schema;
			},
			/**
			 * @name isValid
			 * @description Checks if the form is valid
			 */
			isValid() {
				// return if all fields are valid except the ones that are not displayed
				return this.validated || Object.keys(this.validation).every((key) => this.validation[key] || !this.displayField(this.formSchema.fields.find((schema) => schema.name === key)));
			}
		},

		watch: {
			menu: {
				handler() {
					Object.keys(this.menu).map((key) => {
						this.menuField[key] = this.formData[key];
					});
				},
				deep: true
			}
		},

		mounted() {
			Object.keys(this.formData).map((key) => {
				this.$set(this.validation, key, false);
			});
		},

		methods: {
			themeColor(fieldName) {
				return !this.readonly ? (this.validation[fieldName] || this.validated ? colors.green.darken1 : colors.red.darken1) : colors.blue.darken3;
			},

			displayField(field) {
				if (!field) return false;
				if (field.conditions && field.conditions.show) {
					for (let i = 0; i < field.conditions.show.length; i++) {
						if (!this.checkCondition(field.conditions.show[i])) return false;
					}
				}
				return true;
			},

			checkCondition(condition) {
				let target = this.formData[condition.name];

				if (condition.type === 'equals' && target == condition.value) return true;
				if (condition.type === 'notEquals' && target != condition.value) return true;
				if (condition.type === 'in_array') {
					if (condition.value.includes(target)) return true;
					if (condition.options != null && !target.length && condition.options.showWhenEmpty) return true;
				}
				return false;
			},

			/**
			 * @name updateField
			 * @description Updates the actual form data and closes the menu if the field is valid
			 * @param {Object} field Field to be updated
			 * @param {Array} errors Error list
			 */
			updateField(field, errors) {
				if (!errors || !errors.length) {
					this.validation[field] = true;
					this.formData[field] = this.menuField[field];
					this.menu[field] = false;
					this.$emit('input', this.formData, this.isValid);
				}
			},

			updateSelectField(field) {
				this.validation[field] = true;
				this.$emit('input', this.formData, this.isValid);
			},

			/**
			 * @name getDisplayValue
			 * @description Returns display value of the select field to show meaningful data
			 * @param {Object} field Field to be displayed
			 * @returns {String} Display value
			 */
			getDisplayValue(field) {
				return field.options.find((option) => option.value == this.formData[field.name]).text;
			},

			/**
			 * @name parseDateToText
			 * @description Parse datepicker value into text field
			 * @param {String} fieldName field name
			 */
			parseDateToText(fieldName) {
				let date = this.datePickerModel[fieldName];

				if (!date) return null;

				const [year, month, day] = date.split('-');
				this.menuField[fieldName] = `${day}/${month}/${year}`;
				this.$set(this.datePickerMenu, fieldName, false);
			},

			/**
			 * @name parseDate
			 * @description Parse text field date value into datepicker
			 * @param {String} fieldName field name
			 */
			parseDate(fieldName) {
				let regex = /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
				let date = this.menuField[fieldName];
				let isValid = regex.test(date);
				let pickerValue = null;

				if (isValid) {
					const [day, month, year] = date.split('/');
					try {
						pickerValue = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
					} catch {
						pickerValue = null;
					}
				}

				this.$set(this.datePickerModel, fieldName, pickerValue);
			},

			/**
			 * @name dateFieldFocus
			 * @description Event handler for date field focus
			 * @param {Object} field
			 */
			dateFieldFocus(field) {
				if (!this.menuField[field.name] && (field.valueDefault || field.ageRelativeDob)) {
					this.$set(this.menuField, field.name, field.valueDefault || field.ageRelativeDob);
				}

				this.$nextTick(() => {
					this.parseDate(field.name);
				});
			},

			/**
			 * @name submit
			 * @description Dispatches a form submit event that passes form data
			 */
			submit() {
				this.$emit('submit', this.formData);
			},

			/**
			 * @name forceValidate
			 * @description Forces validation on all fields
			 */
			forceValidate() {
				Object.keys(this.validation).map((key) => {
					this.validation[key] = true;
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	@import '~vuetify/src/styles/styles.sass';
	form.title {
		line-height: 1.8;
	}

	form {
		line-height: 1.8 !important;
	}

	.inline-field {
		display: inline-block;
		font-weight: 600;
		border-bottom: 2px dotted #979797;
		line-height: 1.3;
		cursor: pointer;
	}

	::v-deep .v-item-group {
		cursor: pointer;
	}
</style>
