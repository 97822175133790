<template>
	<div>
		<v-row>
			<v-col cols="12">
				<v-sheet elevation="3" rounded class="pa-6">
					<div class="d-flex mb-1">
						<p class="text-h6 primary--text mb-0">
							Customer Details
						</p>
						<v-spacer />
						<p class="text-body-2 primary--text mb-0">
							Step 1 of 3
						</p>
					</div>

					<p class="mb-6">
						Enter your customer's basic details to start building a picture of which products could be available for them.
					</p>

					<v-sheet rounded class="pa-6 grey lighten-5" style="background: linear-gradient(135deg, rgb(245, 245, 245) 35%, rgba(25, 118, 210, 0.15) 100%)">
						<v-row no-gutters>
							<v-col class="grow">
								<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
									Customer 1
								</p>
								<dynamic-natural-language-form ref="personalForm" :form-schema="personalFormSchema" :form-data="personalFormData" :submit-button="false" :validated="validation.personalForm" @input="personalFormUpdated" />
							</v-col>
							<v-col class="shrink pl-6">
								<v-tooltip bottom max-width="400">
									<template #activator="{ on, attrs }">
										<v-btn icon v-bind="attrs" class="mt-n1" v-on="on">
											<v-icon color="primary">
												mdi-information
											</v-icon>
										</v-btn>
									</template>
									<span>Let us know whether your customer(s) have already retired or the age they are planning to retire. If they are planning to retire in the next five years, affordability will be based on their post-retirement income. For assistance on
										entering the customers income, click the 'Help' button on top right corner.</span>
								</v-tooltip>
							</v-col>
						</v-row>

						<v-row v-if="numberOfClients === 2" no-gutters class="mt-6">
							<v-col class="grow">
								<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
									Customer 2
								</p>
								<dynamic-natural-language-form ref="personalForm2" :form-schema="personalFormSchema" :form-data="personalFormData2" :submit-button="false" :validated="validation.personalForm2" @input="personalForm2Updated" />
							</v-col>
						</v-row>

						<v-row no-gutters class="mt-6">
							<v-col class="grow">
								<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
									Mortgage Details
								</p>
								<dynamic-natural-language-form ref="mortgageForm" :form-schema="mortgageFormSchema" :form-data="mortgageFormData" :submit-button="false" :validated="validation.mortgageForm" @input="mortgageFormUpdated" />
							</v-col>
							<v-col class="shrink pl-6">
								<v-tooltip bottom max-width="400">
									<template #activator="{ on, attrs }">
										<v-btn icon v-bind="attrs" class="mt-n1" v-on="on">
											<v-icon color="primary">
												mdi-information
											</v-icon>
										</v-btn>
									</template>
									<span>Here, we calculate Loan to Value by comparing the amount your customer(s) would like to borrow with the value of their property.</span>
								</v-tooltip>
							</v-col>
						</v-row>

						<v-row no-gutters class="mt-6">
							<v-col class="grow">
								<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
									Expenditure Details
								</p>
								<dynamic-natural-language-form ref="expenditureForm" :form-schema="expenditureFormSchema" :form-data="expenditureFormData" :submit-button="false" :validated="validation.expenditureForm" @input="expenditureFormUpdated" />
								<a color="primary" class="text-body-2 d-block mt-1" @click="openExpensesDialog"> + Add monthly expenses </a>
							</v-col>
							<v-col class="shrink pl-6">
								<v-tooltip bottom max-width="400">
									<template #activator="{ on, attrs }">
										<v-btn icon v-bind="attrs" class="mt-n1" v-on="on">
											<v-icon color="primary">
												mdi-information
											</v-icon>
										</v-btn>
									</template>
									<span>This field captures customer expenditure which is then used to calculate their affordability. For assistance, click the 'Help' button on top right corner.</span>
								</v-tooltip>
							</v-col>
						</v-row>
					</v-sheet>

					<v-row v-if="borrowingIntoRetirement" no-gutters class="mt-6">
						<v-col>
							<v-alert type="info" color="warning" dense border="left" class="py-3 mb-0">
								<div class="text-body-1 font-weight-bold">
									Borrowing into retirement
								</div>
								<div class="text-body-2 mt-2">
									Your customer(s) retirement age and mortgage term are overlapping, which will mean they are lending into retirement. Having a mortgage term that runs into their retirement might impact the products for which they are eligible.
								</div>
							</v-alert>
						</v-col>
					</v-row>

					<v-row v-if="ageDisclaimer" no-gutters class="mt-6">
						<v-col>
							<v-alert type="info" color="warning" dense border="left" class="py-3 mb-0">
								<div class="text-body-1 font-weight-bold">
									Payment Term Lifetime Mortgage (PTLM)
								</div>
								<div class="text-body-2 mt-2">
									Your customers age means they could be eligible for a Payment Term Lifetime Mortgage, customers commit to monthly full interest payments until they either retire or reach the age of 75, whichever is sooner.
								</div>
								<div class="text-body-2 mt-2 font-weight-bold">
									As a last resort, their home may be repossessed if they do not keep up with payments.
								</div>
							</v-alert>
						</v-col>
					</v-row>

					<div class="d-flex mt-6">
						<v-btn color="primary" large outlined @click="$emit('go-back')">
							Back
						</v-btn>
						<v-spacer />
						<v-btn color="primary" large :disabled="!isAllValid" @click="nextStep">
							Next Step
						</v-btn>
					</div>
				</v-sheet>
			</v-col>
		</v-row>

		<common-dialog ref="expensesDialog" :max-width="1000" color="primary">
			<template #header>
				Monthly Expenses
			</template>
			<template #body>
				<v-row class="mt-4">
					<v-col cols="4">
						<validation-provider v-slot="{ errors }" name="Monthly committed borrowing" :rules="'decimal:2|max_value:1000000'">
							<v-text-field v-model.number="expenseModel.borrowing" type="number" :error-messages="errors" prefix="£" label="Monthly committed borrowing" outlined dense />
						</validation-provider>
					</v-col>
					<v-col cols="4">
						<validation-provider v-slot="{ errors }" name="Monthly regular financial commitments" :rules="'decimal:2|max_value:1000000'">
							<v-text-field v-model.number="expenseModel.financial" type="number" :error-messages="errors" prefix="£" label="Monthly regular financial commitments" outlined dense />
						</validation-provider>
					</v-col>
					<v-col cols="4">
						<validation-provider v-slot="{ errors }" name="Monthly utility costs" :rules="'decimal:2|max_value:1000000'">
							<v-text-field v-model.number="expenseModel.utility" type="number" :error-messages="errors" prefix="£" label="Monthly utility costs" outlined dense />
						</validation-provider>
					</v-col>
				</v-row>
				<v-row class="mt-n6">
					<v-col cols="4">
						<validation-provider v-slot="{ errors }" name="Monthly living costs" :rules="'decimal:2|max_value:1000000'">
							<v-text-field v-model.number="expenseModel.living" type="number" :error-messages="errors" prefix="£" label="Monthly living costs" outlined dense />
						</validation-provider>
					</v-col>
					<v-col cols="4">
						<validation-provider v-slot="{ errors }" name="Other costs" :rules="'decimal:2|max_value:1000000'">
							<v-text-field v-model.number="expenseModel.other" type="number" :error-messages="errors" prefix="£" label="Other costs" outlined dense />
						</validation-provider>
					</v-col>
					<!-- Show total with h1 -->
					<v-col cols="4">
						<v-alert color="info" dark dense>
							<p class="body-1 mb-0">
								<span class="font-weight-bold">Total: £{{ totalExpenses | numFormat('0,00.00') }}</span>
							</p>
						</v-alert>
					</v-col>
				</v-row>
				<v-divider class="mt-3 mb-5" />
				<div class="d-flex mt-2">
					<v-btn color="error" large outlined @click="clearExpenses">
						Clear
					</v-btn>
					<v-spacer />
					<v-btn color="primary" large class="ml-3" @click="saveExpenses">
						Submit
					</v-btn>
				</div>
			</template>
		</common-dialog>

		<common-dialog ref="helpDialog" :max-width="800" color="primary">
			<template #header>
				Customer Details
			</template>
			<template #body>
				<v-sheet class="pt-5">
					<p class="text-body-1">
						In step one you are presented with a series of sentences to help gather the information to help calculate eligibility.
					</p>
					<p class="text-body-1">
						Any fields presented with <span class="inline-field error--text">red underlined font</span> need to be interacted with. When you have interacted with them they will change to <span class="inline-field success--text">green</span>. Once all fields are green
						you can navigate to the next step.
					</p>
					<p class="text-body-1 mb-0">
						As you fill out each section, the mini table on the right hand side will change colour. You can expand this view by clicking on the
						<v-btn icon class="">
							<v-icon color="primary">
								mdi-table-eye
							</v-icon>
						</v-btn>
						button towards the top of the mini table to see why the customer(s) criteria is ineligible.
					</p>
				</v-sheet>
			</template>
			<template #footer>
				<v-card-actions class="pa-4">
					<v-checkbox v-model="hideHelpDialog" hide-details label="Do not show this again" class="ma-0 pa-0" @change="helpDialogChanged" />
				</v-card-actions>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { ValidationProvider } from 'vee-validate';
	import { $localStorage } from '@/utils/storage';
	import CommonDialog from '@/components/common/dialog';
	import DynamicNaturalLanguageForm from '@/components/common/dynamic-natural-language-form';

	export default {
		components: {
			ValidationProvider,
			CommonDialog,
			DynamicNaturalLanguageForm
		},

		props: {
			numberOfClients: { type: Number, required: true },
			validation: { type: Object, required: true },
			personalFormData: { type: Object, required: true },
			personalFormData2: { type: Object, required: true },
			mortgageFormData: { type: Object, required: true },
			expenditureFormData: { type: Object, required: true },
			expenses: { type: Object, required: true }
		},

		data() {
			return {
				expenseModel: {},
				personalFormSchema: {
					text: 'Customer is {customerAge} years of age and {isRetired} {plannedRetirementAge}. They have a monthly net income of {income}{incomeRetirementText}{incomeRetirement}.',
					fields: [
						{ type: 'number', name: 'customerAge', display: 'Age', rules: 'required|numeric|min_value:18|max_value:150' },
						{
							type: 'select',
							name: 'isRetired',
							display: 'Is Retired',
							rules: 'required',
							options: [
								{ value: true, text: 'is already retired' },
								{ value: false, text: 'with a planned retirement age of' }
							]
						},
						{ type: 'number', name: 'plannedRetirementAge', display: 'Planned Retirement Age', rules: 'required|numeric|min_value:18|max_value:150', conditions: { show: [{ name: 'isRetired', type: 'equals', value: false }] } },
						{ type: 'plain-text', name: 'incomeRetirementText', display: '. Estimated post retirement net monthly income is ', conditions: { show: [{ name: 'isRetired', type: 'equals', value: false }] } },
						{ type: 'number', name: 'income', prefix: '£', display: 'Income', rules: 'required|decimal:2', currency: true, conditions: { show: [{ name: 'isRetired', type: 'equals', value: false }] } },
						{ type: 'number', name: 'incomeRetirement', prefix: '£', display: 'Post Retirement Income', rules: 'required|decimal:2', currency: true }
					]
				},
				mortgageFormSchema: {
					text: "The customer's property is valued at {propertyValue} and the requested borrowing amount is {requestedBorrowingAmount}.",
					fields: [
						{ type: 'number', name: 'propertyValue', prefix: '£', display: 'Property Value', rules: 'required|decimal:2', currency: true },
						{ type: 'number', name: 'requestedBorrowingAmount', prefix: '£', display: 'Requested Borrowing Amount', rules: 'required|decimal:2', currency: true }
					]
				},
				expenditureFormSchema: {
					text: 'This Customer has monthly outgoings of {expenditure}.',
					fields: [{ type: 'number', name: 'expenditure', prefix: '£', display: 'Expenditure', rules: 'required|decimal:2', currency: true }]
				},
				incomeFormSchema: { text: '' },
				hideHelpDialog: false
			};
		},

		computed: {
			/**
			 * @name borrowingIntoRetirement
			 * @description Checks if the customer is borrowing into retirement
			 * @returns {Boolean}
			 */
			borrowingIntoRetirement() {
				const customer1Retired = this.personalFormData.isRetired || this.personalFormData.plannedRetirementAge - this.personalFormData.customerAge < 15;
				const customer2Retired = this.personalFormData2.isRetired || this.personalFormData2.plannedRetirementAge - this.personalFormData2.customerAge < 15;

				return (customer1Retired && this.validation.personalForm) || (this.numberOfClients === 2 && this.validation.personalForm2 && customer2Retired);
			},

			/**
			 * @name ageDisclaimer
			 * @description Checks if the customer is eligible for a Payment Term Lifetime Mortgage
			 * @returns {Boolean}
			 */
			ageDisclaimer() {
				const customer1Age = this.personalFormData.customerAge;
				const customer2Age = this.personalFormData2.customerAge;

				return (customer1Age >= 50 && customer1Age <= 74 && this.validation.personalForm) || (this.numberOfClients === 2 && customer2Age >= 50 && customer2Age <= 74 && this.validation.personalForm2);
			},

			/**
			 * @name isAllValid
			 * @description Checks if all forms are valid
			 * @returns {Boolean}
			 */
			isAllValid() {
				return Object.keys(this.validation)
					.filter((v) => v !== 'personalForm2' || this.numberOfClients == 2)
					.every((key) => this.validation[key] === true);
			},

			/**
			 * @name totalExpenses
			 * @description Calculates the total expenses
			 * @returns {Number}
			 */
			totalExpenses() {
				return Object.values(this.expenseModel).reduce((a, b) => (Number(a) || 0) + (Number(b) || 0), 0);
			}
		},

		mounted() {
			this.$vuetify.goTo(0, { duration: 0 });
			if (!$localStorage.get('hideStep2HelpDialog')) this.$refs.helpDialog.open();
		},

		methods: {
			/**
			 * @name openExpensesDialog
			 * @description Opens the expenses dialog
			 */
			openExpensesDialog() {
				this.expenseModel = { ...this.expenses };
				this.$refs.expensesDialog.open();
			},

			/**
			 * @name clearExpenses
			 * @description Clears the expenses
			 */
			clearExpenses() {
				this.expenses = {};
				this.expenseModel = {};
				this.expenditureFormData.expenditure = this.expenditureFormData.expenditure || (this.numberOfClients > 1 ? 1000 : 500);
			},

			/**
			 * @name saveExpenses
			 * @description Saves the expenses
			 */
			saveExpenses() {
				this.$refs.expenditureForm.forceValidate();
				this.validation.expenditureForm = true;
				this.expenditureFormData.expenditure = this.totalExpenses;
				this.$refs.expensesDialog.close();
				this.$emit('expenses-updated', this.expenseModel);
			},

			/**
			 * @name personalFormUpdated
			 * @description Updates the validation state of the personal form
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			personalFormUpdated(formData, isValid) {
				this.$set(this.validation, 'personalForm', isValid);
			},

			/**
			 * @name personalForm2Updated
			 * @description Updates the validation state of the personal form for customer 2
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			personalForm2Updated(formData, isValid) {
				this.$set(this.validation, 'personalForm2', isValid);
			},

			/**
			 * @name mortgageFormUpdated
			 * @description Updates the validation state of the mortgage form
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			mortgageFormUpdated(formData, isValid) {
				this.$set(this.validation, 'mortgageForm', isValid);
			},

			/**
			 * @name expenditureFormUpdated
			 * @description Updates the validation state of the expenditure form
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			expenditureFormUpdated(formData, isValid) {
				this.$set(this.validation, 'expenditureForm', isValid);
				this.expenses = {};
			},

			/**
			 * @name nextStep
			 * @description Emits the next step event
			 */
			nextStep() {
				this.$emit('next-step');
			},

			/**
			 * @name helpDialogChanged
			 * @description Updates the hideHelpDialog state on local storage
			 */
			helpDialogChanged() {
				$localStorage.set('hideStep2HelpDialog', this.hideHelpDialog);
			}
		}
	};
</script>

<style scoped lang="scss">
	.inline-field {
		display: inline-block;
		font-weight: 600;
		border-bottom: 2px dotted #979797;
		line-height: 1.3;
		cursor: pointer;
	}
</style>
