<!--
	@name app-common-privacy
	@description Privacy Policy
	@date 2020/06/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Privacy Policy
		</template>
		<template #body>
			<body class="pt-8">
				<p class="c2">
					<span class="c0">LATER LIFE LENDING TOOL</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c0">This privacy notice is intended for advisers only. </span>
				</p>
				<p class="c2">
					<span class="c0">This privacy notice was last updated in January 2024.</span>
				</p>
				<p class="c2">
					<span class="c0">At &nbsp;Air we look after your details carefully. We adhere strictly to data protection laws.</span>
				</p>
				<p class="c2">
					<span class="c0">This notice describes how we collect, use and look after the information you provide us when you enquire about and use our services. This notice may change from time to time as our processes change over time, which will be reflected in this document.
					</span>
				</p>
				<p class="c2">
					<span class="c0">Use of this tool within this website is intended for use by UK Advisers only and it only refers to UK products. </span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">WHO WE ARE</span>
				</p>
				<p class="c2">
					<span class="c0">COMPANY DETAILS AND REGULATION</span>
				</p>
				<p class="c2">
					<span class="c0">
						Answers in Retirement Ltd trading as Air is a limited company registered in England No 09991416. Registered Office: PO Box GL64 4QT, Southgate House, Southgate Street, Gloucester, Gloucestershire, United Kingdom, GL1 1UD. The company is not regulated by
						the Financial Conduct Authority.
					</span>
				</p>
				<p class="c2">
					<span class="c0">This privacy notice references to “we”, “us” and “our” are to Air. References to “our Website” or “the Website” are to www.lllnavigator.co.uk </span>
				</p>
				<p class="c2">
					<span class="c0">Where we process your Personal Data we are acting as a Data Controller.</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c17">Please contact us if you have any questions or complaints about our privacy policy or information we hold about you by email to </span>
					<span class="c14"><a class="c6" href="mailto:privacy@airlaterlife.co.uk">privacy@airlaterlife.co.uk</a></span>
					<span class="c17">&nbsp;or write to us at the following address; Answers in Retirement Limited, Southgate House, Southgate Street, Gloucester, Gloucestershire, United Kingdom, GL1 1UD. You can also contact us by telephone on 0800 294 5097. &nbsp;Calls may be recorded and
						may be monitored. &nbsp;</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">WHAT INFORMATION WE COLLECT AND HOW</span>
				</p>
				<p class="c2">
					<span class="c0">THE INFORMATION WE COLLECT INCLUDES:</span>
				</p>
				<p class="c2">
					<span class="c0">Customer Data</span>
				</p>
				<ul class="c10 lst-kix_list_1-0 start">
					<li class="c2 c8 c13 li-bullet-0">
						<span class="c0">Any personal details you knowingly provide us using the tool and email, such as customer name, customer ID, address, telephone number etc.</span>
					</li>
				</ul>
				<p class="c2">
					<span class="c0">Adviser Data</span>
				</p>
				<ul class="c10 lst-kix_list_1-0">
					<li class="c4 li-bullet-0">
						<span class="c0">Your FCA number</span>
					</li>
					<li class="c4 li-bullet-0">
						<span class="c0">Your marketing preferences.</span>
					</li>
					<li class="c4 li-bullet-0">
						<span class="c0">Your IP address, browser type and browsing activity whilst on our website. Your IP address is a string of numbers unique to your computer that is recorded by our web server when you request any page or component on the website. </span>
					</li>
					<li class="c2 c8 c13 li-bullet-0">
						<span class="c0">We may also store information about you using cookies (files which are sent by us to your computer or other access device) which we can access when you visit our site in future. We do this to recognise you and your preferred settings, and it saves you
							from re-entering information on return visits to the site. If you want to delete any cookies that are already on your computer, please refer to the instructions for your file management software to locate the file or directory that stores cookies. You
							can view our full cookie notice <a href="/cookie-policy" target="_blank">here</a>
						</span>
					</li>
				</ul>
				<p class="c2">
					<span class="c0">Information on deleting or controlling cookies is available at the About Cookies website. </span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">WHAT WE DO WITH THE INFORMATION</span>
				</p>
				<p class="c2">
					<span class="c0">Customer Information</span>
				</p>
				<p class="c2">
					<span class="c0">Any customer personal information we collect about will be used in accordance with the data protection laws. The details we collect will be used: </span>
				</p>
				<p class="c2 c8">
					<span class="c0">To process a calculation, advise on products and produce a downloadable report.</span>
				</p>
				<p class="c2">
					<span class="c0">Adviser Information</span>
				</p>
				<ul class="c10 lst-kix_list_2-0 start">
					<li class="c4 li-bullet-0">
						<span class="c0">To understand how the tool is being used.</span>
					</li>
					<li class="c4 li-bullet-0">
						<span class="c0">To personalise content on our website.</span>
					</li>
					<li class="c2 c8 c13 li-bullet-0">
						<span class="c0">To tailor our website better to your needs.</span>
					</li>
				</ul>
				<p class="c2 c3">
					<span class="c7" />
				</p>
				<p class="c2">
					<span class="c7">HOW WE USE THE DATA</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<a id="t.8f42a87f3539045e7b66868df90ffc03b833dd25" /><a id="t.0" />
				<table class="c15">
					<tr class="c11">
						<td class="c5" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c7">PURPOSE</span>
							</p>
						</td>
						<td class="c9" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c7">DATA TYPES</span>
							</p>
						</td>
						<td class="c12" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c7">LAWFUL BASIS</span>
							</p>
							<p class="c1 c3">
								<span class="c7" />
							</p>
						</td>
					</tr>
					<tr class="c11">
						<td class="c5" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">To better understand tool usage</span>
							</p>
						</td>
						<td class="c9" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">FCA number</span>
							</p>
						</td>
						<td class="c12" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">We have a legitimate business interest to collect this information so as to improve the tool </span>
							</p>
						</td>
					</tr>
					<tr class="c11">
						<td class="c5" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">To provide you with the information you have requested</span>
							</p>
						</td>
						<td class="c9" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">Customer Name</span>
							</p>
							<p class="c1">
								<span class="c0">Customer ID</span>
							</p>
							<p class="c1">
								<span class="c0">Customer income &amp; expenditure details</span>
							</p>
							<p class="c1">
								<span class="c0">Customer affordability details</span>
							</p>
							<p class="c1 c3">
								<span class="c0" />
							</p>
						</td>
						<td class="c12" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">This is necessary for the performance of potentially entering into a contract to provide the services you have enquired about.</span>
							</p>
						</td>
					</tr>
					<tr class="c11">
						<td class="c5" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">We may use cookies to maintain the structure and performance of our websites.</span>
							</p>
						</td>
						<td class="c9" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">IP Address</span>
							</p>
							<p class="c1">
								<span class="c0">User Name</span>
							</p>
							<p class="c1">
								<span class="c0">Site activity</span>
							</p>
						</td>
						<td class="c12" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">These are essential cookies.</span>
							</p>
						</td>
					</tr>
					<tr class="c11">
						<td class="c5" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">We may use cookies to monitor website usage to improve our online services for you</span>
							</p>
						</td>
						<td class="c9" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">IP Address</span>
							</p>
							<p class="c1">
								<span class="c0">User Name</span>
							</p>
							<p class="c1">
								<span class="c0">Site Activity</span>
							</p>
						</td>
						<td class="c12" colspan="1" rowspan="1">
							<p class="c1">
								<span class="c0">You can opt-out of these non-essential cookies</span>
							</p>
						</td>
					</tr>
				</table>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">DISCLOSING YOUR INFORMATION</span>
				</p>
				<p class="c2">
					<span class="c0">We may share the information collected with other entities in the KR Group in order to provide the information requested.</span>
				</p>
				<p class="c2">
					<span class="c0">We do not share your personal data outside of the UK or EEA.</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">HOW LONG WE WILL KEEP YOUR DATA</span>
				</p>
				<p class="c2">
					<span class="c0">Any personal information that you provide to us will be retained for the time that you are using the tool.</span>
				</p>
				<p class="c2">
					<span class="c0">We do not keep the PDFs generated by the tool.</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">YOUR RIGHTS</span>
				</p>
				<p class="c2">
					<span class="c0">YOU HAVE THE RIGHT TO:</span>
				</p>
				<ul class="c10 lst-kix_list_6-0 start">
					<li class="c4 li-bullet-0">
						<span class="c0">request a copy of any information that we currently hold about you and ask us to correct or delete this.</span>
					</li>
					<li class="c4 li-bullet-0">
						<span class="c0">request that copies of your personal data sent to another controller </span>
					</li>
					<li class="c4 li-bullet-0">
						<span class="c0">object to automated decision making and/or profiling</span>
					</li>
					<li class="c4 li-bullet-0">
						<span class="c0">restrict or object to processing of your information.</span>
					</li>
					<li class="c4 li-bullet-0">
						<span class="c0">be informed regarding the information we hold &nbsp;in our systems about you to be provided to you or another Data Controller in a machine readable format.</span>
					</li>
					<li class="c2 c8 c13 li-bullet-0">
						<span class="c0">Where you have provided consent for us to process your data, you have the right to withdraw the consent for the processing of that data.</span>
					</li>
				</ul>
				<p class="c2">
					<span class="c0">If you believe that we are not processing your information correctly you should contact our Data Protection Officer at the address above or via our email address privacy@airlaterlife.co.uk . If you are not satisfied with our response you have the right to
						take your complaint to the Information Commissioner&rsquo;s Office (www.ico.org.uk).</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">PROTECTING YOUR INFORMATION</span>
				</p>
				<p class="c2">
					<span class="c0">We are committed to protecting the confidentiality and security of your information. &nbsp;As such any information collected via our website is encrypted and delivered to us through a secure environment and protected using robust security methods. Air
						maintains a comprehensive information security management system which ensures industry standard technical and administrative controls are in place for the protection of customer data.</span>
				</p>
				<p class="c2">
					<span class="c0">Please note that there is no guarantee that any email sent will be received, or that the contents of any such email will remain private or unaltered during Internet transmission. For this reason we will not include any of your personal information by
						email without your prior permission, but will usually respond to your enquiries by telephone or post. If you have any such concerns, you may prefer to telephone or write to us instead. We accept no liability for any damages you or others may suffer as a
						result of the alteration or loss of confidentiality of any such information.</span>
				</p>
				<p class="c2">
					<span class="c0">We reserve the right to monitor the use and content of emails which are sent from and received by us for the purposes of ensuring compliance with our own email policy, and identifying and taking action against unlawful or improper use of our systems,
						including, but not limited to, spoofing, the transmission of computer viruses and a denial of service attack.</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">OTHER WEBSITES</span>
				</p>
				<p class="c2">
					<span class="c0">This privacy notice only covers this website and any other means by which we collect your data. Any other third-party websites which may be linked to by our website are subject to their own notice, which may differ from ours.</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
				<p class="c2">
					<span class="c7">LEGAL</span>
				</p>
				<p class="c2">
					<span class="c0">COPYRIGHT AND TRADEMARK</span>
				</p>
				<p class="c2">
					<span class="c0">&copy; 2023 Answers in Retirement Limited. Copyright in the pages of this website, in the screens displaying the pages and in the information and material contained therein and their arrangement, is owned by Answers in Retirement Limited unless otherwise
						indicated. Reproduction of the pages of this website in whole or in part without the prior written consent of Answers in Retirement Ltd is strictly prohibited unless carried out for private, non-commercial viewing purposes.</span>
				</p>
				<p class="c2">
					<span class="c0">Use of this website and the tool within it is subject to our terms and conditions of use, available <a @click="openTerms">here</a> . &nbsp;</span>
				</p>
				<p class="c2 c3">
					<span class="c0" />
				</p>
			</body>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';

	export default {
		name: 'app-common-privacy',

		components: { CommonDialog },

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			},

			openTerms() {
				this.$refs.dialog.close();
				this.$emit('open-terms');
			}
		}
	};
</script>
<style type="text/css" scoped>
	ul.lst-kix_list_1-0 {
		list-style-type: none;
	}

	.lst-kix_list_3-0 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_5-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-8 {
		list-style-type: none;
	}

	.lst-kix_list_3-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_3-2 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_5-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-3 {
		list-style-type: none;
	}

	.lst-kix_list_3-5 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_5-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-1 {
		list-style-type: none;
	}

	.lst-kix_list_3-4 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_1-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-7 {
		list-style-type: none;
	}

	.lst-kix_list_3-3 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_5-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_1-6 {
		list-style-type: none;
	}

	.lst-kix_list_3-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_3-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_3-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_5-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_4-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_5-3 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_4-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_5-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_5-1 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_4-8 {
		list-style-type: none;
	}

	.lst-kix_list_5-7 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_4-6 {
		list-style-type: none;
	}

	.lst-kix_list_5-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_5-8 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_4-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-1 {
		list-style-type: none;
	}

	.lst-kix_list_5-4 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_4-4 {
		list-style-type: none;
	}

	.lst-kix_list_5-5 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_4-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-3 {
		list-style-type: none;
	}

	.lst-kix_list_6-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_6-3 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_6-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_6-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_6-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_6-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_6-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_6-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_6-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_2-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_2-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_2-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_2-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_2-8 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_3-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-4 {
		list-style-type: none;
	}

	.lst-kix_list_4-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_4-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_4-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_4-3 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_4-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_4-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_4-6 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_6-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-2 {
		list-style-type: none;
	}

	.lst-kix_list_1-0 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_2-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-3 {
		list-style-type: none;
	}

	.lst-kix_list_1-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_1-2 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_2-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-5 {
		list-style-type: none;
	}

	.lst-kix_list_1-3 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_1-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_1-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_1-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_1-6 > li:before {
		content: '\0025cf   ';
	}

	li.li-bullet-0:before {
		margin-left: -18pt;
		white-space: nowrap;
		display: inline-block;
		min-width: 18pt;
	}

	.lst-kix_list_2-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_2-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_1-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_2-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_2-3 > li:before {
		content: '\0025cf   ';
	}

	ol {
		margin: 0;
		padding: 0;
	}

	table td,
	table th {
		padding: 0;
	}

	.c12 {
		border-right-style: solid;
		padding: 0pt 5.4pt 0pt 5.4pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 163.8pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c5 {
		border-right-style: solid;
		padding: 0pt 5.4pt 0pt 5.4pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 161.2pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c9 {
		border-right-style: solid;
		padding: 0pt 5.4pt 0pt 5.4pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 125.8pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c14 {
		background-color: #ffffff;
		-webkit-text-decoration-skip: none;
		color: #0563c1;
		font-weight: 400;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		font-size: 10.5pt;
		font-family: 'Roboto';
	}

	.c4 {
		margin-left: 36pt;
		padding-top: 0pt;
		padding-left: 0pt;
		padding-bottom: 0pt;
		line-height: 1.0791666666666666;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c7 {
		color: #000000;
		font-weight: 700;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 11pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c0 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 11pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c2 {
		padding-top: 0pt;
		padding-bottom: 8pt;
		line-height: 1.0791666666666666;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c1 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 1;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c17 {
		background-color: #ffffff;
		font-size: 10.5pt;
		font-family: 'Roboto';
		font-weight: 400;
	}

	.c15 {
		border-spacing: 0;
		border-collapse: collapse;
		margin-right: auto;
	}

	.c16 {
		background-color: #ffffff;
		max-width: 451.3pt;
		padding: 72pt 72pt 72pt 72pt;
	}

	.c6 {
		color: inherit;
		text-decoration: inherit;
	}

	.c10 {
		padding: 0;
		margin: 0;
	}

	.c8 {
		margin-left: 36pt;
	}

	.c11 {
		height: 0pt;
	}

	.c13 {
		padding-left: 0pt;
	}

	.c3 {
		height: 11pt;
	}

	.title {
		padding-top: 24pt;
		color: #000000;
		font-weight: 700;
		font-size: 36pt;
		padding-bottom: 6pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.subtitle {
		padding-top: 18pt;
		color: #666666;
		font-size: 24pt;
		padding-bottom: 4pt;
		font-family: 'Georgia';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		font-style: italic;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	li {
		color: #000000;
		font-size: 11pt;
		font-family: 'Roboto';
	}

	p {
		margin: 0;
		color: #000000;
		font-size: 11pt;
		font-family: 'Roboto';
	}

	h1 {
		padding-top: 24pt;
		color: #000000;
		font-weight: 700;
		font-size: 24pt;
		padding-bottom: 6pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h2 {
		padding-top: 18pt;
		color: #000000;
		font-weight: 700;
		font-size: 18pt;
		padding-bottom: 4pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h3 {
		padding-top: 14pt;
		color: #000000;
		font-weight: 700;
		font-size: 14pt;
		padding-bottom: 4pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h4 {
		padding-top: 12pt;
		color: #000000;
		font-weight: 700;
		font-size: 12pt;
		padding-bottom: 2pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h5 {
		padding-top: 11pt;
		color: #000000;
		font-weight: 700;
		font-size: 11pt;
		padding-bottom: 2pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h6 {
		padding-top: 10pt;
		color: #000000;
		font-weight: 700;
		font-size: 10pt;
		padding-bottom: 2pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
</style>
