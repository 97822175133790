<template>
	<div>
		<v-sheet elevation="3" rounded class="pa-6">
			<div class="d-flex mb-1">
				<p class="text-h6 primary--text mb-0">
					Customer Priorities
				</p>
				<v-spacer />
				<p class="text-body-2 primary--text mb-0">
					Step 3 of 3
				</p>
			</div>

			<p class="text-body-1 mb-1">
				Lastly, we need to answer a few questions regarding your customer priorities.
			</p>
			<p class="text-body-1 font-weight-bold mb-6">
				For each statement please rate how strongly they agree or disagree on a scale of 1 - 5 (1 being strongly disagree and 5 being strongly agree).
			</p>

			<div class="ma-n1">
				<v-tooltip bottom max-width="300">
					<template #activator="{ on }">
						<v-chip label outlined class="ma-1" v-on="on">
							<v-icon left color="success">
								mdi-check-circle
							</v-icon>Likely to be suitable
						</v-chip>
					</template>
					<span>This product would likely be suitable with your customer(s) preference</span>
				</v-tooltip>
				<v-tooltip bottom max-width="300">
					<template #activator="{ on }">
						<v-chip label outlined class="ma-1" v-on="on">
							<v-icon left color="amber">
								mdi-help-circle
							</v-icon>Could be suitable
						</v-chip>
					</template>
					<span>This product could be suitable with your customer(s) preference</span>
				</v-tooltip>
				<v-tooltip bottom max-width="300">
					<template #activator="{ on }">
						<v-chip label outlined class="ma-1" v-on="on">
							<v-icon left color="error">
								mdi-close-circle
							</v-icon>Unlikely to be suitable
						</v-chip>
					</template>
					<span>This product is unlikely to be suitable with your customer(s) preference</span>
				</v-tooltip>
			</div>

			<div>
				<v-simple-table class="elevation-2 productTable mt-3">
					<thead style="background-color: #1976d2">
						<tr>
							<th v-for="(column, index) in priorityColumns" :key="index" class="font-weight-bold white--text py-4" :class="{ 'text-center': index }">
								<div>{{ column.text }}</div>
								<div class="text-caption">
									{{ column.subtitle }}
								</div>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in priorityRows" :key="row.value">
							<td class="font-weight-bold py-3">
								{{ row.text }}
							</td>
							<td>
								<v-item-group v-model="priorities[index]" mandatory>
									<v-container>
										<v-row no-gutters class="d-flex justify-center">
											<v-col v-for="i in 5" :key="i" cols="2" class="px-1">
												<v-item v-slot="{ active, toggle }" :value="i" class="mr-2">
													<v-card elevation="0" :class="active ? 'primary white--text' : 'white primary--text'" class="pa-1" style="border: 1px solid; border-color: #1976d2 !important" width="120px" @click="toggle">
														<div class="text-body-1 flex-grow-1 text-center flex-no-wrap text-no-wrap">
															{{ i }}
														</div>
													</v-card>
												</v-item>
											</v-col>
										</v-row>
									</v-container>
								</v-item-group>
							</td>
							<td v-for="(column, index) in columns.length - 1" :key="column" class="text-center" style="width: 100px">
								<v-icon v-if="getPriorityValue(row, index) == null" color="grey" size="28">
									mdi-help-circle
								</v-icon>
								<v-icon v-else-if="getPriorityValue(row, index) === 'green'" color="success" size="28">
									mdi-check-circle
								</v-icon>
								<v-icon v-else-if="getPriorityValue(row, index) === 'amber'" color="amber" size="28">
									mdi-help-circle
								</v-icon>
								<v-icon v-else color="error" size="28">
									mdi-close-circle
								</v-icon>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
			</div>

			<div class="d-flex mt-8">
				<v-btn color="primary" large outlined @click="$emit('go-back')">
					Back
				</v-btn>
				<v-spacer />
				<v-btn color="primary" large @click="$emit('next-step')">
					Finish
				</v-btn>
			</div>
		</v-sheet>

		<common-dialog ref="helpDialog" :max-width="800" color="primary">
			<template #header>
				Customer Priorities
			</template>
			<template #body>
				<v-sheet class="pt-5">
					<p class="text-body-1">
						In the third and final step you will need to ask your customer(s) the following questions to help understand which product is suitable based on their priorities.
					</p>
					<div class="text-body-1 mb-0">
						<span class="d-inline">Select between</span>
						<span class="d-inline priorities-button"> 1 </span>
						<span class="d-inline-block"> and </span>
						<span class="d-inline priorities-button"> 5 </span>
						<span class="d-inline"> (1 being strongly disagree and 5 being strongly agree) and the table will instantly update to help you see which products are likely suitable </span>
						<v-icon left color="success" size="18" class="mt-n1 mr-0">
							mdi-check-circle
						</v-icon>, could be suitable
						<v-icon left color="amber" size="18" class="mt-n1 mr-0">
							mdi-help-circle
						</v-icon>
						and unlikely to be suitable
						<v-icon left color="error" size="18" class="mt-n1 mr-0">
							mdi-close-circle
						</v-icon>.
					</div>
				</v-sheet>
			</template>
			<template #footer>
				<v-card-actions class="pa-4">
					<v-checkbox v-model="hideHelpDialog" hide-details label="Do not show this again" class="ma-0 pa-0" @change="helpDialogChanged" />
				</v-card-actions>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { $localStorage } from '@/utils/storage';
	import CommonDialog from '@/components/common/dialog';

	export default {
		components: {
			CommonDialog
		},

		props: {
			priorities: { type: Array, required: true },
			priorityRows: { type: Array, required: true },
			priorityColumns: { type: Array, required: true },
			columns: { type: Array, required: true }
		},

		data() {
			return {
				hideHelpDialog: false
			};
		},

		mounted() {
			this.$vuetify.goTo(0, { duration: 0 });
			if (!$localStorage.get('hideStep4HelpDialog')) this.$refs.helpDialog.open();
		},

		methods: {
			/**
			 * @name getPriorityValue
			 * @description Returns the priority status of a criteria
			 * @param {Object} row
			 * @param {Number} index
			 */
			getPriorityValue(row, index) {
				switch (row.name) {
					case 'priority1': {
						if (this.priorities[0] == null) return null;
						return [this.priorities[0] === 1 ? 'red' : this.priorities[0] === 2 ? 'amber' : 'green', this.priorities[0] === 1 ? 'red' : this.priorities[0] === 2 ? 'amber' : 'green', this.priorities[0] === 5 ? 'green' : 'amber'][index];
					}
					case 'priority2': {
						if (this.priorities[1] == null) return null;
						return [this.priorities[1] > 3 ? 'green' : this.priorities[1] < 3 ? 'red' : 'amber', this.priorities[1] > 3 ? 'green' : this.priorities[1] < 3 ? 'red' : 'amber', this.priorities[1] < 3 ? 'green' : 'amber'][index];
					}
					case 'priority3': {
						if (this.priorities[2] == null) return null;
						return [this.priorities[2] === 1 ? 'green' : this.priorities[2] === 5 ? 'red' : 'amber', this.priorities[2] === 1 ? 'green' : this.priorities[2] === 5 ? 'red' : 'amber', this.priorities[2] === 1 ? 'red' : this.priorities[2] > 3 ? 'green' : 'amber'][index];
					}
					case 'priority4': {
						if (this.priorities[3] == null) return null;
						return [this.priorities[3] === 4 ? 'amber' : this.priorities[3] === 5 ? 'red' : 'green', this.priorities[3] > 3 ? 'amber' : 'green', this.priorities[3] === 1 ? 'red' : this.priorities[3] === 2 ? 'amber' : 'green'][index];
					}
					case 'priority5': {
						if (this.priorities[4] == null) return null;
						return [this.priorities[4] < 3 ? 'amber' : 'green', this.priorities[4] < 3 ? 'amber' : 'green', this.priorities[4] === 5 ? 'red' : this.priorities[4] < 3 ? 'green' : 'amber'][index];
					}
					case 'priority6': {
						if (this.priorities[5] == null) return null;
						return [this.priorities[5] === 4 ? 'amber' : this.priorities[5] === 5 ? 'red' : 'green', this.priorities[5] > 3 ? 'amber' : 'green', this.priorities[5] === 1 ? 'red' : this.priorities[5] === 2 ? 'amber' : 'green'][index];
					}
					case 'priority7': {
						if (this.priorities[6] == null) return null;
						return [this.priorities[6] < 3 ? 'green' : 'amber', this.priorities[6] === 1 ? 'amber' : 'green', this.priorities[6] === 3 ? 'amber' : this.priorities[6] > 3 ? 'green' : 'red'][index];
					}
					default:
						break;
				}
			},

			/**
			 * @name helpDialogChanged
			 * @description Handles the change of the help dialog checkbox and saves the value to local storage
			 */
			helpDialogChanged() {
				$localStorage.set('hideStep4HelpDialog', this.hideHelpDialog);
			}
		}
	};
</script>

<style scoped lang="scss">
	.priorities-button {
		border: 1px solid #000;
		border-radius: 4px;
		margin: 0 8px;
		padding: 1px 3px;
		font-weight: bold;
	}
</style>
