<!--
	@name app-common-privacy
	@description Privacy Policy
	@date 2020/06/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div class="pa-8">
		<h2 class="c12">
			<span>Cookie Notice</span>
		</h2>
		<p class="c1">
			<span class="c0">Cookies are small pieces of information, normally consisting of just letters and numbers, which online services provide when users visit them. Software on the
				user&#39;s device (for example a web browser) can store cookies and send them back to the website next time they visit.</span>
		</p>
		<p class="c1">
			<span class="c0">To have full access to our website we sometimes need to collect information about you.</span>
		</p>
		<p class="c1">
			<span class="c11">Essential Cookies</span>
		</p>
		<p class="c1">
			<span class="c0">We will always load essential cookies that are either for;</span>
		</p>
		<ul class="c26 lst-kix_list_11-0 start">
			<li class="c4 li-bullet-0">
				<span class="c0">Authentication</span>
			</li>
			<li class="c4 li-bullet-0">
				<span class="c0">User status</span>
			</li>
			<li class="c4 li-bullet-0">
				<span class="c0">Security</span>
			</li>
		</ul>
		<p class="c8 c14">
			<span class="c0" />
		</p>
		<p class="c1">
			<span class="c11">Non-Essential Cookies</span>
		</p>
		<p class="c1">
			<span class="c0">By visiting our website, if you accept non-essential cookies we collect standard internet information and details of your visit. The data will give us an idea of
				the number of daily visitors to the various parts of the site, what content it is being accessed, IP addresses used etc.
			</span>
		</p>
		<p class="c1">
			<span class="c0">The table below details our cookies and purpose:</span>
		</p>
		<a id="t.8bc2b859726a4a59db23b5bbe5640c4b44efa312" /><a id="t.0" />
		<table class="c22">
			<thead>
				<tr class="c25">
					<td class="c6" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c7">Cookie</span>
						</p>
					</td>
					<td class="c5" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c3">ID(s)</span>
						</p>
					</td>
					<td class="c16" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c3">Essential</span>
						</p>
					</td>
					<td class="c2" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c7">Purpose</span>
						</p>
					</td>
					<tbody />
				</tr>
				<tr class="c25">
					<td class="c10" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c7">Google Analytics</span>
						</p>
					</td>
					<td class="c9" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c7">_ga</span>
						</p>
						<p class="c1 c8">
							<span class="c7 c13" />
						</p>
					</td>
					<td class="c21" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c3">N</span>
						</p>
					</td>
					<td class="c19" colspan="1" rowspan="1">
						<p class="c1">
							<span class="c3">We use Google Analytics to understand how you interact with our website in order to improve the user experience.</span>
						</p>
					</td>
				</tr>
			</thead>
		</table>
		<p class="c1 c8">
			<span class="c3" />
		</p>
		<h2 class="c12">
			<span>How to disable all cookies</span>
		</h2>
		<p class="c1">
			<span class="c3">For information on cookies, visit www.allaboutcookies.org.</span>
		</p>
		<p class="c1">
			<span class="c7">If you disable all cookies on our website, some services may not work properly, for example online forms.</span>
		</p>
	</div>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';

	export default {
		name: 'app-common-privacy',

		components: { CommonDialog },

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			},

			openTerms() {
				this.$refs.dialog.close();
				this.$emit('open-terms');
			}
		}
	};
</script>
<style type="text/css" scoped>
	ol.lst-kix_list_9-0.start {
		counter-reset: lst-ctn-kix_list_9-0 0;
	}

	.lst-kix_list_9-0 > li {
		counter-increment: lst-ctn-kix_list_9-0;
	}

	ol.lst-kix_list_1-5.start {
		counter-reset: lst-ctn-kix_list_1-5 0;
	}

	ol.lst-kix_list_9-6.start {
		counter-reset: lst-ctn-kix_list_9-6 0;
	}

	.lst-kix_list_1-2 > li {
		counter-increment: lst-ctn-kix_list_1-2;
	}

	.lst-kix_list_9-2 > li {
		counter-increment: lst-ctn-kix_list_9-2;
	}

	.lst-kix_list_5-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_1-4 > li {
		counter-increment: lst-ctn-kix_list_1-4;
	}

	ol.lst-kix_list_1-6.start {
		counter-reset: lst-ctn-kix_list_1-6 0;
	}

	.lst-kix_list_5-3 > li:before {
		content: '\0025cf   ';
	}

	ol.lst-kix_list_9-5.start {
		counter-reset: lst-ctn-kix_list_9-5 0;
	}

	.lst-kix_list_5-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_5-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_5-7 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_8-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_8-5 {
		list-style-type: none;
	}

	.lst-kix_list_5-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_5-8 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_8-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_8-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_8-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_8-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_8-7 {
		list-style-type: none;
	}

	.lst-kix_list_9-4 > li {
		counter-increment: lst-ctn-kix_list_9-4;
	}

	.lst-kix_list_5-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_5-5 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_8-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_8-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-0.start {
		counter-reset: lst-ctn-kix_list_1-0 0;
	}

	.lst-kix_list_6-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_6-3 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_6-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_6-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_6-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_6-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_6-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_6-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_6-6 > li:before {
		content: '\0025cf   ';
	}

	ol.lst-kix_list_1-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-4 {
		list-style-type: none;
	}

	.lst-kix_list_2-7 > li:before {
		content: '\0025aa   ';
	}

	ol.lst-kix_list_1-5 {
		list-style-type: none;
	}

	.lst-kix_list_7-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_7-6 > li:before {
		content: '\0025cf   ';
	}

	ol.lst-kix_list_1-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-0 {
		list-style-type: none;
	}

	.lst-kix_list_2-5 > li:before {
		content: '\0025aa   ';
	}

	ol.lst-kix_list_1-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-2 {
		list-style-type: none;
	}

	.lst-kix_list_7-2 > li:before {
		content: '\0025aa   ';
	}

	ol.lst-kix_list_9-4.start {
		counter-reset: lst-ctn-kix_list_9-4 0;
	}

	ul.lst-kix_list_3-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-7 {
		list-style-type: none;
	}

	.lst-kix_list_10-1 > li:before {
		content: 'o  ';
	}

	ol.lst-kix_list_9-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-5 {
		list-style-type: none;
	}

	.lst-kix_list_7-8 > li:before {
		content: '\0025aa   ';
	}

	ol.lst-kix_list_9-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_3-4 {
		list-style-type: none;
	}

	.lst-kix_list_10-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_10-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_9-8 > li {
		counter-increment: lst-ctn-kix_list_9-8;
	}

	.lst-kix_list_10-3 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_11-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_11-6 {
		list-style-type: none;
	}

	.lst-kix_list_4-1 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_11-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_11-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_11-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_11-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_11-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_11-0 {
		list-style-type: none;
	}

	.lst-kix_list_9-2 > li:before {
		content: '' counter(lst-ctn-kix_list_9-2, lower-roman) '. ';
	}

	.lst-kix_list_4-3 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_4-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_1-8 > li {
		counter-increment: lst-ctn-kix_list_1-8;
	}

	ul.lst-kix_list_11-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-4.start {
		counter-reset: lst-ctn-kix_list_1-4 0;
	}

	ol.lst-kix_list_1-1.start {
		counter-reset: lst-ctn-kix_list_1-1 0;
	}

	.lst-kix_list_9-0 > li:before {
		content: '' counter(lst-ctn-kix_list_9-0, decimal) '. ';
	}

	ol.lst-kix_list_9-2.start {
		counter-reset: lst-ctn-kix_list_9-2 0;
	}

	.lst-kix_list_9-6 > li:before {
		content: '' counter(lst-ctn-kix_list_9-6, decimal) '. ';
	}

	.lst-kix_list_9-3 > li {
		counter-increment: lst-ctn-kix_list_9-3;
	}

	.lst-kix_list_9-4 > li:before {
		content: '' counter(lst-ctn-kix_list_9-4, lower-latin) '. ';
	}

	.lst-kix_list_11-3 > li:before {
		content: '\0025cf   ';
	}

	ol.lst-kix_list_10-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-3.start {
		counter-reset: lst-ctn-kix_list_1-3 0;
	}

	ul.lst-kix_list_2-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-2.start {
		counter-reset: lst-ctn-kix_list_1-2 0;
	}

	.lst-kix_list_11-5 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_2-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-1 {
		list-style-type: none;
	}

	.lst-kix_list_9-8 > li:before {
		content: '' counter(lst-ctn-kix_list_9-8, lower-roman) '. ';
	}

	ul.lst-kix_list_2-6 {
		list-style-type: none;
	}

	.lst-kix_list_1-1 > li:before {
		content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) ' ';
	}

	ul.lst-kix_list_2-7 {
		list-style-type: none;
	}

	.lst-kix_list_11-7 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_2-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_2-5 {
		list-style-type: none;
	}

	.lst-kix_list_1-3 > li:before {
		content: '' counter(lst-ctn-kix_list_1-3, lower-roman) ') ';
	}

	ul.lst-kix_list_10-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_10-7 {
		list-style-type: none;
	}

	.lst-kix_list_1-7 > li:before {
		content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
			counter(lst-ctn-kix_list_1-3, lower-roman) '.' counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) '.'
			counter(lst-ctn-kix_list_1-6, decimal) '.' counter(lst-ctn-kix_list_1-7, decimal) ' ';
	}

	ul.lst-kix_list_10-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_10-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_10-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_10-3 {
		list-style-type: none;
	}

	.lst-kix_list_1-3 > li {
		counter-increment: lst-ctn-kix_list_1-3;
	}

	.lst-kix_list_1-5 > li:before {
		content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) '.'
			counter(lst-ctn-kix_list_1-3, lower-roman) '.' counter(lst-ctn-kix_list_1-4, decimal) '.' counter(lst-ctn-kix_list_1-5, decimal) ' ';
	}

	ul.lst-kix_list_10-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_10-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-1.start {
		counter-reset: lst-ctn-kix_list_9-1 0;
	}

	.lst-kix_list_2-1 > li:before {
		content: '-  ';
	}

	.lst-kix_list_2-3 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_1-1 > li {
		counter-increment: lst-ctn-kix_list_1-1;
	}

	.lst-kix_list_9-1 > li {
		counter-increment: lst-ctn-kix_list_9-1;
	}

	.lst-kix_list_3-0 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_5-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-8 {
		list-style-type: none;
	}

	.lst-kix_list_3-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_3-2 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_5-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-6 {
		list-style-type: none;
	}

	.lst-kix_list_8-1 > li:before {
		content: 'o  ';
	}

	ol.lst-kix_list_1-8.start {
		counter-reset: lst-ctn-kix_list_1-8 0;
	}

	.lst-kix_list_8-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_3-5 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_5-0 {
		list-style-type: none;
	}

	.lst-kix_list_10-0 > li {
		counter-increment: lst-ctn-kix_list_10-0;
	}

	.lst-kix_list_3-4 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_5-3 {
		list-style-type: none;
	}

	.lst-kix_list_3-3 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_5-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_5-1 {
		list-style-type: none;
	}

	.lst-kix_list_8-0 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_5-2 {
		list-style-type: none;
	}

	.lst-kix_list_8-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_3-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_8-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_8-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_8-3 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_3-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_3-7 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_8-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_11-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_11-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_11-0 > li:before {
		content: '\0025cf   ';
	}

	ol.lst-kix_list_9-3.start {
		counter-reset: lst-ctn-kix_list_9-3 0;
	}

	.lst-kix_list_8-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_4-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_4-7 > li:before {
		content: 'o  ';
	}

	ul.lst-kix_list_4-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_4-3 {
		list-style-type: none;
	}

	.lst-kix_list_7-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_2-6 > li:before {
		content: '\0025aa   ';
	}

	ol.lst-kix_list_9-7.start {
		counter-reset: lst-ctn-kix_list_9-7 0;
	}

	.lst-kix_list_2-4 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_2-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_7-1 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_7-5 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_9-6 > li {
		counter-increment: lst-ctn-kix_list_9-6;
	}

	.lst-kix_list_7-3 > li:before {
		content: '\0025cf   ';
	}

	ul.lst-kix_list_7-5 {
		list-style-type: none;
	}

	.lst-kix_list_10-0 > li:before {
		content: '' counter(lst-ctn-kix_list_10-0, decimal) '. ';
	}

	ul.lst-kix_list_7-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_7-3 {
		list-style-type: none;
	}

	.lst-kix_list_9-7 > li {
		counter-increment: lst-ctn-kix_list_9-7;
	}

	ul.lst-kix_list_7-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_7-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_7-8 {
		list-style-type: none;
	}

	ul.lst-kix_list_7-1 {
		list-style-type: none;
	}

	ul.lst-kix_list_7-2 {
		list-style-type: none;
	}

	.lst-kix_list_1-7 > li {
		counter-increment: lst-ctn-kix_list_1-7;
	}

	ul.lst-kix_list_7-0 {
		list-style-type: none;
	}

	.lst-kix_list_7-7 > li:before {
		content: 'o  ';
	}

	ol.lst-kix_list_10-0.start {
		counter-reset: lst-ctn-kix_list_10-0 0;
	}

	.lst-kix_list_9-5 > li {
		counter-increment: lst-ctn-kix_list_9-5;
	}

	.lst-kix_list_10-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_10-8 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_4-0 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_10-2 > li:before {
		content: '\0025aa   ';
	}

	ol.lst-kix_list_1-7.start {
		counter-reset: lst-ctn-kix_list_1-7 0;
	}

	.lst-kix_list_4-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_1-5 > li {
		counter-increment: lst-ctn-kix_list_1-5;
	}

	.lst-kix_list_4-2 > li:before {
		content: '\0025aa   ';
	}

	.lst-kix_list_4-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_9-3 > li:before {
		content: '' counter(lst-ctn-kix_list_9-3, decimal) '. ';
	}

	.lst-kix_list_10-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_9-1 > li:before {
		content: '' counter(lst-ctn-kix_list_9-1, lower-latin) '. ';
	}

	.lst-kix_list_9-7 > li:before {
		content: '' counter(lst-ctn-kix_list_9-7, lower-latin) '. ';
	}

	.lst-kix_list_11-4 > li:before {
		content: 'o  ';
	}

	.lst-kix_list_9-5 > li:before {
		content: '' counter(lst-ctn-kix_list_9-5, lower-roman) '. ';
	}

	ul.lst-kix_list_6-6 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-8 {
		list-style-type: none;
	}

	.lst-kix_list_11-6 > li:before {
		content: '\0025cf   ';
	}

	.lst-kix_list_1-0 > li:before {
		content: 'Appendix ' counter(lst-ctn-kix_list_1-0, decimal) ' ';
	}

	ul.lst-kix_list_6-2 {
		list-style-type: none;
	}

	.lst-kix_list_11-8 > li:before {
		content: '\0025aa   ';
	}

	ul.lst-kix_list_6-3 {
		list-style-type: none;
	}

	.lst-kix_list_1-2 > li:before {
		content: '' counter(lst-ctn-kix_list_1-0, decimal) '.' counter(lst-ctn-kix_list_1-1, decimal) '.' counter(lst-ctn-kix_list_1-2, decimal) ' ';
	}

	ul.lst-kix_list_6-0 {
		list-style-type: none;
	}

	ul.lst-kix_list_6-1 {
		list-style-type: none;
	}

	.lst-kix_list_1-4 > li:before {
		content: ' ';
	}

	.lst-kix_list_1-0 > li {
		counter-increment: lst-ctn-kix_list_1-0;
	}

	.lst-kix_list_1-6 > li {
		counter-increment: lst-ctn-kix_list_1-6;
	}

	.lst-kix_list_1-6 > li:before {
		content: ' ';
	}

	li.li-bullet-0:before {
		margin-left: -18pt;
		white-space: nowrap;
		display: inline-block;
		min-width: 18pt;
	}

	.lst-kix_list_2-0 > li:before {
		content: '\0025cf   ';
	}

	ol.lst-kix_list_9-8.start {
		counter-reset: lst-ctn-kix_list_9-8 0;
	}

	.lst-kix_list_1-8 > li:before {
		content: ' ';
	}

	.lst-kix_list_2-2 > li:before {
		content: '\0025aa   ';
	}

	ol {
		margin: 0;
		padding: 0;
	}

	table td,
	table th {
		padding: 0;
	}

	.c5 {
		border-right-style: solid;
		padding: 7.5pt 11.2pt 7.5pt 11.2pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: middle;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #8eaadb;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 60.3pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c16 {
		border-right-style: solid;
		padding: 0.8pt 0.8pt 0.8pt 0.8pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #8eaadb;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 39.1pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c2 {
		border-right-style: solid;
		padding: 7.5pt 11.2pt 7.5pt 11.2pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: middle;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #8eaadb;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 289.6pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c19 {
		border-right-style: solid;
		padding: 7.5pt 11.2pt 7.5pt 11.2pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #dddddd;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 289.6pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c6 {
		border-right-style: solid;
		padding: 7.5pt 11.2pt 7.5pt 11.2pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: middle;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #8eaadb;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 61.5pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c21 {
		border-right-style: solid;
		padding: 0.8pt 0.8pt 0.8pt 0.8pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #dddddd;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 39.1pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c9 {
		border-right-style: solid;
		padding: 7.5pt 11.2pt 7.5pt 11.2pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #dddddd;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 60.3pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c10 {
		border-right-style: solid;
		padding: 7.5pt 11.2pt 7.5pt 11.2pt;
		border-bottom-color: #000000;
		border-top-width: 1pt;
		border-right-width: 1pt;
		border-left-color: #000000;
		vertical-align: top;
		border-right-color: #000000;
		border-left-width: 1pt;
		border-top-style: solid;
		background-color: #dddddd;
		border-left-style: solid;
		border-bottom-width: 1pt;
		width: 61.5pt;
		border-top-color: #000000;
		border-bottom-style: solid;
	}

	.c4 {
		margin-left: 36pt;
		padding-top: 0pt;
		padding-left: 0pt;
		padding-bottom: 0pt;
		line-height: 1;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c11 {
		color: #000000;
		font-weight: 700;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 11pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c0 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 11pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c14 {
		margin-left: 36pt;
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 1;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c3 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c23 {
		color: #2f5496;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 13pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c12 {
		padding-top: 2pt;
		padding-bottom: 0pt;
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c15 {
		color: #2f5496;
		font-weight: 700;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 13pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c24 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 1;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c13 {
		color: #172b4d;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c18 {
		color: #444444;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c17 {
		color: #ffffff;
		font-weight: 700;
		text-decoration: none;
		vertical-align: baseline;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c1 {
		padding-top: 0pt;
		padding-bottom: 8pt;
		line-height: 1.0791666666666666;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.c22 {
		margin-left: auto;
		border-spacing: 0;
		border-collapse: collapse;
		margin-right: auto;
	}

	.c20 {
		background-color: #ffffff;
		max-width: 451.3pt;
		padding: 72pt 72pt 72pt 72pt;
	}

	.c26 {
		padding: 0;
		margin: 0;
	}

	.c7 {
		font-size: 10pt;
	}

	.c8 {
		height: 11pt;
	}

	.c25 {
		height: 0pt;
	}

	.title {
		padding-top: 24pt;
		color: #000000;
		font-weight: 700;
		font-size: 36pt;
		padding-bottom: 6pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	.subtitle {
		padding-top: 18pt;
		color: #666666;
		font-size: 24pt;
		padding-bottom: 4pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		font-style: italic;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	li {
		color: #000000;
		font-size: 11pt;
		font-family: 'Roboto';
	}

	p {
		margin: 0;
		color: #000000;
		font-size: 11pt;
		font-family: 'Roboto';
	}

	h1 {
		padding-top: 12pt;
		color: #2f5496;
		font-size: 16pt;
		padding-bottom: 0pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h2 {
		padding-top: 2pt;
		color: #2f5496;
		font-size: 13pt;
		padding-bottom: 0pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h3 {
		padding-top: 14pt;
		color: #000000;
		font-weight: 700;
		font-size: 14pt;
		padding-bottom: 4pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h4 {
		padding-top: 12pt;
		color: #000000;
		font-weight: 700;
		font-size: 12pt;
		padding-bottom: 2pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h5 {
		padding-top: 11pt;
		color: #000000;
		font-weight: 700;
		font-size: 11pt;
		padding-bottom: 2pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h6 {
		padding-top: 10pt;
		color: #000000;
		font-weight: 700;
		font-size: 10pt;
		padding-bottom: 2pt;
		font-family: 'Roboto';
		line-height: 1.0791666666666666;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
</style>
