/**
 * @namespace Filters
 * @exports Filters/formatDate
 * @description Format date dd/mm/yyyy
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

export default (url, type) => {
	if (!url) return;
	if (!type) return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/${url}`;

	switch (type) {
		case 'logo':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/logo/${url}`;
		case 'brand':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/logo/brand/${url}`;
		case 'site':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/logo/site/${url}`;
		case 'organisation':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/logo/organisation/${url}`;
		case 'webinar':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/logo/webinars/${url}`;
		case 'advert':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/advert/${url}`;
		case 'placeholder':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/placeholder/${url}`;
		case 'icon':
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/icon/${url}`;
		default:
			return `${process.env.VUE_APP_S3_AIR_ASSETS_URL}/${type}/${url}`;
	}
};
