<template>
	<v-container v-if="loaded" style="max-width: 1280px">
		<v-row align="start" no-gutters>
			<v-col>
				<p v-if="step > 1" class="text-h5 font-weight-bold px-3 py-4">
					<span class="primary--text"> Customer Reference:</span> {{ customerReference }}
				</p>

				<!-- Chatbot  -->
				<chat-step v-if="step === 1" :config="chatConfig" :customer-reference="customerReference" :number-of-clients="numberOfClients" :fca-number="fcaNumber" @next-step="chatNextStep" />

				<v-row v-else-if="step === 2 || step === 3">
					<v-col cols="9">
						<!-- Step 1: Customer Details  -->
						<customer-details
							v-if="step === 2"
							:number-of-clients="numberOfClients"
							:personal-form-data="personalFormData"
							:personal-form-data2="personalFormData2"
							:mortgage-form-data="mortgageFormData"
							:expenditure-form-data="expenditureFormData"
							:expenses="expenses"
							:validation="customerDetailsValidation"
							@expenses-updated="updateExpenses"
							@next-step="customerDetailsNextStep"
							@go-back="step--"
						/>

						<!-- Step 2: Affodability  -->
						<affordability-details
							v-else-if="step === 3"
							:number-of-clients="numberOfClients"
							:form-data="affordabilityFormData"
							:estimated-disposable-income1="estimatedDisposableIncome1"
							:estimated-disposable-income2="estimatedDisposableIncome2"
							:estimated-disposable-income-retirement1="estimatedDisposableIncomeRetirement1"
							:estimated-disposable-income-retirement2="estimatedDisposableIncomeRetirement2"
							:is-customer-retired="isCustomerRetired"
							:is-customer2-retired="isCustomer2Retired"
							:validation="affordabilityValidation"
							@next-step="affordabilityNextStep"
							@go-back="step--"
						/>
					</v-col>

					<!-- Mini Table -->
					<v-col cols="3">
						<div class="d-flex mt-n9 mb-2">
							<div class="text-body-2">
								Borrowing Options Available
							</div>
							<v-spacer />
							<v-btn icon class="mt-n2" @click="eligibiltyDialog = true">
								<v-icon color="primary">
									mdi-table-eye
								</v-icon>
							</v-btn>
						</div>
						<v-sheet v-for="(column, index) in columns.length - 1" :key="index" elevation="3" rounded color="grey-lighten-4" class="text-left pa-4 mb-5">
							<p class="text-body-1 font-weight-bold mb-0">
								{{ columns[index + 1].title }}
							</p>
							<p class="text-caption font-weight-medium mb-0">
								{{ columns[index + 1].subtitle }}
							</p>
							<div class="mb-n1 mx-n1 mt-1">
								<v-chip v-for="chip in chips" v-show="!(index === 2 && (chip.name === 'affordability' || chip.name === 'customerAffordability'))" :key="chip.value" class="text-center ma-1" label small :color="chipColor(chip, index)">
									<span>
										{{ chip.text }}
									</span>
								</v-chip>
							</div>
						</v-sheet>
					</v-col>
				</v-row>

				<!-- Step 3: Priorities  -->
				<customer-priorities v-else-if="step === 4" :priority-rows="priorityRows" :priority-columns="priorityColumns" :priorities="priorities" :columns="columns" @next-step="prioritiesNextStep" @go-back="step--" />

				<!-- Step 4: Result Summary  -->
				<result-summary
					v-else-if="step === 5"
					:id="id"
					:customer-reference="customerReference"
					:number-of-clients="numberOfClients"
					:fca-number="fcaNumber"
					:personal-form-data="personalFormData"
					:personal-form-data2="personalFormData2"
					:mortgage-form-data="mortgageFormData"
					:expenditure-form-data="expenditureFormData"
					:expenses="expenses"
					:estimated-disposable-income1="estimatedDisposableIncome1"
					:estimated-disposable-income2="estimatedDisposableIncome2"
					:estimated-disposable-income-retirement1="estimatedDisposableIncomeRetirement1"
					:estimated-disposable-income-retirement2="estimatedDisposableIncomeRetirement2"
					:affordability-config="affordabilityFormData"
					:is-customer-retired="isCustomerRetired"
					:is-customer2-retired="isCustomer2Retired"
					:priority-rows="priorityRows"
					:priority-columns="priorityColumns"
					:priorities="priorities"
					:columns="columns"
					:outside-criteria="outsideCriteria"
					:eligibility-amber="eligibilityAmber"
					:rows-extended="rows"
					:customer1-age="customer1Age"
					:customer2-age="customer2Age"
					:ltv="ltv"
					:eligibility="eligibility"
					:affordability="affordability"
					:customer-affordability="customerAffordability"
					:budget="budget"
					:custom-budget="customBudget"
					:lowest-post-retirement-budget="lowestPostRetirementBudget"
					:lowest-custom-budget="lowestCustomBudget"
					:monthly-repayment="monthlyRepayment"
					:backing-data="backingData"
					:additional-information="additionalInformation"
					@update="updateJson({ s3Key, payload })"
					@go-back="step--"
				/>
			</v-col>
		</v-row>

		<!-- Eligibility Dialog -->
		<v-dialog v-model="eligibiltyDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark color="primary" class="pl-3 pr-3 flex-grow-0">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Eligibility Tracker
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="eligibiltyDialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 ma-0 mx-auto" style="max-width: 1400px">
					<div class="pt-4">
						<p class="text-body-1 mb-6">
							Our quick glance tracker will update the customer(s) eligibility in real time.
						</p>
					</div>

					<v-simple-table v-if="step !== 4" class="elevation-2 productTable">
						<thead style="background-color: #efefef">
							<tr>
								<th v-for="(column, index) in columns" :key="index" class="font-weight-bold" :class="{ 'text-center': index }">
									{{ column.title }}
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, rowIndex) in rows" v-show="row.name !== 'age2' || numberOfClients == 2" :key="row.value">
								<td class="font-weight-bold">
									{{ row.text }}
								</td>
								<td v-for="(column, index) in columns.length - 1" :key="column" class="text-center py-2" style="width: 170px">
									<div v-if="index === 2 && (row.name === 'affordability' || row.name === 'customerAffordability')" class="text-caption">
										N/A
									</div>
									<v-icon v-else-if="getValue(row, index) == null" color="grey" size="28">
										mdi-help-circle
									</v-icon>
									<v-icon v-else-if="getValue(row, index)" color="success" size="28">
										mdi-check-circle
									</v-icon>
									<div v-else class="d-flex flex-column align-center justify-center mt-1">
										<v-icon v-if="rowIndex === 4 || (rowIndex === 5 && eligibilityAmber[index])" color="amber" size="28">
											mdi-help-circle
										</v-icon>
										<v-icon v-else color="error" size="28">
											mdi-close-circle
										</v-icon>
										<v-tooltip bottom max-width="400">
											<template #activator="{ on }">
												<a class="text-caption" v-on="on"> More info </a>
											</template>
											<span v-html="getTooltipContent(row, column)" />
										</v-tooltip>
									</div>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-container>
			</v-card>
		</v-dialog>

		<toolbox />
	</v-container>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import { v4 as uuid } from 'uuid';
	import Toolbox from './toolbox';
	import ChatStep from './chat';
	import CustomerDetails from './customer-details';
	import AffordabilityDetails from './affordability';
	import CustomerPriorities from './customer-priorities';
	import ResultSummary from './result-summary';

	export default {
		components: {
			ChatStep,
			CustomerDetails,
			AffordabilityDetails,
			CustomerPriorities,
			ResultSummary,
			Toolbox
		},

		data() {
			return {
				loaded: false,
				id: null,
				step: 1,
				chatConfig: {
					step: 0,
					messages: []
				},
				customerReference: '',
				numberOfClients: null,
				fcaNumber: '',
				eligibiltyDialog: false,
				personalFormData: { customerAge: 55, isRetired: false, plannedRetirementAge: 67, income: 3000, incomeRetirement: 1500 },
				personalFormData2: { customerAge: 55, isRetired: false, plannedRetirementAge: 67, income: 3000, incomeRetirement: 1500 },
				mortgageFormData: { propertyValue: 250000, requestedBorrowingAmount: 50000 },
				expenditureFormData: { expenditure: null },
				expenses: {},
				affordabilityFormData: {
					customDisposableIncome: null,
					customDisposableIncome2: null,
					customDisposableIncomeRetirement: null,
					customDisposableIncomeRetirement2: null,
					customerIncomeNotes: null
				},
				columns: [
					{ title: '', width: '200px' },
					{ title: 'Standard residential', subtitle: '(fixed rate 5 year C&I)', value: 'standard' },
					{ title: 'RIO', subtitle: '(fixed rate 5 year IO)', value: 'rio' },
					{ title: 'Lifetime', subtitle: '(fixed for duration)', value: 'lifetime' }
				],
				priorityColumns: [
					{ text: '' },
					{ text: '', width: 100 },
					{ text: 'Standard residential', subtitle: '(fixed rate 5 year C&I)', value: 'standard' },
					{ text: 'RIO', subtitle: '(fixed rate 5 year IO)', value: 'rio' },
					{ text: 'Lifetime', subtitle: '(fixed for duration)', value: 'lifetime' }
				],
				outsideCriteria: [
					{ name: 'age', text: 'The customer(s) does/do not meet the age criteria for this product. The customer(s) must be between {minAge} and {maxAge}' },
					{ name: 'ltv', text: 'The customer(s) is/are looking to borrow too much for this product, the maximum LTV for this product is {maxLtv}%' },
					{ name: 'affordability', text: 'Unfortunately the customer(s) does/do not meet the affordability criteria for this product. The monthly repayment for this product is £{monthlyRepayment}, you have calculated the customer(s) budget to be £{budget}' },
					{
						name: 'customerAffordability',
						text: 'Unfortunately the customer(s) does/do not meet the affordability criteria for this product. The monthly repayment for this product is £{monthlyRepayment}, the customer(s) has/have advised their monthly budget to be £{customBudget}'
					}
				],
				customerDetailsValidation: {
					personalForm: false,
					personalForm2: false,
					mortgageForm: false,
					expenditureForm: false
				},
				affordabilityValidation: {
					customDisposableIncome: false,
					customDisposableIncomeRetirement: false,
					customDisposableIncome2: false,
					customDisposableIncomeRetirement2: false
				},
				priorities: [3, 3, 3, 3, 3, 3, 3],
				additionalInformation: { text: '' }
			};
		},

		computed: {
			...mapState('App', ['site']),

			/**
			 * @name backingData
			 * @description Returns the backing data for the eligibility tracker
			 * @returns {Object}
			 */
			backingData() {
				const ltvTable = { ...this.site.data.ltv, ...this.site.data.ltvPtlm };

				Object.keys(this.site.data.ltv).forEach((key) => {
					if (this.site.data.ltvPtlm[key]) ltvTable[key] = Math.max(this.site.data.ltv[key], this.site.data.ltvPtlm[key]);
				});

				let youngestAge = this.numberOfClients === 2 ? Math.min(this.personalFormData.customerAge, this.personalFormData2.customerAge) : this.personalFormData.customerAge;
				let lifetimeMaxLtv = ltvTable[youngestAge];

				if (!lifetimeMaxLtv) {
					let keys = Object.keys(ltvTable);
					let first = keys[0];
					let last = keys[keys.length - 1];
					lifetimeMaxLtv = youngestAge > last ? ltvTable[last] : ltvTable[first];
				}

				return {
					standard: this.site.data.standard,
					rio: this.site.data.rio,
					lifetime: { maxLtv: lifetimeMaxLtv, ...this.site.data.lifetime }
				};
			},

			/**
			 * @name rows
			 * @description Returns the rows for the eligibility tracker
			 * @returns {Array}
			 */
			rows() {
				let rows = [
					{ text: this.numberOfClients === 2 ? 'Customer 1 Age' : 'Age', name: 'age1', values: [] },
					{ text: 'Customer 2 Age ', name: 'age2', values: [] },
					{ text: 'LTV', name: 'ltv', values: [] },
					{ text: 'Calculated Affordability', name: 'affordability', values: [] },
					{ text: 'Customer Preferred Affordability', name: 'customerAffordability', values: [] },
					{ text: 'Eligibility', name: 'eligibility', values: [] }
				];

				return rows;
			},

			/**
			 * @name chips
			 * @description Returns the chips for the eligibility tracker mini table
			 * @returns {Array}
			 */
			chips() {
				let chips = [
					{ text: 'Age ', name: 'age', values: [] },
					{ text: 'LTV', name: 'ltv', values: [] },
					{ text: 'Calculated Affordability', name: 'affordability', values: [] },
					{ text: 'Customer Preferred Affordability', name: 'customerAffordability', values: [] }
				];

				return chips;
			},

			/**
			 * @name priorityRows
			 * @description Returns the rows for the customer priorities table
			 * @returns {Array}
			 */
			priorityRows() {
				let rows = [
					{ text: 'I can afford to commit to a fixed repayment amount for the term of the loan, both now and in the future', name: 'priority1', values: [] },
					{ text: 'I want and intend to make regular repayments to offset the amount of the interest', name: 'priority2', values: [] },
					{ text: 'Having flexibility with the payment amount or frequency is more important to me than minimising the overall cost of the loan', name: 'priority3', values: [] },
					{ text: 'A rate that is fixed for life is attractive to me so that I do not have to re-visit my mortgage at a later date', name: 'priority4', values: [] },
					{ text: 'I may be in a position to repay my mortgage early in the future and therefore I would favour products where early repayment charges are low or expire completely in a short period', name: 'priority5', values: [] },
					{ text: 'Having enough money to live comfortably is more important to me than having equity left in my property to pass on as an inheritance', name: 'priority6', values: [] },
					{ text: 'I am comfortable considering products where the compound interest will increase the debt owed and erode the equity in my property', name: 'priority7', values: [] }
				];

				let rowsJoint = [
					{ text: 'We can afford to commit to a fixed repayment amount for the term of the loan, both now and in the future', name: 'priority1', values: [] },
					{ text: 'We want and intend to make regular repayments to offset the amount of the interest', name: 'priority2', values: [] },
					{ text: 'Having flexibility with the payment amount or frequency is more important to us than minimising the overall cost of the loan', name: 'priority3', values: [] },
					{ text: 'A rate that is fixed for life is attractive to us so that we do not have to re-visit my mortgage at a later date', name: 'priority4', values: [] },
					{ text: 'We may be in a position to repay our mortgage early in the future and therefore we would favour products where early repayment charges are low or expire completely in a short period', name: 'priority5', values: [] },
					{ text: 'Having enough money to live comfortably is more important to us than having equity left in our property to pass on as an inheritance', name: 'priority6', values: [] },
					{ text: 'We are comfortable considering products where the compound interest will increase the debt owed and erode the equity in our property', name: 'priority7', values: [] }
				];

				return this.numberOfClients === 2 ? rowsJoint : rows;
			},

			/**
			 * @name estimatedDisposableIncome1
			 * @description Returns the estimated disposable income for customer 1
			 * @returns {Array}
			 */
			estimatedDisposableIncome1() {
				let income = this.personalFormData.income;
				let expenses = this.expenditureFormData.expenditure / this.numberOfClients;
				return (income - expenses) * 0.8;
			},

			/**
			 * @name disposableIncome1
			 * @description Returns the disposable income for customer 1
			 * @returns {Array}
			 */
			disposableIncome1() {
				return this.affordabilityFormData.customDisposableIncome ? this.affordabilityFormData.customDisposableIncome : this.estimatedDisposableIncome1;
			},

			/**
			 * @name estimatedDisposableIncomeRetirement1
			 * @description Returns the estimated disposable income for customer 1 in retirement
			 * @returns {Array}
			 */
			estimatedDisposableIncomeRetirement1() {
				let income = this.personalFormData.incomeRetirement;
				let expenses = this.expenditureFormData.expenditure / this.numberOfClients;
				return (income - expenses) * 0.8;
			},

			/**
			 * @name disposableIncomeRetirement1
			 * @description Returns the disposable income for customer 1 in retirement
			 * @returns {Array}
			 */
			disposableIncomeRetirement1() {
				return this.affordabilityFormData.customDisposableIncome ? this.affordabilityFormData.customDisposableIncome : this.estimatedDisposableIncomeRetirement1;
			},

			/**
			 * @name estimatedDisposableIncome2
			 * @description Returns the estimated disposable income for customer 2
			 * @returns {Array}
			 */
			estimatedDisposableIncome2() {
				let income = this.personalFormData2.income;
				let expenses = this.expenditureFormData.expenditure / this.numberOfClients;
				return (income - expenses) * 0.8;
			},

			/**
			 * @name disposableIncome2
			 * @description Returns the disposable income for customer 2
			 * @returns {Array}
			 */
			disposableIncome2() {
				return this.affordabilityFormData.customDisposableIncome2 ? this.affordabilityFormData.customDisposableIncome2 : this.estimatedDisposableIncome2;
			},

			/**
			 * @name estimatedDisposableIncomeRetirement2
			 * @description Returns the estimated disposable income for customer 2 in retirement
			 * @returns {Array}
			 */
			estimatedDisposableIncomeRetirement2() {
				let income = this.personalFormData2.incomeRetirement;
				let expenses = this.expenditureFormData.expenditure / this.numberOfClients;
				return (income - expenses) * 0.8;
			},

			/**
			 * @name disposableIncomeRetirement2
			 * @description Returns the disposable income for customer 2 in retirement
			 * @returns {Array}
			 */
			disposableIncomeRetirement2() {
				return this.affordabilityFormData.customDisposableIncome2 ? this.affordabilityFormData.customDisposableIncome2 : this.estimatedDisposableIncomeRetirement2;
			},

			/**
			 * @name monthlyRepayment
			 * @description Returns the monthly repayment for each product
			 * @returns {Boolean}
			 */
			monthlyRepayment() {
				let borrowing = this.mortgageFormData.requestedBorrowingAmount;

				return {
					standard: this.pmt(this.backingData.standard.aer / 100 / 12, 15 * 12, -borrowing),
					rio: (borrowing * this.backingData.rio.aer) / 100 / 12,
					lifetime: (borrowing * this.backingData.lifetime.aer) / 100 / 12
				};
			},

			/**
			 * @name budget
			 * @description Returns the budget for the customer(s)
			 * @returns {Number}
			 */
			budget() {
				let income1 = this.personalFormData.isRetired || this.personalFormData.plannedRetirementAge - this.personalFormData.customerAge <= 5 ? this.estimatedDisposableIncomeRetirement1 : this.estimatedDisposableIncome1;
				if (this.numberOfClients === 1) return income1;

				let income2 = this.personalFormData2.isRetired || this.personalFormData2.plannedRetirementAge - this.personalFormData2.customerAge <= 5 ? this.estimatedDisposableIncomeRetirement2 : this.estimatedDisposableIncome2;
				return income1 + income2;
			},

			/**
			 * @name lowestBudget
			 * @description Returns the lowest budget for the customer(s)
			 * @returns {Number}
			 */
			lowestBudget() {
				let income1 = this.personalFormData.isRetired || this.personalFormData.plannedRetirementAge - this.personalFormData.customerAge <= 5 ? this.estimatedDisposableIncomeRetirement1 : this.estimatedDisposableIncome1;
				if (this.numberOfClients === 1) return income1;

				let income2 = this.personalFormData2.isRetired || this.personalFormData2.plannedRetirementAge - this.personalFormData2.customerAge <= 5 ? this.estimatedDisposableIncomeRetirement2 : this.estimatedDisposableIncome2;
				return income1 < income2 ? income1 : income2;
			},

			/**
			 * @name lowestPostRetirementBudget
			 * @description Returns the lowest budget for the customer(s) in retirement. Used for affordability check for RIO
			 * @returns {Number}
			 */
			lowestPostRetirementBudget() {
				let income1 = this.estimatedDisposableIncomeRetirement1;
				if (this.numberOfClients === 1) return income1;

				let income2 = this.estimatedDisposableIncomeRetirement2;
				return income1 < income2 ? income1 : income2;
			},

			/**
			 * @name customBudget
			 * @description Returns the custom budget for the customer(s)
			 * @returns {Number}
			 */
			customBudget() {
				let income1 = this.isCustomerRetired ? this.affordabilityFormData.customDisposableIncomeRetirement : this.affordabilityFormData.customDisposableIncome;
				if (this.numberOfClients === 1) return income1;

				let income2 = this.isCustomer2Retired ? this.affordabilityFormData.customDisposableIncomeRetirement2 : this.affordabilityFormData.customDisposableIncome2;
				return typeof income1 === 'number' && typeof income2 === 'number' ? income1 + income2 : null;
			},

			/**
			 * @name lowestCustomBudget
			 * @description Returns the lowest custom budget for the customer(s)
			 * @returns {Number}
			 */
			lowestCustomBudget() {
				let income1 = this.affordabilityFormData.customDisposableIncomeRetirement;
				if (this.numberOfClients === 1) return income1;

				let income2 = this.affordabilityFormData.customDisposableIncomeRetirement2;
				return typeof income1 === 'number' && typeof income2 === 'number' ? (income1 < income2 ? income1 : income2) : null;
			},

			/**
			 * @name affordability
			 * @description Returns affordability for each product. Estimated disposable income is used for affordability check for standard and lifetime
			 * @returns {Array}
			 */
			affordability() {
				if (!this.isCustomerDetailsValid) return [null, null, null];
				let monthlyRepayment = this.monthlyRepayment;
				let budget = parseFloat(this.budget.toFixed(2));
				return [budget >= parseFloat(monthlyRepayment.standard.toFixed(2)), parseFloat(this.lowestPostRetirementBudget.toFixed(2)) >= parseFloat(monthlyRepayment.rio.toFixed(2)), budget >= parseFloat(monthlyRepayment.lifetime.toFixed(2))];
			},

			/**
			 * @name customerAffordability
			 * @description Returns customer affordability for each product. Custom disposable income is used for customer affordability check for standard and lifetime
			 * @returns {Array}
			 */
			customerAffordability() {
				// let isAffordabilityValid = Object.keys(this.affordabilityValidation).every((key) => !!this.affordabilityValidation[key]);
				if (!this.isCustomerDetailsValid || this.customBudget === null || this.lowestCustomBudget === null || !this.isAffordabilityValid) return [null, null, null];
				let monthlyRepayment = this.monthlyRepayment;
				let budget = parseFloat(this.customBudget.toFixed(2));
				return [budget >= parseFloat(monthlyRepayment.standard.toFixed(2)), parseFloat(this.lowestCustomBudget.toFixed(2)) >= parseFloat(monthlyRepayment.rio.toFixed(2)), budget >= parseFloat(monthlyRepayment.lifetime.toFixed(2))];
			},

			/**
			 * @name customer1Age
			 * @description Returns if customer 1 is eligible for each product based on age
			 * @returns {Array}
			 */
			customer1Age() {
				if (!this.customerDetailsValidation.personalForm) return [null, null, null];
				let age = this.personalFormData.customerAge;
				let standard = age >= this.backingData.standard.minAge && age <= this.backingData.standard.maxAge;
				let rio = age >= this.backingData.rio.minAge && age <= this.backingData.rio.maxAge;
				let lifetime = age >= this.backingData.lifetime.minAge && age <= this.backingData.lifetime.maxAge;
				return [standard, rio, lifetime];
			},

			/**
			 * @name customer2Age
			 * @description Returns if customer 2 is eligible for each product based on age
			 * @returns {Array}
			 */
			customer2Age() {
				if (!this.customerDetailsValidation.personalForm2) return [null, null, null];
				let age = this.personalFormData2.customerAge;
				let standard = age >= this.backingData.standard.minAge && age <= this.backingData.standard.maxAge;
				let rio = age >= this.backingData.rio.minAge && age <= this.backingData.rio.maxAge;
				let lifetime = age >= this.backingData.lifetime.minAge && age <= this.backingData.lifetime.maxAge;
				return [standard, rio, lifetime];
			},

			/**
			 * @name ltv
			 * @description Returns if the LTV is valid for each product
			 * @returns {Array}
			 */
			ltv() {
				if (!this.customerDetailsValidation.mortgageForm || !this.customerDetailsValidation.personalForm || (this.numberOfClients === 2 && !this.customerDetailsValidation.personalForm2)) return [null, null, null];
				let propertyValue = this.mortgageFormData.propertyValue;
				let requestedBorrowingAmount = this.mortgageFormData.requestedBorrowingAmount;
				let standard = (requestedBorrowingAmount / propertyValue) * 100 <= this.backingData.standard.maxLtv;
				let rio = (requestedBorrowingAmount / propertyValue) * 100 <= this.backingData.rio.maxLtv;
				let lifetime = (requestedBorrowingAmount / propertyValue) * 100 <= this.backingData.lifetime.maxLtv;
				return [standard, rio, lifetime];
			},

			/**
			 * @name eligibility
			 * @description Returns if the customer(s) is/are eligible for each product
			 * @returns {Array}
			 */
			eligibility() {
				if (!this.isCustomerDetailsValid) return [null, null, null];
				// if all criterias passed then true, otherwise false
				let age1 = this.customer1Age;
				let age2 = this.customer2Age;
				let ltv = this.ltv;
				let affordability = this.affordability;
				let customerAffordability = this.customerAffordability;

				let standard = age1[0] && (this.numberOfClients < 2 || age2[0]) && ltv[0] && affordability[0] && customerAffordability[0];
				let rio = age1[1] && (this.numberOfClients < 2 || age2[1]) && ltv[1] && affordability[1] && customerAffordability[1];
				let lifetime = age1[2] && (this.numberOfClients < 2 || age2[2]) && ltv[2];

				return [standard, rio, lifetime];
			},

			/**
			 * @name eligibilityAmber
			 * @description Returns if the eligibility is amber based on the customer(s) affordability
			 * @returns {Array}
			 */
			eligibilityAmber() {
				if (!this.isCustomerDetailsValid) return [null, null, null];
				let age1 = this.customer1Age;
				let age2 = this.customer2Age;
				let ltv = this.ltv;
				let affordability = this.affordability;
				let customerAffordability = this.customerAffordability;

				let standard = age1[0] && (this.numberOfClients < 2 || age2[0]) && ltv[0] && ((affordability[0] && !customerAffordability[0]) || (!affordability[0] && customerAffordability[0]));
				let rio = age1[1] && (this.numberOfClients < 2 || age2[1]) && ltv[1] && ((affordability[1] && !customerAffordability[1]) || (!affordability[1] && customerAffordability[1]));

				return [standard, rio, false];
			},

			/**
			 * @name isCustomerRetired
			 * @description Returns if the customer is retired
			 * @returns {Boolean}
			 */
			isCustomerRetired() {
				return this.personalFormData.isRetired || this.personalFormData.plannedRetirementAge - this.personalFormData.customerAge <= 5;
			},

			/**
			 * @name isCustomer2Retired
			 * @description Returns if the customer 2 is retired
			 * @returns {Boolean}
			 */
			isCustomer2Retired() {
				return this.personalFormData2.isRetired || this.personalFormData2.plannedRetirementAge - this.personalFormData2.customerAge <= 5;
			},

			/**
			 * @name isCustomerDetailsValid
			 * @description Returns if the customer details form is valid
			 * @returns {Boolean}
			 */
			isCustomerDetailsValid() {
				return Object.keys(this.customerDetailsValidation)
					.filter((v) => v !== 'personalForm2' || this.numberOfClients == 2)
					.every((key) => this.customerDetailsValidation[key] === true);
			},

			/**
			 * @name isAffordabilityValid
			 * @description Returns if the affordability form is valid
			 * @returns {Boolean}
			 */
			isAffordabilityValid() {
				let validation = {
					customDisposableIncome: this.isCustomerRetired || this.affordabilityValidation.customDisposableIncome,
					customDisposableIncomeRetirement: this.affordabilityValidation.customDisposableIncomeRetirement,
					customDisposableIncome2: this.numberOfClients === 1 || this.isCustomer2Retired || this.affordabilityValidation.customDisposableIncome2,
					customDisposableIncomeRetirement2: this.numberOfClients === 1 || this.affordabilityValidation.customDisposableIncomeRetirement2
				};

				return Object.keys(validation).every((key) => !!validation[key]);
			},

			/**
			 * @name s3Key
			 * @description S3 key for form data
			 * @returns {Object}
			 */
			s3Key() {
				if (!this.id) return null;
				return `form/${this.id}.json`;
			},

			/**
			 * @name payload
			 * @description Constructs the payload for the API request
			 * @returns {Object}
			 */
			payload() {
				const payload = {
					id: this.id,
					reference: this.customerReference,
					fcaNumber: this.fcaNumber,
					numberOfClients: this.numberOfClients,
					step: this.step
				};

				if (this.step > 1) {
					payload.clients = [];

					if (this.numberOfClients === 1) {
						payload.clients.push({
							age: this.personalFormData.customerAge,
							isRetired: this.personalFormData.isRetired,
							plannedRetirementAge: this.personalFormData.plannedRetirementAge,
							income: this.personalFormData.isRetired ? null : this.personalFormData.income,
							incomeRetirement: this.personalFormData.incomeRetirement
						});
					} else if (this.numberOfClients === 2) {
						payload.clients.push({
							age: this.personalFormData.customerAge,
							isRetired: this.personalFormData.isRetired,
							plannedRetirementAge: this.personalFormData.plannedRetirementAge,
							income: this.personalFormData.isRetired ? null : this.personalFormData.income,
							incomeRetirement: this.personalFormData.incomeRetirement
						});
						payload.clients.push({
							age: this.personalFormData2.customerAge,
							isRetired: this.personalFormData2.isRetired,
							plannedRetirementAge: this.personalFormData2.plannedRetirementAge,
							income: this.personalFormData2.isRetired ? null : this.personalFormData2.income,
							incomeRetirement: this.personalFormData2.incomeRetirement
						});
					}

					payload.mortgage = {
						propertyValue: this.mortgageFormData.propertyValue,
						requestedBorrowingAmount: this.mortgageFormData.requestedBorrowingAmount
					};

					payload.expenditure = this.expenditureFormData.expenditure;
				}

				if (this.step > 2) {
					payload.clients[0].preferredDisposableIncomeRetirement = this.affordabilityFormData.customDisposableIncomeRetirement;
					if (!this.personalFormData.isRetired) payload.clients[0].preferredDisposableIncome = this.affordabilityFormData.customDisposableIncome;
					if (this.numberOfClients === 2) {
						payload.clients[1].preferredDisposableIncomeRetirement = this.affordabilityFormData.customDisposableIncomeRetirement2;
						if (!this.personalFormData2.isRetired) payload.clients[1].preferredDisposableIncome = this.affordabilityFormData.customDisposableIncome2;
					}

					payload.affordabilityNotes = this.affordabilityFormData.customerIncomeNotes || '';
				}

				if (this.step > 3) {
					payload.priorities = this.priorities;
				}

				if (this.step > 4) {
					payload.additionalInformation = this.additionalInformation.text || '';
				}

				return payload;
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			...mapActions('App', ['updateJson', 'getJson']),

			/**
			 * @name init
			 * @description Initialises the component
			 */
			async init() {
				if (this.$route.query.id) {
					this.id = this.$route.query.id;
					try {
						const { data } = await this.getJson({ s3Key: this.s3Key, filename: `${this.id}.json` });
						this.setData(data);
					} catch (error) {
						alert('Failed to load existing session'); // TODO: Use snackbar?
					}
				} else {
					this.id = uuid();
				}

				this.loaded = true;
			},

			/**
			 * @name setData
			 * @description Gets the data from API and populates the fields
			 */
			setData(data) {
				this.step = data.step || 1;
				this.customerReference = data.reference;
				this.fcaNumber = data.fcaNumber;
				this.numberOfClients = data.numberOfClients;

				if (data.clients) {
					data.clients.forEach((client, index) => {
						const ref = index === 0 ? 'personalFormData' : 'personalFormData2';
						Object.keys(this[ref]).forEach((k) => {
							const lookupKey = k === 'customerAge' ? 'age' : k; // Can we not keep this consistent
							if (client[lookupKey]) this[ref][k] = client[lookupKey];
						});
					});
				}

				if (data.mortgage) {
					Object.keys(this.mortgageFormData).forEach((k) => {
						if (data.mortgage[k]) this.mortgageFormData[k] = data.mortgage[k];
					});
				}

				if (this.step === 1) {
					this.chatConfig = {
						step: 3,
						messages: [
							{ text: 'First things first, lets add a reference for your customer - most people tend to use a surname or a numerical reference.', posted: 'Just now', isBot: true },
							{ text: data.reference, isBot: false },
							{ text: `Thanks, we\'ll use "${data.reference}" as your reference. Next, can you enter your FCA Firm Reference Number?`, posted: 'Just now', isBot: true },
							{ text: data.fcaNumber, isBot: false },
							{ text: 'Thanks for providing your Firm Reference Number. Lastly, can you tell me how many customers there are?', posted: 'Just now', isBot: true },
							{ text: '', isBot: false, shouldAnswerWithButton: true },
							{ text: "Thanks for your answers. When you're ready, go to next step to load up the calculator. Remember to save a PDF for your records as this session will not be saved.", posted: 'Just now', isBot: true }
						]
					};
					this.expenditureFormData.expenditure = this.expenditureFormData.expenditure || (this.numberOfClients > 1 ? 1000 : 500);
					if (data.step) this.step++; // Only go to next step if available in the form data from s3
					return;
				}

				this.expenditureFormData.expenditure = data.expenditure;

				this.customerDetailsValidation = {
					personalForm: true,
					personalForm2: this.numberOfClients === 2 ? true : false,
					mortgageForm: true,
					expenditureForm: true
				};

				if (this.step === 2) {
					this.step++;

					if (this.affordabilityFormData.customDisposableIncome === null || !this.affordabilityValidation.customDisposableIncome) this.affordabilityFormData.customDisposableIncome = parseFloat(this.estimatedDisposableIncome1.toFixed(2));
					if (this.affordabilityFormData.customDisposableIncomeRetirement === null || !this.affordabilityValidation.customDisposableIncomeRetirement)
						this.affordabilityFormData.customDisposableIncomeRetirement = parseFloat(this.estimatedDisposableIncomeRetirement1.toFixed(2));
					if (this.numberOfClients > 1) {
						if (this.affordabilityFormData.customDisposableIncome2 === null || !this.affordabilityValidation.customDisposableIncome2) this.affordabilityFormData.customDisposableIncome2 = parseFloat(this.estimatedDisposableIncome2.toFixed(2));
						if (this.affordabilityFormData.customDisposableIncomeRetirement2 === null || !this.affordabilityValidation.customDisposableIncomeRetirement2)
							this.affordabilityFormData.customDisposableIncomeRetirement2 = parseFloat(this.estimatedDisposableIncomeRetirement2.toFixed(2));
					}

					return;
				}

				this.affordabilityFormData.customDisposableIncomeRetirement = data.clients[0].preferredDisposableIncomeRetirement;
				if (!this.personalFormData.isRetired) this.affordabilityFormData.customDisposableIncome = data.clients[0].preferredDisposableIncome;
				if (this.numberOfClients === 2) {
					this.affordabilityFormData.customDisposableIncomeRetirement2 = data.clients[1].preferredDisposableIncomeRetirement;
					this.affordabilityFormData.customDisposableIncome2 = data.clients[1].preferredDisposableIncome;
				}
				this.affordabilityFormData.customerIncomeNotes = data.affordabilityNotes;

				this.affordabilityValidation = {
					customDisposableIncome: true,
					customDisposableIncomeRetirement: true,
					customDisposableIncome2: true,
					customDisposableIncomeRetirement2: true
				};

				if (this.step === 3) {
					this.step++;
					return;
				}

				this.priorities = data.priorities;

				if (this.step === 4) {
					this.step++;
					return;
				}

				this.additionalInformation.text = data.additionalInformation;
			},

			/**
			 * @name helpDialogOpen
			 * @description Opens the help dialog
			 */
			helpDialogOpen() {
				this.$refs.helpDialog.open();
			},

			/**
			 * @name disclaimerDialogOpen
			 * @description Opens the disclaimer dialog
			 */
			disclaimerDialogOpen() {
				this.$refs.disclaimerDialog.open();
			},

			/**
			 * @name supportingMaterialDialogOpen
			 * @description Opens the supporting material dialog
			 */
			supportingMaterialDialogOpen() {
				this.$refs.supportingMaterialDialog.open();
			},

			/**
			 * @name updateExpenses
			 * @description Event handler for updating the expenses
			 * @param {Number} val
			 */
			updateExpenses(val) {
				this.expenses = val;
			},

			/**
			 * @name getValue
			 * @description Returns the eligibility status of a criteria
			 * @param {Object} row
			 * @param {Number} index
			 */
			getValue(row, index) {
				switch (row.name) {
					case 'age':
						return this.numberOfClients > 1 ? this.customer1Age[index] && this.customer2Age[index] : this.customer1Age[index];
					case 'age1':
						return this.customer1Age[index];
					case 'age2':
						return this.customer2Age[index];
					case 'ltv':
						return this.ltv[index];
					case 'affordability':
						return this.affordability[index];
					case 'customerAffordability':
						return this.customerAffordability[index];
					case 'eligibility':
						return this.eligibility[index];
					default:
						break;
				}
			},

			/**
			 * @name chipColor
			 * @description Returns the chip color for a criteria
			 * @param {Object} row
			 * @param {Number} index
			 */
			chipColor(row, index) {
				return this.getValue(row, index) == null ? 'grey lighten-2' : this.getValue(row, index) ? 'success' : row.name === 'customerAffordability' ? 'amber' : 'error';
			},

			/**
			 * @name chatNextStep
			 * @description Chat next step event handler
			 * @param {String} customerReference
			 * @param {Number} numberOfClients
			 */
			chatNextStep({ customerReference, numberOfClients, fcaNumber }) {
				this.customerReference = customerReference;
				this.numberOfClients = numberOfClients;
				this.fcaNumber = fcaNumber;
				this.updateJson({ s3Key: this.s3Key, payload: this.payload });
				this.expenditureFormData.expenditure = this.expenditureFormData.expenditure || (this.numberOfClients > 1 ? 1000 : 500);
				this.step++;
			},

			/**
			 * @name customerDetailsNextStep
			 * @description Customer details next step event handler
			 */
			customerDetailsNextStep() {
				this.updateJson({ s3Key: this.s3Key, payload: this.payload });
				this.step++;

				if (this.affordabilityFormData.customDisposableIncome === null || !this.affordabilityValidation.customDisposableIncome) this.affordabilityFormData.customDisposableIncome = parseFloat(this.estimatedDisposableIncome1.toFixed(2));
				if (this.affordabilityFormData.customDisposableIncomeRetirement === null || !this.affordabilityValidation.customDisposableIncomeRetirement)
					this.affordabilityFormData.customDisposableIncomeRetirement = parseFloat(this.estimatedDisposableIncomeRetirement1.toFixed(2));
				if (this.numberOfClients > 1) {
					if (this.affordabilityFormData.customDisposableIncome2 === null || !this.affordabilityValidation.customDisposableIncome2) this.affordabilityFormData.customDisposableIncome2 = parseFloat(this.estimatedDisposableIncome2.toFixed(2));
					if (this.affordabilityFormData.customDisposableIncomeRetirement2 === null || !this.affordabilityValidation.customDisposableIncomeRetirement2)
						this.affordabilityFormData.customDisposableIncomeRetirement2 = parseFloat(this.estimatedDisposableIncomeRetirement2.toFixed(2));
				}
			},

			/**
			 * @name affordabilityNextStep
			 * @description Affordability next step event handler
			 */
			async affordabilityNextStep() {
				// let isValid = Object.keys(this.affordabilityValidation).every((key) => !!this.affordabilityValidation[key]);
				if (!this.isAffordabilityValid) return;
				this.updateJson({ s3Key: this.s3Key, payload: this.payload });
				this.step++;
			},

			/**
			 * @name prioritiesNextStep
			 * @description Priorities next step event handler
			 */
			prioritiesNextStep() {
				this.updateJson({ s3Key: this.s3Key, payload: this.payload });
				this.step++;
			},

			/**
			 * @name pmt
			 * @description Calculates the monthly repayment
			 * @param {Number} rate
			 * @param {Number} nper
			 * @param {Number} pv
			 * @returns {Number}
			 */
			pmt(rate, nper, pv) {
				return (rate * pv * Math.pow(1 + rate, nper)) / (1 - Math.pow(1 + rate, nper));
			},

			/**
			 * @name getTooltipContent
			 * @description Returns the tooltip content for a criteria
			 * @param {Object} row
			 * @param {Number} columnIndex
			 * @returns {String}
			 */
			getTooltipContent(row, columnIndex) {
				let content = null;

				if (row.name === 'eligibility') {
					if (this.customer1Age[columnIndex - 1] && !(this.numberOfClients > 1 && !this.customer2Age[columnIndex - 1]) && this.ltv[columnIndex - 1] && !(!this.customerAffordability[columnIndex - 1] && columnIndex !== 3))
						return 'This product was calculated to be ineligible based on the Calculated Affordability not being met. Customer Preferred payment amount shows this product could be available.';

					if (this.customer1Age[columnIndex - 1] && !(this.numberOfClients > 1 && !this.customer2Age[columnIndex - 1]) && this.ltv[columnIndex - 1] && !(!this.affordability[columnIndex - 1] && columnIndex !== 3))
						return 'This product could be available based on the Customer(s) Preferred Affordability.';

					content = 'This product has been calculated to be ineligible after the following criteria was not met</br><ul class="criteria">';

					if (!this.customer1Age[columnIndex - 1]) {
						content += `<li>Customer 1 Age</li>`;
					}
					if (this.numberOfClients > 1 && !this.customer2Age[columnIndex - 1]) {
						content += `<li>Customer 2 Age</li>`;
					}
					if (!this.ltv[columnIndex - 1]) {
						content += `<li>LTV</li>`;
					}
					if (!this.affordability[columnIndex - 1] && columnIndex !== 3) {
						content += `<li>Calculated Affordability</li>`;
					}
					// if (!this.customerAffordability[columnIndex - 1] && columnIndex !== 3) {
					// 	content += `<li>Customer Preferred Affordability</li>`;
					// }

					content += '</ul>';

					return content;
				}

				let criteria = this.outsideCriteria.find((c) => row.name === c.name || row.name.includes(c.name));
				let product = this.columns[columnIndex].value;

				if (criteria) {
					if (criteria.name === 'affordability') {
						let budget = product === 'rio' ? this.lowestPostRetirementBudget : this.budget;
						content = (content || criteria.text).replace('{monthlyRepayment}', this.monthlyRepayment[product].toFixed(2));
						content = (content || criteria.text).replace('{budget}', budget.toFixed(2));
					} else if (criteria.name === 'customerAffordability') {
						let budget = product === 'rio' ? this.lowestCustomBudget : this.customBudget;
						content = (content || criteria.text).replace('{monthlyRepayment}', this.monthlyRepayment[product].toFixed(2));
						content = (content || criteria.text).replace('{customBudget}', budget.toFixed(2));
					} else {
						let matches = criteria.text.match(/{(.*?)}/g);
						if (matches) {
							matches.forEach((match) => {
								let key = match.replace('{', '').replace('}', '');
								content = (content || criteria.text).replace(match, this.backingData[product][key]);
							});
						}
					}
				}

				return content;
			}
		}
	};
</script>

<style scoped lang="scss">
	.productTable {
		font-size: 0.9rem;
		thead {
			background-color: #ddd;
		}
	}

	.inline-field {
		display: inline-block;
		font-weight: 600;
		border-bottom: 2px dotted #979797;
		line-height: 1.3;
		cursor: pointer;
	}

	::v-deep {
		.criteria {
			color: #fff;
			li {
				color: #fff !important;
			}
		}
	}
</style>
