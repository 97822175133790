<template>
	<div>
		<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark color="teal" class="pl-3 pr-3 flex-grow-0">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Supporting Materials
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="dialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 ma-0 mx-auto" style="max-width: 936px">
					<div>
						<v-sheet>
							<h2 class="text-body-1 font-weight-bold mb-1">
								Check your State Pension forecast
							</h2>
							<p class="text-body-2 mb-2">
								Use this link to help calculate your customer(s) post-retirements income. Your customer will have to log into the government gateway portal and enter their details to access their information.
							</p>
							<a class="text-body-2" href="https://www.gov.uk/check-state-pension" target="_blank">
								https://www.gov.uk/check-state-pension
							</a>
						</v-sheet>
						<v-divider class="my-5" />
						<v-sheet>
							<h2 class="text-body-1 font-weight-bold mb-1">
								Pensions &amp; retirement
							</h2>
							<p class="text-body-2 mb-2">
								Use this link to access a range of articles and guides for any questions you may have on pensions and retirement.
							</p>
							<a class="text-body-2" href="https://www.moneyhelper.org.uk/en/pensions-and-retirement" target="_blank">
								https://www.moneyhelper.org.uk/en/pensions-and-retirement
							</a>
						</v-sheet>
						<v-divider class="my-5" />
						<v-sheet>
							<h2 class="text-body-1 font-weight-bold mb-1">
								Pension Wise
							</h2>
							<p class="text-body-2 mb-2">
								Pension Wise is a service from MoneyHelper, backed by government. They offer free, impartial guidance to over 50s.
							</p>
							<a class="text-body-2" href="https://www.moneyhelper.org.uk/en/pensions-and-retirement/pension-wise?source=pw" target="_blank">
								https://www.moneyhelper.org.uk/en/pensions-and-retirement/pension-wise?source=pw
							</a>
						</v-sheet>
					</div>
				</v-container>
			</v-card>
			<!-- <common-dialog ref="eligibiltyDialog" color="primary" fullscreen> -->
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: 'app-common-help-dialog',

		data() {
			return {
				dialog: false
			};
		},

		methods: {
			open() {
				this.dialog = true;
			}
		}
	};
</script>
