import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '@/components/app';
import EligibilityPriorities from '@/components/app/eligibility-priorities';
import ProductComparison from '@/components/app/product-comparison';
import CookiePolicy from '@/components/common/cookie-policy';

Vue.use(VueRouter);

const routes = [
	{ path: '/', name: 'dashboard', component: Dashboard, meta: { breadcrumb: [{ text: 'Dashboard' }] } },
	{ path: '/eligibility-and-priorities', name: 'tool-1', component: EligibilityPriorities, meta: { breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Eligibility and Priorities' }] } },
	{ path: '/product-comparision', name: 'tool-2', component: ProductComparison, meta: { breadcrumb: [{ text: 'Dashboard', to: '/' }, { text: 'Product Comparison' }] } },
	{ path: '/cookie-policy', name: 'cookiePolicy', meta: { public: true, layout: 'blank' }, component: CookiePolicy }
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;
