<template>
	<v-app>
		<layout v-if="site" />
	</v-app>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	import Layout from './components/layout';

	export default {
		name: 'app',

		components: {
			Layout
		},

		data: () => ({
			//
		}),

		computed: {
			...mapState('App', ['site'])
		},

		created() {
			this.getSiteConfig();
		},

		methods: {
			...mapActions('App', ['getSiteConfig'])
		}
	};
</script>
