<template>
	<div>
		<v-dialog v-model="helpDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark color="deep-orange" class="pl-3 pr-3 flex-grow-0">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Disclaimers
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="helpDialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 ma-0 mx-auto" style="max-width: 1200px">
					<v-sheet class="text-body-2 grey--text text--darken-2 mb-3">
						<v-icon color="grey" class="mr-1">
							mdi-alert-circle
						</v-icon>
						<span>The figures populated are an indicative values only, please use the KFI information to compare products.</span>
					</v-sheet>

					<div class="c9 doc-content">
						<table class="mt-6">
							<tr class="c0">
								<td class="c6 header" colspan="1" rowspan="1" width="16%">
									<p class="c1">
										<span class="c3" />
									</p>
								</td>
								<td class="header font-weight-bold" colspan="1" rowspan="1" width="16%">
									<p class="c2">
										<span class="c3">Interest Rate</span>
									</p>
								</td>
								<td class="header font-weight-bold" colspan="1" rowspan="1" width="16%">
									<p class="c2">
										<span class="c3">Standard Variable Rate</span>
									</p>
								</td>
								<td class="header font-weight-bold" colspan="1" rowspan="1" width="52%">
									<p class="c2">
										<span class="c3">Term &amp; Interest Calculation</span>
									</p>
								</td>
							</tr>
							<tr class="c0">
								<td class="c5" colspan="1" rowspan="1">
									<p class="c2">
										<span class="font-weight-bold">Standard Residential Capital and Interest</span>
									</p>
								</td>
								<td class="c6" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">{{ site.data.standard.aer }}%</span>
									</p>
								</td>
								<td class="c6" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">{{ site.data.standard.svr }}%</span>
									</p>
								</td>
								<td class="c6" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">The assumed length of term for this product is 15 years.</span>
									</p>
									<p class="c2">
										<span class="c3">Five years at the fixed rate below and the last ten years at the standard variable rate below.</span>
									</p>
									<br />
									<p class="c2">
										<span class="c3">The fixed rate monthly repayment is made every month for the first five years and then the standard variable monthly repayment is made every month for the remaining ten years.</span>
									</p>
									<p class="c1">
										<span class="c3" />
									</p>
									<p class="c2">
										<span class="c3">Example</span>
									</p>
									<p class="c2">
										<span class="c3">On a borrowing amount of &pound;100,000</span>
									</p>
									<p class="c2">
										<span class="c3">The first five years are calculated at 4.79% with a monthly repayment of &pound;779.90.</span>
									</p>
									<p class="c2">
										<span class="c3">The remaining 10 years are calculated at 8.74% with a monthly repayment of &pound;930.10.</span>
									</p>
									<p class="c2">
										<span class="c3">Resulting in a total cost of borrowing over 15 years of &pound;158,406.41.</span>
									</p>
								</td>
							</tr>
							<tr class="c0">
								<td class="c5" colspan="1" rowspan="1">
									<p class="c2">
										<span class="font-weight-bold">RIO</span>
									</p>
								</td>
								<td class="c7" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">{{ site.data.rio.aer }}%</span>
									</p>
								</td>
								<td class="c7" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">{{ site.data.rio.svr }}%</span>
									</p>
								</td>
								<td class="c7" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">The assumed length of term for this product is 15 years.</span>
									</p>
									<p class="c2">
										<span class="c3">Five years at the fixed rate below and the last ten years at the standard variable rate below.</span>
									</p>
									<br />
									<p class="c2">
										<span class="c3">The first five years are calculated at the fixed interest rate and the last ten years are calculated at the standard variable amount.</span>
									</p>
									<p class="c1">
										<span class="c3" />
									</p>
									<p class="c2">
										<span class="c3">Example</span>
									</p>
									<p class="c2">
										<span class="c3">On a borrowing amount of &pound;100,000</span>
									</p>
									<p class="c2">
										<span class="c3">The first five years are calculated at 6.14% with a monthly repayment of &pound;511.67.</span>
									</p>
									<p class="c2">
										<span class="c3">The remaining 10 years are calculated at 7.79% with a monthly repayment of &pound;649.17.</span>
									</p>
									<p class="c2">
										<span class="c3">Resulting in a total cost of borrowing over 15 years of &pound;208,600.00.</span>
									</p>
								</td>
							</tr>
							<tr class="c0">
								<td class="c5" colspan="1" rowspan="1">
									<p class="c2">
										<span class="font-weight-bold">Lifetime - Optional Payment</span>
									</p>
								</td>
								<td class="c4" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">{{ site.data.lifetimeOptional.aer }}%</span>
									</p>
								</td>

								<td class="c4" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">NA</span>
									</p>
								</td>
								<td class="c4" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">The assumed length of term for this product is 15 years.</span>
									</p>
									<p class="c2">
										<span class="c3">The assumed repayment term for this product is 15 years.</span>
									</p>
									<br />
									<p class="c2">
										<span class="c3">The fixed rate monthly repayment is made every month for the full 15 year term.</span>
									</p>
									<p class="c1">
										<span class="c3" />
									</p>
									<p class="c2">
										<span class="c3">Example</span>
									</p>
									<p class="c2">
										<span class="c3">On a borrowing amount of &pound;100,000</span>
									</p>
									<p class="c2">
										<span class="c3">The full 15-year term is calculated 6.75% with repayments equalling 100% of the interest of &pound;526.50.</span>
									</p>
									<p class="c2">
										<span class="c3">Resulting in a total cost of borrowing of &pound;201,250.00.</span>
									</p>
								</td>
							</tr>
							<tr class="c0">
								<td class="c5" colspan="1" rowspan="1">
									<p class="c2">
										<span class="font-weight-bold">Lifetime</span>
									</p>
								</td>
								<td class="c4" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">{{ site.data.lifetime.aer }}%</span>
									</p>
								</td>
								<td class="c4" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">NA</span>
									</p>
								</td>
								<td class="c4" colspan="1" rowspan="1">
									<p class="c2">
										<span class="c3">The assumed length of term for this product is 15 years</span>
									</p>
									<br />
									<p class="c2">
										<span class="c3">Interest is compounded each month on the fixed interest rate for the full 15 years.</span>
									</p>
									<p class="c1">
										<span class="c3" />
									</p>
									<p class="c2">
										<span class="c3">Example</span>
									</p>
									<p class="c2">
										<span class="c3">On a borrowing amount of &pound;100,000</span>
									</p>
									<p class="c2">
										<span class="c3">The full 15-year term interest is compounded at 6.69%.</span>
									</p>
									<p class="c2">
										<span class="c3">Resulting in a total cost of borrowing of &pound;264,153.00.</span>
									</p>
								</td>
							</tr>
						</table>
						<p class="c8">
							<span class="c3" />
						</p>
					</div>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		name: 'app-common-help-dialog',

		data() {
			return {
				helpDialog: false
			};
		},

		computed: {
			...mapState('App', ['site'])
		},

		methods: {
			open() {
				this.helpDialog = true;
			}
		}
	};
</script>

<style type="text/css" lang="scss" scoped>
	@import url(https://themes.googleusercontent.com/fonts/css?kit=wAPX1HepqA24RkYW1AuHYA);

	ol {
		margin: 0;
		padding: 0;
	}

	table {
		border-spacing: 0;
		border-collapse: collapse;
		margin-right: auto;

		td,
		th {
			padding: 8px !important;
			vertical-align: top !important;
			text-align: left !important;
			font-size: 1rem !important;
			line-height: 1.6rem !important;
			border: 1pt solid #000000 !important;

			&.header {
				background-color: #e7e6e6;
			}

			p {
				text-align: left !important;
				font-size: 0.9rem !important;
				line-height: 1.3rem !important;
				margin-bottom: 0 !important;
			}
		}
	}
</style>
