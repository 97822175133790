<template>
	<v-breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" style="background: #fff">
		<template #item="{ item }">
			<v-breadcrumbs-item :to="item.to" :disabled="item.disabled" class="text-subtitle-2" exact>
				{{ item.text }}
			</v-breadcrumbs-item>
		</template>
	</v-breadcrumbs>
</template>

<script>
	export default {
		name: 'layout-breadcrumb',
		computed: {
			referrer() {
				return this.$route.query.referrer || null;
			},

			breadcrumbs() {
				return this.$route.meta?.breadcrumb.map((b) => {
					if (this.referrer) b.disabled = true;
					return b;
				});
			}
		}
	};
</script>
