/**
 * @namespace Utils
 * @class Storage
 * @exports Utils/$localStorage <instance>
 * @exports Utils/$sessionStorage <instance>
 * @description Util for notifications
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
class Storage {
	constructor(storage) {
		this.storage = storage || window.localStorage;

		if (!this.isAvailable()) throw new Error('Storage is not supported by browser!');
	}

	/**
	 * @static @name isAvailable
	 * @description TODO
	 * @return {TODO} TODO
	 */
	isAvailable() {
		return typeof this.storage !== 'undefined';
	}

	/**
	 * @static @name set
	 * @description TODO
	 * @param {TODO} key TODO
	 * @param {TODO} value TODO
	 * @return {TODO} TODO
	 */
	set(key, value) {
		if (value && typeof value === 'object') {
			value = JSON.stringify(value);
		}

		this.storage.setItem(key, value);
	}

	/**
	 * @static @name get
	 * @description TODO
	 * @param {TODO} key TODO
	 * @return {TODO} TODO
	 */
	get(key) {
		let value;

		try {
			value = JSON.parse(this.storage.getItem(key));
		} catch (err) {
			value = this.storage.getItem(key);
		}

		return value;
	}

	/**
	 * @static @name delete
	 * @description TODO
	 * @param {TODO} key TODO
	 * @return {TODO} TODO
	 */
	delete(key) {
		this.storage.removeItem(key);
	}

	/**
	 * @static @name hideDialog
	 * @description TODO
	 * @param {String} name TODO
	 * @return {TODO} TODO
	 */
	hideDialog(name) {
		return this.set(`dialogs.${name}`, 'hidden');
	}

	/**
	 * @static @name resetDialog
	 * @description TODO
	 * @param {String} name TODO
	 * @return {TODO} TODO
	 */
	resetDialog(name) {
		return this.delete(`dialogs.${name}`);
	}

	/**
	 * @static @name isDialogHidden
	 * @description TODO
	 * @param {TODO} name TODO
	 * @return {TODO} TODO
	 */
	isDialogHidden(name) {
		if (this.get(`dialogs.${name}`) === 'hidden') return true;

		return false;
	}
}

const $localStorage = new Storage();
const $sessionStorage = new Storage(window.sessionStorage);

export { $localStorage, $sessionStorage };
