<template>
	<div>
		<v-dialog v-model="helpDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark color="pink" class="pl-3 pr-3 flex-grow-0">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Help
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="helpDialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 ma-0 mx-auto" style="max-width: 936px">
					<div class="c11">
						<table class="mt-6" width="100%">
							<tr class="c12">
								<td class="header font-weight-bold" colspan="1" rowspan="1">
									<p class="c0">
										<span>Question</span>
									</p>
								</td>
								<td class="header font-weight-bold" colspan="1" rowspan="1">
									<p class="c0">
										<span>Guidance</span>
									</p>
								</td>
							</tr>
							<tr class="c2">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>Customer Reference</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c0">
										<span class="c7">This should be a unique reference for this session</span>
									</p>
								</td>
							</tr>
							<tr class="c16">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>Fixed Rate (%)</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c10">
										<span class="c7">These fields are pre populated from a data sheet for each product range, however these can be overwritten. Please use AER for Standard Residential, RIO and Lifetime (no payments). Please use MER for Lifetime (optional payments) </span>
									</p>
								</td>
							</tr>
							<tr class="c5">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>SVR (%)</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c10">
										<span class="c7">These fields are pre populated from a data sheet for each product range, however these can be overwritten </span>
									</p>
								</td>
							</tr>
							<tr class="c5">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>Borrowing amount</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c10">
										<span class="c7">Enter the customer(s) borrowing amount </span>
									</p>
								</td>
							</tr>
							<tr class="c5">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>Monthly repayment fixed</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c10">
										<span class="c7">Calculated fields once the borrowing amount is entered </span>
									</p>
								</td>
							</tr>
							<tr class="c5">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>Monthly repayment SVR</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c10">
										<span class="c7">Calculated fields once the borrowing amount is entered </span>
									</p>
								</td>
							</tr>
							<tr class="c5">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>Customer budget</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c10">
										<span class="c7">Enter your customer(s) monthly budget</span>
									</p>
								</td>
							</tr>
							<tr class="c5">
								<td class="c4" colspan="1" rowspan="1">
									<p class="c0">
										<span>Total cost of borrowing</span>
									</p>
								</td>
								<td class="c1" colspan="1" rowspan="1">
									<p class="c10">
										<span class="c7">Calculated fields once the borrowing amount is entered </span>
									</p>
								</td>
							</tr>
						</table>
					</div>
				</v-container>
			</v-card>
			<!-- <common-dialog ref="eligibiltyDialog" color="primary" fullscreen> -->
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: 'app-common-help-dialog',

		data() {
			return {
				helpDialog: false
			};
		},

		methods: {
			open() {
				this.helpDialog = true;
			}
		}
	};
</script>

<style type="text/css" lang="scss" scoped>
	ol {
		margin: 0;
		padding: 0;
	}

	table {
		border-spacing: 0;
		border-collapse: collapse;
		margin-right: auto;

		td, th {
			padding: 8px !important;
			vertical-align: top !important;
			text-align: left !important;
			font-size: 1rem !important;
			line-height: 1.6rem !important;
			border: 1pt solid #000000 !important;

			&.header {
				background-color: #e7e6e6;
			}

			p {
				text-align: left !important;
				font-size: .9rem !important;
				line-height: 1.3rem !important;
				margin-bottom: 0 !important;
			}
		}
	}
</style>
