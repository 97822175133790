<template>
	<div>
		<v-sheet elevation="3" rounded class="pa-8 pt-2">
			<v-timeline reverse class="pt-6">
				<p class="text-body-1 font-weight-medium mb-10">
					To get started, please answer a couple of quick questions.
				</p>
				<v-slide-y-transition group>
					<template v-for="(message, index) in config.messages">
						<!-- Text input for the other steps -->
						<v-timeline-item v-if="message?.id === 1 && config.step === 0" :key="index" color="primary" small :right="message.isBot" :left="!message.isBot">
							<v-card :color="!message.isBot ? 'grey lighten-4' : 'white'" class="py-2 px-6" width="400">
								<div class="d-flex flex-row align-center">
									<div class="flex-grow-1">
										<validation-observer ref="referenceObserver">
											<validation-provider v-slot="{ errors }" name="Customer Reference" :rules="'required'">
												<v-text-field v-model="customerReference" :error-messages="errors" placeholder="Type Something" @keypress.enter="submitReference" />
											</validation-provider>
										</validation-observer>
									</div>
									<v-btn icon class="ml-4" @click="submitReference">
										<v-icon>mdi-send</v-icon>
									</v-btn>
								</div>
							</v-card>
						</v-timeline-item>
						<v-timeline-item v-else-if="message?.id === 2 && config.step === 1" :key="index" color="primary" small :right="message.isBot" :left="!message.isBot">
							<v-card :color="!message.isBot ? 'grey lighten-4' : 'white'" class="py-2 px-6" width="400">
								<div class="d-flex flex-row align-center">
									<div class="flex-grow-1">
										<validation-observer ref="fcaNumberObserver">
											<validation-provider v-slot="{ errors }" name="Firm Reference Number (FRN)" :rules="'required|numeric|min:6|max:7'">
												<v-text-field v-model="fcaNumber" :error-messages="errors" placeholder="Firm Reference Number (FRN)" @keypress.enter="submitFcaNumber" />
											</validation-provider>
										</validation-observer>
									</div>
									<v-btn icon class="ml-4" @click="submitFcaNumber">
										<v-icon>mdi-send</v-icon>
									</v-btn>
								</div>
							</v-card>
						</v-timeline-item>
						<v-timeline-item v-else :key="index" color="primary" small :right="message.isBot" :left="!message.isBot">
							<v-avatar v-if="message.isBot" size="56" class="mr-6 mt-1" rounded="0">
								<img alt="Avatar" :src="'logo/site/chatbot.png' | urlize" />
							</v-avatar>
							<v-card :color="!message.isBot ? 'grey lighten-4' : 'white'">
								<v-card-text v-if="message.text" class="text-body-1 grey--text text--darken-2">
									{{ message.text }}
								</v-card-text>
								<v-card-text v-if="message.posted" class="text-body-2 font-italic pt-0">
									{{ message.posted }}
								</v-card-text>

								<v-item-group v-if="message.shouldAnswerWithButton" v-model="numberOfClients" disabled>
									<v-container>
										<v-row class="py-2 px-4">
											<v-col cols="12" md="6" class="pa-2">
												<v-item v-slot="{ active, toggle }" :value="1">
													<v-card :class="active ? 'primary white--text' : 'white grey--text text--darken-2'" class="py-2 px-5" style="border: 2px solid" @click="toggle">
														<div class="text-body-1 flex-grow-1 text-center text-no-wrap">
															1 Customer
														</div>
													</v-card>
												</v-item>
											</v-col>
											<v-col cols="12" md="6" class="pa-2">
												<v-item v-slot="{ active, toggle }" :value="2">
													<v-card :class="active ? 'primary white--text' : 'white grey--text text--darken-2'" class="py-2 px-5" style="border: 2px solid" @click="toggle">
														<div class="text-body-1 flex-grow-1 text-center text-no-wrap">
															2 Customers
														</div>
													</v-card>
												</v-item>
											</v-col>
										</v-row>
									</v-container>
								</v-item-group>
							</v-card>
						</v-timeline-item>
					</template>
				</v-slide-y-transition>
			</v-timeline>

			<div v-if="config.step === 3" class="text-center">
				<v-divider class="mt-6 mb-10" />
				<v-btn color="primary" x-large @click="nextStep">
					Next Step
				</v-btn>
			</div>
		</v-sheet>

		<common-dialog ref="helpDialog" :max-width="800" color="primary">
			<template #header>
				Welcome to the Eligibility and Priorities calculator
			</template>
			<template #body>
				<v-sheet class="pt-5">
					<p class="text-body-1">
						To get started enter a customer reference and select how many customers there are.
					</p>
					<div class="d-flex">
						<v-speed-dial class="mt-1">
							<template #activator>
								<v-btn color="blue darken-2" dark fab small>
									<v-icon> mdi-tools </v-icon>
								</v-btn>
							</template>
						</v-speed-dial>

						<p class="text-body-1 ml-4">
							There is a tool box in the right hand corner of the screen which contains tools to help navigate your journey. Click on the symbol to explore these useful tools.
						</p>
					</div>
					<p class="text-body-1 mb-0">
						Once you have completed these click next steps to start your navigation to Later Life Lending.
					</p>
				</v-sheet>
			</template>
			<template #footer>
				<v-card-actions class="pa-4">
					<v-checkbox v-model="hideHelpDialog" hide-details label="Do not show this again" class="ma-0 pa-0" @change="helpDialogChanged" />
				</v-card-actions>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { $localStorage } from '@/utils/storage';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/components/common/dialog';

	export default {
		components: {
			ValidationObserver,
			ValidationProvider,
			CommonDialog
		},

		props: {
			config: { type: Object, required: true },
			customerReference: { type: String, default: '' },
			numberOfClients: { type: Number, default: 0 },
			fcaNumber: { type: String, default: '' }
		},

		data() {
			return {
				hideHelpDialog: false
			};
		},

		watch: {
			numberOfClients: {
				handler() {
					if (this.config.step < 3) {
						this.config.step = 3;
						this.submitCustomerCount();
					}
				}
			}
		},

		mounted() {
			this.$vuetify.goTo(0, { duration: 0 });

			if (!$localStorage.get('hideStep1HelpDialog')) this.$refs.helpDialog.open();

			if (this.config.messages.length === 0)
				setTimeout(() => {
					this.config.messages.push({
						text: 'First things first, lets add a reference for your customer - most people tend to use a surname or a numerical reference.',
						posted: 'Just now',
						isBot: true
					});
					this.config.messages.push({ id: 1, isBot: false });
				}, 250);
		},

		methods: {
			/**
			 * @name submitReference
			 * @description Submits the answer to the bot
			 */
			async submitReference() {
				if (this.config.step < 2) {
					let success = await this.$refs.referenceObserver[0].validate();
					if (success) this.config.messages.push({ text: this.customerReference, isBot: false });
					else return;
				}

				this.config.step++;

				this.config.messages = this.config.messages.filter((msg) => msg.id !== 1);
				this.config.messages.push({
					text: `Thanks, we'll use "${this.customerReference}" as your reference. Next, can you enter your FCA Firm Reference Number?`,
					posted: 'Just now',
					isBot: true
				});
				this.config.messages.push({ id: 2, isBot: false });
			},

			/**
			 * @name submitCustomerCount
			 * @description Submits the answer to the bot
			 */
			async submitCustomerCount() {
				this.config.messages.push({
					text: "Thanks for your answers. When you're ready, go to next step to load up the calculator. Remember to save a PDF for your records as this session will not be saved.",
					posted: 'Just now',
					isBot: true
				});
			},

			/**
			 * @name submitFcaNumber
			 * @description Submits the answer to the bot
			 */
			async submitFcaNumber() {
				let success = await this.$refs.fcaNumberObserver[0].validate();
				if (success) this.config.messages.push({ text: this.fcaNumber, isBot: false });
				else return;

				this.config.step++;
				this.config.messages = this.config.messages.filter((msg) => msg.id !== 2);

				this.config.messages.push({
					text: `Thanks for providing your Firm Reference Number. Lastly, can you tell me how many customers there are?`,
					posted: 'Just now',
					isBot: true
				});
				this.config.messages.push({ text: ``, isBot: false, shouldAnswerWithButton: true });
			},

			/**
			 * @name nextStep
			 * @description Emits the next step event
			 */
			nextStep() {
				this.$emit('next-step', {
					numberOfClients: this.numberOfClients,
					customerReference: this.customerReference,
					fcaNumber: this.fcaNumber
				});
			},

			/**
			 * @name helpDialogChanged
			 * @description Updates the hideHelpDialog state
			 */
			helpDialogChanged() {
				$localStorage.set('hideStep1HelpDialog', this.hideHelpDialog);
			}
		}
	};
</script>

<style scoped lang="scss">
	.v-timeline::before,
	.v-timeline::after {
		height: 0;
	}

	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card::after,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card::after {
		left: -10px;
		right: initial;
		transform: rotate(0deg);
	}

	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card::after,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card::after {
		right: -10px;
		left: initial;
		transform: rotate(180deg);
	}

	::v-deep {
		.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before),
		.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after {
			.v-timeline-item__body {
				justify-content: flex-end;
			}
		}

		.v-timeline-item__body {
			display: flex;
			max-width: calc(70% - 48px) !important;
			align-items: center;
		}
		.v-timeline-item__divider {
			display: none;
		}
	}
</style>
