<template>
	<v-container style="max-width: 1200px">
		<v-img :max-width="site?.logo?.maxWidth" :src="site?.logo?.path | urlize('brand')" class="mx-auto mb-10" />
		<p class="text-h5 font-weight-bold mx-10 px-10 mb-10 text-center">
			Welcome to the Later Life Lending Navigator, where you can leverage the tools below to guide your customers on their advisory journey.
		</p>
		<v-sheet class="d-flex ma-n3">
			<v-card rounded class="pa-8 flex-shrink-1 flex-grow-1 ma-3" elevation="5">
				<div class="text-center">
					<v-icon color="primary" size="80" class="mb-2">
						mdi-account-check-outline
					</v-icon>
					<h2 class="mb-4 primary--text text-center">
						Eligibility and Priorities
					</h2>
					<p class="mb-4 px-10">
						Use our quick calculator to determine which options are available for your customer(s)
					</p>
					<v-btn class="my-2" large color="primary" @click="getStarted('tool-1')">
						Let's get started
					</v-btn>
				</div>
			</v-card>

			<v-card rounded class="pa-8 flex-shrink-1 flex-grow-1 ma-3" elevation="5">
				<div class="text-center">
					<v-icon color="primary" size="80" class="mb-2">
						mdi-list-box-outline
					</v-icon>
					<h2 class="mb-4 primary--text text-center">
						Product Comparison
					</h2>
					<p class="mb-4 px-10">
						Use our quick calculator to compare products that maybe available for your customer(s)
					</p>
					<v-btn class="my-2" large color="primary" @click="getStarted('tool-2')">
						Let's get started
					</v-btn>
				</div>
			</v-card>
		</v-sheet>
	</v-container>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		data() {
			return {
				tool: null
			};
		},

		computed: {
			...mapState('App', ['site'])
		},

		methods: {
			getStarted(val) {
				this.$router.push({ name: val });
			}
		}
	};
</script>

<style></style>
