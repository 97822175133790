import axios from 'axios';

/**
 * @namespace Utils
 * @method $http
 * @exports Utils/$http
 * @description Util giving http access using axios
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

const config = {
	baseURL: process.env.VUE_APP_API_URL,
	headers: { Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' }
};

const $http = axios.create(config);

export default $http;
