import { $http } from '@/utils';

/**
 * @namespace Service
 * @class File
 * @exports Service/File
 * @description API File Service
 * @date 2023/01/19
 * @license no license
 * @copywrite Answers In Retirement Limited
 */
export default {
	/**
	 * @name getSiteConfig
	 * @description Get site config
	 * @returns {Object} Promise a http response
	 */
	getSiteConfig: () => $http.get(`cms/site`),

	/**
	 * @name updateJson
	 * @description Get site config
	 * @returns {Object} Promise a http response
	 */
	updateJson: (payload) => $http.post(`file/json`, payload),

	/**
	 * @name getJson
	 * @description Get Json
	 * @returns {Object} Promise a http response
	 */
	getJson: (params) => $http.get(`file`, { params }).then(({ data }) => $http.get(data.preSignedUrl)),

	/**
	 * @name eligibilityAndPrioritiesPdf
	 * @description Print eligibility & priorities tool pdf
	 * @returns {Object} Promise a http response
	 */
	eligibilityAndPrioritiesPdf: (payload) => $http.post(`eligibility-and-priorities/pdf`, payload),

	/**
	 * @name productComparisonPdf
	 * @description Print product comparison tool pdf
	 * @returns {Object} Promise a http response
	 */
	productComparisonPdf: (payload) => $http.post(`product-comparison/pdf`, payload)
};
