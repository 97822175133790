<template>
	<div>
		<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card tile>
				<v-toolbar flat dark color="pink" class="pl-3 pr-3 flex-grow-0">
					<v-row>
						<v-col cols="12" md="2" class="d-flex pa-0">
							<v-toolbar-title class="align-self-center">
								Help
							</v-toolbar-title>
						</v-col>
						<v-spacer />
						<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
							<v-btn icon dark class="align-self-center" @click="dialog = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-toolbar>
				<v-container fluid class="pa-6 ma-0 mx-auto" style="max-width: 936px">
					<div>
						<div class="">
							<table class="mt-6">
								<tr class="c2">
									<td class="c6 header" colspan="3" rowspan="1">
										<p class="c0">
											<span class="c4 font-weight-bold">Customer(s)</span>
										</p>
									</td>
								</tr>
								<tr class="c2">
									<td colspan="1" rowspan="1" width="30%">
										<p class="c0">
											<span class="c1 font-weight-bold">Question</span>
										</p>
									</td>
									<td colspan="1" rowspan="1" width="35%">
										<p class="c0">
											<span class="c1 font-weight-bold">Guidance</span>
										</p>
									</td>
									<td colspan="1" rowspan="1" width="35%">
										<p class="c0">
											<span class="c1 font-weight-bold">Examples</span>
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c22" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Age</span>
										</p>
									</td>
									<td class="c26" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">This is your customer(s) current age.</span>
										</p>
									</td>
									<td class="c31" colspan="1" rowspan="1">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c22" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Has the customer already retired?</span>
										</p>
									</td>
									<td class="c26" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Please select if your customer(s) have already retired.</span>
										</p>
									</td>
									<td class="c31" colspan="1" rowspan="1">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c22" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Planned Retirement Age</span>
										</p>
									</td>
									<td class="c35" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">This is the age your customer(s) plan to retire at.</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
									<td class="c38" colspan="1" rowspan="1">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Monthly Income</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
									<td class="c20" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">This is your customer(s) monthly income post tax (net). If they are self employed, enter their most recent years earnings. This can
												include any savings, non-earned income such as DLA/PIP, rental profit etc.<br />Please refer to the guide in the Help section for a
												list of benefits.</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
									<td class="c42" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Net Earned Income, Investment Income, State Pension, Company Pension, Private Pension, Pension Credit, DLA,) Working Tax Credits,
												Child Tax Credits, Social Fund,</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0">
											<span class="c1">Armed Forces Independence Payment, Attendance Allowance, Bereavement Allowance, Bereavement Payment, Bereavement Support Payment,
												Blind Person's Allowance, Budgeting Loan/Budgeting Advance, Carer's Benefit/Carer's Allowance, Child Benefit, Cold
												Weather Payment, Council Tax Reduction, Disability Living Allowance, Disabled Facilities Grants, Discretionary Assistance Funds,
												Discretionary Housing Payment, Employment and Support Allowance, Free School Meals, Funeral Payments, Guardians
												AllowanceHealthy Start Food Vouchers, Housing Benefit, Incapacity Benefit, Income
												Support, Industrial Injuries Disablement benefit, Job Seekers Allowance, Local Housing Allowance, Local Welfare Assistance,
												Maternity Allowance, Pension Credit, Personal Independence Payment (PIP), Reduced Earnings Allowance, Severe Disablement Premium,
												Statutory Adoption Pay, Statutory maternity Pay, Statutory Paternity Pay, Statutory Sick Pay, Support for Mortgage Interest (SMI),
												Sure Start Maternity Grant, Tax Credits, Universal Credit, War Pensions scheme, Warm Homes Discount, Widows Parents Allowance,
												Winter Fuel Payment</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c40">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Estimated monthly retirement income</span>
										</p>
									</td>
									<td class="c20" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">This is your customer(s) monthly pension income post tax they expect to receive post their planned retirement age (net). This should
												include both state and private pensions and can include any savings, non-earned income such as DLA/PIP, rental profit etc.
												<br />Please refer to the guide in the Help section for a list of benefits.</span>
										</p>
									</td>
									<td class="c42" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Investment Income, State Pension, Company Pension, Private Pension, Pension Credit, DLA,) Working Tax Credits, Child Tax Credits,
												Social Fund,</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c8">
											<span class="c1">Armed Forces Independence Payment, Attendance Allowance, Bereavement Allowance, Bereavement Payment, Bereavement Support Payment,
												Blind Person's Allowance, Budgeting Loan/Budgeting Advance, Carer's Benefit/Carer's Allowance, Child Benefit, Cold
												Weather Payment, Council Tax Reduction, Disability Living Allowance, Disabled Facilities Grants, Discretionary Assistance Funds,
												Discretionary Housing Payment, Employment and Support Allowance, Free School Meals, Funeral Payments, Guardians
												AllowanceHealthy Start Food Vouchers, Housing Benefit, Incapacity Benefit, Income
												Support, Industrial Injuries Disablement benefit, Job Seekers Allowance, Local Housing Allowance, Local Welfare Assistance,
												Maternity Allowance, Pension Credit, Personal Independence Payment (PIP), Reduced Earnings Allowance, Severe Disablement Premium,
												Statutory Adoption Pay, Statutory maternity Pay, Statutory Paternity Pay, Statutory Sick Pay, Support for Mortgage Interest (SMI),
												Sure Start Maternity Grant, Tax Credits, Universal Credit, War Pensions scheme, Warm Homes Discount, Widows Parents Allowance,
												Winter Fuel Payment</span>
										</p>
										<p class="c8 c7">
											<span class="c1" />
										</p>
										<p class="c8 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
							</table>
							<table class="mt-6">
								<tr class="c2">
									<td class="c18 header" colspan="3" rowspan="1">
										<p class="c0">
											<span class="font-weight-bold">Mortgage Details</span>
										</p>
									</td>
								</tr>
								<tr class="c2">
									<td colspan="1" rowspan="1" width="30%">
										<p class="c0">
											<span class="c1 font-weight-bold">Question</span>
										</p>
									</td>
									<td colspan="1" rowspan="1" width="35%">
										<p class="c0">
											<span class="c1 font-weight-bold">Guidance</span>
										</p>
									</td>
									<td colspan="1" rowspan="1" width="35%">
										<p class="c0">
											<span class="c1 font-weight-bold">Examples</span>
										</p>
									</td>
								</tr>
								<tr class="c28">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Property Value</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c15">This is the estimated value of your customer(s) property.</span>
										</p>
									</td>
									<td class="c32" colspan="2" rowspan="1">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c28">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Requested borrowing amount</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c15">This is the total amount your customer(s) wants to borrow (your customer(s) is going to borrow this amount of money straight
												away).</span>
										</p>
									</td>
									<td class="c32" colspan="2" rowspan="1">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
							</table>
							<table class="mt-6">
								<tr class="c2">
									<td class="c18 header" colspan="3" rowspan="1">
										<p class="c0">
											<span class="c4 font-weight-bold">Expenditure Details</span>
										</p>
									</td>
								</tr>
								<tr class="c2">
									<td colspan="1" rowspan="1" width="30%">
										<p class="c0">
											<span class="c1 font-weight-bold">Question</span>
										</p>
									</td>
									<td colspan="1" rowspan="1" width="35%">
										<p class="c0">
											<span class="c1 font-weight-bold">Guidance</span>
										</p>
									</td>
									<td colspan="1" rowspan="1" width="35%">
										<p class="c0">
											<span class="c1 font-weight-bold">Examples</span>
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Monthly committed borrowing</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">This is the monthly amount your customer(s) pay in committed borrowing, such as mortgage repayments, monthly car loan/PCP/HP,
												minimum credit card payment, mail order etc.<br />Please refer to the guide in the Help section for examples of monthly committed
												borrowing.</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
									<td class="c44" colspan="2" rowspan="1">
										<p class="c0">
											<span class="c1">Mortgage, Mortgage (2nd property), Lifetime Mortgage, Credit Cards (On completion), Credit Cards (Future), Loans (On completion),
												Loans (Future), Overdrafts, Car Finance PCP, Other</span>
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Regular monthly financial commitments</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c15">This is your customer(s) monthly amount they have committed to pay in financial commitments, such as school fees, child care costs,
												ground rent/service charges etc. <br />Please refer to the guide in the Help section for examples of </span><span class="c15">Regular monthly financial commitments.</span>
										</p>
									</td>
									<td class="c21" colspan="2" rowspan="1">
										<p class="c0">
											<span class="c15">Service Charge, Ground Rent</span>
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Monthly utility costs</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c15">Your customer(s) total monthly cost for utility bills, such as gas, electric, internet etc. <br />Please refer to the guide in the
												Help section for a list of monthly utility costs examples.</span>
										</p>
									</td>
									<td class="c21" colspan="2" rowspan="1">
										<p class="c0">
											<span class="c15">Utility Bills (Water, Gas, Electric)<br />Telephone, Internet, Satellite, TV licence<br />Council Tax</span>
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Monthly living costs</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c15">Your customer(s) total monthly living costs, such as food, toiletries, fuel etc.<br />Please refer to the guide in the Help section
												for examples of monthly living costs.</span>
										</p>
									</td>
									<td class="c21" colspan="2" rowspan="1">
										<p class="c0">
											<span class="c1">General Household expenditure (Food, Toiletries, Clothes)<br />Fuel/Essential Travel</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Other Costs</span>
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c15">Any other costs for customer(s) that are not covered in the categories above, such as holidays, entertainment, insurance policies
												etc.<br />Please refer to the guide in the Help section for examples of other costs.</span>
										</p>
									</td>
									<td class="c21" colspan="2" rowspan="1">
										<p class="c0">
											<span class="c15">Social Spending (Holidays, Leisure, Entertainment)<br />Insurance Policies<br />Other</span>
										</p>
									</td>
								</tr>
								<tr class="c10">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Total Costs</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c15">This field automatically calculates the expenditure fields, however you can use this field on its own. <br />If you need help with
												this section please use the supporting material links in the Help button on top right corner.</span>
										</p>
									</td>
									<td class="c21" colspan="2" rowspan="1">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
							</table>
							<table class="mt-6">
								<tr class="c34">
									<td class="c6 header" colspan="3" rowspan="1">
										<p class="c0">
											<span class="c11 font-weight-bold">Affordability</span>
										</p>
									</td>
								</tr>
								<tr class="c3">
									<td class="c24" colspan="1" rowspan="1" width="30%">
										<p class="c0">
											<span class="c1">Estimated monthly disposable income (pre retirement)</span>
										</p>
									</td>
									<td class="c29" colspan="1" rowspan="2" width="35%">
										<p class="c0">
											<span class="c15">This is the amount that we estimate your customer(s) could afford to pay each month towards their mortgage.<br />This is calculated
												at 80% of their total affordability</span>
										</p>
									</td>
									<td class="c32" colspan="1" rowspan="2" width="35%">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c3">
									<td class="c24" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Estimated monthly disposable income (post retirement)</span>
										</p>
									</td>
								</tr>
								<tr class="c41">
									<td class="c22" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Customers estimated monthly disposable income (pre retirement)</span>
										</p>
									</td>
									<td class="c17" colspan="1" rowspan="2">
										<p class="c0">
											<span class="c15">Enter the amount your customer(s) is/are willing to use for their monthly disposable income. You can use this field to increase the
												20% haircut on the above estimated monthly disposable income fields which they are willing to commit.</span>
										</p>
									</td>
									<td class="c25" colspan="1" rowspan="2">
										<p class="c0 c7">
											<span class="c1" />
										</p>
									</td>
								</tr>
								<tr class="c27">
									<td class="c22" colspan="1" rowspan="1">
										<p class="c0">
											<span class="c1">Customers estimated monthly disposable income (post retirement)</span>
										</p>
									</td>
								</tr>
							</table>
						</div>
					</div>
				</v-container>
			</v-card>
			<!-- <common-dialog ref="eligibiltyDialog" color="primary" fullscreen> -->
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: 'app-common-help-dialog',

		data() {
			return {
				dialog: false
			};
		},

		methods: {
			open() {
				this.dialog = true;
			}
		}
	};
</script>

<style type="text/css" lang="scss" scoped>
	@import url(https://themes.googleusercontent.com/fonts/css?kit=fpjTOVmNbO4Lz34iLyptLUXza5VhXqVC6o75Eld_V98);

	ol {
		margin: 0;
		padding: 0;
	}

	table {
		border-spacing: 0;
		border-collapse: collapse;
		margin-right: auto;

		td, th {
			padding: 8px !important;
			vertical-align: top !important;
			text-align: left !important;
			font-size: 1rem !important;
			line-height: 1.6rem !important;
			border: 1pt solid #000000 !important;

			&.header {
				background-color: #e7e6e6;
			}

			p {
				text-align: left !important;
				font-size: .9rem !important;
				line-height: 1.3rem !important;
				margin-bottom: 0 !important;
			}
		}
	}

	.c33 {
		font-style: italic;
	}
</style>
