<!--
	@name app-common-privacy
	@description Privacy Policy
	@date 2020/06/03
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :component="$options.name">
		<template #header>
			Terms &amp; Conditions
		</template>
		<template #body>
			<div class="pt-6">
				<ol class="c1 lst-kix_list_4-0 start" start="1">
					<li id="h.gjdgxs" class="c2 c7 li-bullet-0">
						<span class="c5">About our terms</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.30j0zll" class="c2 li-bullet-0">
						<span class="c4">These terms and conditions of use (</span><span class="c5">Terms</span><span class="c4">) explain how you may use this website and any of its content (</span><span class="c5">Site</span><span class="c4">). These Terms apply between Answers in Retirement Limited (</span><span class="c5">we</span><span class="c4">, </span><span class="c5">us</span><span class="c4">&nbsp;or </span><span class="c5">our</span><span class="c4">) and you, the person accessing or using the Site (</span><span class="c5">you</span><span class="c4">&nbsp;or </span><span class="c5">your</span><span class="c4">).</span>
					</li>
					<li id="h.1fob9te" class="c2 li-bullet-0">
						<span class="c4">You should read these Terms carefully before using the Site. By using the Site or otherwise indicating your consent, you agree to be bound by these
							Terms. If you do not agree with any of these Terms, you should stop using the Site immediately.</span>
					</li>
					<li id="h.3znysh7" class="c2 li-bullet-0">
						<span class="c4">The Site is provided by us to you free of charge for information</span><span class="c5">&nbsp;</span><span class="c4">purposes only. </span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="2">
					<li id="h.2et92p0" class="c2 c7 li-bullet-0">
						<span class="c5">About us</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.tyjcwt" class="c2 li-bullet-0">
						<span class="c4">We are Answers in Retirement Limited (trading as Air), a company registered in England and Wales under company registration number 09991416. Our
							registered office is at PO Box GL4 4QT, Southgate House, Southgate Street, Gloucester, Gloucestershire, United Kingdom, GL1 1UD. &nbsp;We are not
							regulated by the Financial Conduct Authority.
						</span>
					</li>
					<li class="c2 li-bullet-0">
						<span class="c4">All trademarks and copyright on this page are owned by Answers in Retirement Limited. &nbsp;</span>
					</li>
					<li id="h.3dy6vkm" class="c2 li-bullet-0">
						<span class="c4">If you have any questions about the Site, please contact us by:</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-2 start" start="1">
					<li id="h.1t3h5sf" class="c0 li-bullet-0">
						<span class="c4">sending an email to lll.navigator@airlaterlife.co.uk, or</span>
					</li>
					<li id="h.4d34og8" class="c0 li-bullet-0">
						<span class="c4">calling us on 0800 2945097 our telephone lines are open Monday to Saturday: 9 am to 5pm</span><span class="c10">.</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="3">
					<li id="h.2s8eyo1" class="c2 c7 li-bullet-0">
						<span class="c5">Using the site</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.17dp8vu" class="c2 li-bullet-0">
						<span class="c4">The Site is for intermediary use only as part of research into later life lending products that may potentially be of interest to intermediary&rsquo;s
							customers. &nbsp;</span>
					</li>
					<li id="h.3rdcrjn" class="c2 li-bullet-0">
						<span class="c4">You agree that you are solely responsible for all costs and expenses you may incur in relation to your use of the Site.</span>
					</li>
					<li id="h.26in1rg" class="c2 li-bullet-0">
						<span class="c4">We try to make the Site as accessible as possible. If you have any difficulties using the Site, please contact us using the contact details at the top
							of this page.</span>
					</li>
					<li id="h.lnxbz9" class="c2 li-bullet-0">
						<span class="c4">As a condition of your use of the Site, you agree not to:</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-2 start" start="1">
					<li id="h.35nkun2" class="c0 li-bullet-0">
						<span class="c4">misuse or attack our Site by knowingly introducing viruses, trojans, worms, logic bombs or any other material which is malicious or technologically
							harmful (such as by way of a denial-of-service attack), or</span>
					</li>
					<li id="h.1ksv4uv" class="c0 li-bullet-0">
						<span class="c4">attempt to gain unauthorised access to our Site, the server on which our Site is stored or any server, computer or database connected to our
							Site.</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1" start="5">
					<li id="h.44sinio" class="c2 li-bullet-0">
						<span class="c4">We may prevent or suspend your access to the Site if you do not comply with these Terms or any applicable law.</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="4">
					<li id="h.2jxsxqh" class="c2 c7 li-bullet-0">
						<span class="c5">Privacy and personal information</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li class="c2 li-bullet-0">
						<span class="c4">Privacy and personal information are important to us. As your customer&rsquo;s intermediary, it is your responsibility to ensure that, if you input
							personal data into the tool, you have your customer&rsquo;s consent and/or correct permissions to share their personal data before submission.</span>
					</li>
					<li class="c2 li-bullet-0">
						<span class="c4">Any personal information that you provide to us will be dealt with in line with our applicable privacy notice available </span><a class="c11" @click="openPrivacy">here,</a><span class="c4">&nbsp;which explains what personal information we collect, what we do with it, and how to contact us in the event you have a query or complaint.</span>
					</li>
					<li class="c2 li-bullet-0">
						<span class="c4">You are responsible to ensure that any output you generate from the system is for your use only, not to be shared or distributed and securely disposed
							of once it is no longer required to protect any personal data contained within it. &nbsp;</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="5">
					<li id="h.z337ya" class="c2 c7 li-bullet-0">
						<span class="c5">Ownership, use and intellectual property rights</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.3j2qqm3" class="c2 li-bullet-0">
						<span class="c4">The intellectual property rights in the Site and in any text, images, video, audio or other multimedia content, software or other information or
							material submitted to or accessible from the Site (</span><span class="c5">Content</span><span class="c4">) are owned by us and our licensors.</span>
					</li>
					<li id="h.1y810tw" class="c2 li-bullet-0">
						<span class="c4">We and our licensors reserve all our intellectual property rights (including, but not limited to, all copyright, trade marks, domain names, design
							rights, database rights, patents and all other intellectual property rights of any kind) whether registered or unregistered anywhere in the world. This
							means, for example, that we remain owners of them and are free to use them as we see fit.</span>
					</li>
					<li id="h.4i7ojhp" class="c2 li-bullet-0">
						<span class="c4">Nothing in these Terms grants you any legal rights in the Site or the Content other than as necessary for you to access it. You agree not to adjust,
							try to circumvent or delete any notices contained on the Site or the Content (including any intellectual property notices) and in particular, in any
							digital rights or other security technology embedded or contained within the Site or the Content.</span>
					</li>
					<li id="h.2xcytpi" class="c2 li-bullet-0">
						<span class="c4">The Air trademarks are our property. Use by you of any trade marks on the Site or in the Content is strictly prohibited. </span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="6">
					<li id="h.1ci93xb" class="c2 c7 li-bullet-0">
						<span class="c5">Accuracy of information and availability of the site</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.3whwml4" class="c2 li-bullet-0">
						<span class="c4">We try to make sure that the Site is accurate, up-to-date and free from bugs, but we cannot promise that it will be. Furthermore, we cannot promise
							that the Site will be fit or suitable for any purpose. Any reliance that you may place on the information on the Site is at your own risk.</span>
					</li>
					<li id="h.2bn6wsx" class="c2 li-bullet-0">
						<span class="c4">We may suspend or terminate access or operation of the Site at any time as we see fit.</span>
					</li>
					<li class="c2 li-bullet-0">
						<span class="c4">Any Content is provided for your general information purposes only and to inform you about products that may potentially be of interest to your
							customer/s. It does not constitute technical, financial or legal advice or any other type of advice and should not be relied on as such. &nbsp;</span>
					</li>
					<li id="h.qsh70q" class="c2 li-bullet-0">
						<span class="c4">Prices are provided on an indicative basis only. &nbsp;We are not responsible if/when products cease to be available or if pricing changes occur.
							Indicative pricing can change rapidly and therefore the information provided is supplied on an informational basis only. &nbsp;You should always use
							your own independent judgment when using our Site and its Content.</span>
					</li>
					<li id="h.3as4poj" class="c2 li-bullet-0">
						<span class="c4">While we try to make sure that the Site is available for your use, we do not promise that the Site will be available at all times or that your use of
							the Site will be uninterrupted, and uptime is not guaranteed. &nbsp; &nbsp;</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="7">
					<li id="h.1pxezwc" class="c2 c7 li-bullet-0">
						<span class="c5">Hyperlinks and third party sites</span>
					</li>
				</ol>
				<p class="c6">
					<span class="c4">The Site may contain hyperlinks or references to third party advertising and websites other than the Site. Any such hyperlinks or references are provided
						for your convenience only. We have no control over third party advertising or websites and accept no legal responsibility for any content, material or
						information contained in them. The display of any hyperlink and reference to any third party advertising or website does not mean that we endorse that third
						party&rsquo;s website, products or services.
					</span>
				</p>
				<ol class="c1 lst-kix_list_4-0" start="8">
					<li id="h.49x2ik5" class="c2 c7 li-bullet-0">
						<span class="c5">Our responsibility to you</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.2p2csry" class="c2 li-bullet-0">
						<span class="c4">The Site and the tool contained within it are provided on an &ldquo;as is&rdquo; and informational basis only. &nbsp;All liability in contract,
							negligence or otherwise that can be excluded at law is hereby excluded. &nbsp;</span>
					</li>
					<li id="h.147n2zr" class="c2 li-bullet-0">
						<span class="c4">Nothing in these terms seeks to exclude or limit our liability for any death or personal injury caused by our negligence, liability for fraud or
							fraudulent misrepresentation, or any other liability that the law does not allow us to exclude or limit.</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="9">
					<li id="h.3o7alnk" class="c2 c7 li-bullet-0">
						<span class="c5">No third party rights</span>
					</li>
				</ol>
				<p class="c6">
					<span class="c4">No one other than us or you has any right to enforce any of these Terms.</span>
				</p>
				<ol class="c1 lst-kix_list_4-0" start="10">
					<li id="h.23ckvvd" class="c2 c7 li-bullet-0">
						<span class="c5">Variation</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.ihv636" class="c2 li-bullet-0">
						<span class="c4">No changes to these Terms are valid or have any effect unless agreed by us in writing.</span>
					</li>
					<li id="h.32hioqz" class="c2 li-bullet-0">
						<span class="c4">We reserve the right to vary these Terms from time to time. Our updated Terms will be displayed on the Site and by continuing to use and access the
							Site following such changes, you agree to be bound by any variation made by us. It is your responsibility to check these Terms from time to time to
							verify such variations.</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-0" start="11">
					<li id="h.1hmsyys" class="c2 c7 li-bullet-0">
						<span class="c5">Complaints</span>
					</li>
				</ol>
				<ol class="c1 lst-kix_list_4-1 start" start="1">
					<li id="h.41mghml" class="c2 li-bullet-0">
						<span class="c4">We will try to resolve any disputes with quickly and efficiently. If you are unhappy, please contact us as soon as possible using the contact details
							set out at the top of this page.</span>
					</li>
					<li id="h.2grqrue" class="c2 li-bullet-0">
						<span class="c4">The laws of England and Wales apply to these Terms, and any disputes will be subject to the non-exclusive jurisdiction of the courts of England and
							Wales.</span>
					</li>
				</ol>
				<div>
					<p class="c9">
						<span class="c8" />
					</p>
				</div>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/components/common/dialog';

	export default {
		name: 'app-common-privacy',

		components: { CommonDialog },

		data: () => ({}),

		methods: {
			/**
			 * @name open
			 * @description Open dialog and set properties
			 */
			open() {
				this.$refs.dialog.open();
			},

			openPrivacy() {
				this.$refs.dialog.close();
				this.$emit('open-privacy');
			}
		}
	};
</script>

<style type="text/css" scoped>
	ol.lst-kix_list_7-0 {
		list-style-type: none;
	}

	.lst-kix_list_14-1 > li:before {
		content: '(' counter(lst-ctn-kix_list_14-1, lower-latin) ') ';
	}

	.lst-kix_list_14-3 > li:before {
		content: '(' counter(lst-ctn-kix_list_14-3, upper-latin) ') ';
	}

	.lst-kix_list_2-1 > li {
		counter-increment: lst-ctn-kix_list_2-1;
	}

	ol.lst-kix_list_15-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_15-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_15-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-0.start {
		counter-reset: lst-ctn-kix_list_9-0 0;
	}

	.lst-kix_list_14-0 > li:before {
		content: ' ';
	}

	.lst-kix_list_14-4 > li:before {
		content: '(' counter(lst-ctn-kix_list_14-4, lower-latin) ') ';
	}

	ol.lst-kix_list_15-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_15-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_15-4 {
		list-style-type: none;
	}

	.lst-kix_list_6-1 > li {
		counter-increment: lst-ctn-kix_list_6-1;
	}

	.lst-kix_list_14-5 > li:before {
		content: '(' counter(lst-ctn-kix_list_14-5, upper-latin) ') ';
	}

	.lst-kix_list_14-7 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_15-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_15-0 {
		list-style-type: none;
	}

	.lst-kix_list_14-6 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_15-1 {
		list-style-type: none;
	}

	.lst-kix_list_13-0 > li {
		counter-increment: lst-ctn-kix_list_13-0;
	}

	ol.lst-kix_list_7-4.start {
		counter-reset: lst-ctn-kix_list_7-4 0;
	}

	.lst-kix_list_5-0 > li {
		counter-increment: lst-ctn-kix_list_5-0;
	}

	.lst-kix_list_9-0 > li {
		counter-increment: lst-ctn-kix_list_9-0;
	}

	ol.lst-kix_list_2-3.start {
		counter-reset: lst-ctn-kix_list_2-3 0;
	}

	ol.lst-kix_list_7-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_7-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_7-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_7-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_7-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_7-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-8.start {
		counter-reset: lst-ctn-kix_list_11-8 0;
	}

	ol.lst-kix_list_7-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_7-4 {
		list-style-type: none;
	}

	.lst-kix_list_14-2 > li:before {
		content: '(' counter(lst-ctn-kix_list_14-2, lower-roman) ') ';
	}

	ol.lst-kix_list_5-3.start {
		counter-reset: lst-ctn-kix_list_5-3 0;
	}

	ol.lst-kix_list_12-0.start {
		counter-reset: lst-ctn-kix_list_12-0 0;
	}

	.lst-kix_list_4-3 > li {
		counter-increment: lst-ctn-kix_list_4-3;
	}

	ol.lst-kix_list_3-7.start {
		counter-reset: lst-ctn-kix_list_3-7 0;
	}

	.lst-kix_list_14-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_8-8.start {
		counter-reset: lst-ctn-kix_list_8-8 0;
	}

	.lst-kix_list_3-2 > li {
		counter-increment: lst-ctn-kix_list_3-2;
	}

	ol.lst-kix_list_15-5.start {
		counter-reset: lst-ctn-kix_list_15-5 0;
	}

	ol.lst-kix_list_10-4.start {
		counter-reset: lst-ctn-kix_list_10-4 0;
	}

	.lst-kix_list_7-2 > li {
		counter-increment: lst-ctn-kix_list_7-2;
	}

	.lst-kix_list_5-0 > li:before {
		content: 'Schedule ' counter(lst-ctn-kix_list_5-0, decimal) ' ';
	}

	ol.lst-kix_list_6-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_6-1 {
		list-style-type: none;
	}

	.lst-kix_list_14-8 > li {
		counter-increment: lst-ctn-kix_list_14-8;
	}

	.lst-kix_list_5-4 > li {
		counter-increment: lst-ctn-kix_list_5-4;
	}

	.lst-kix_list_1-4 > li {
		counter-increment: lst-ctn-kix_list_1-4;
	}

	ol.lst-kix_list_1-6.start {
		counter-reset: lst-ctn-kix_list_1-6 0;
	}

	.lst-kix_list_5-3 > li:before {
		content: '' counter(lst-ctn-kix_list_5-2, decimal) '.' counter(lst-ctn-kix_list_5-3, decimal) ' ';
	}

	ol.lst-kix_list_9-5.start {
		counter-reset: lst-ctn-kix_list_9-5 0;
	}

	.lst-kix_list_5-2 > li:before {
		content: '' counter(lst-ctn-kix_list_5-2, decimal) ' ';
	}

	.lst-kix_list_8-3 > li {
		counter-increment: lst-ctn-kix_list_8-3;
	}

	.lst-kix_list_5-1 > li:before {
		content: 'Part ' counter(lst-ctn-kix_list_5-1, upper-latin) ' ';
	}

	.lst-kix_list_5-7 > li:before {
		content: '(' counter(lst-ctn-kix_list_5-7, upper-latin) ') ';
	}

	.lst-kix_list_5-6 > li:before {
		content: '(' counter(lst-ctn-kix_list_5-6, lower-roman) ') ';
	}

	.lst-kix_list_5-8 > li:before {
		content: '(' counter(lst-ctn-kix_list_5-8, upper-roman) ') ';
	}

	.lst-kix_list_9-4 > li {
		counter-increment: lst-ctn-kix_list_9-4;
	}

	ol.lst-kix_list_6-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_6-7 {
		list-style-type: none;
	}

	.lst-kix_list_5-4 > li:before {
		content: '' counter(lst-ctn-kix_list_5-2, decimal) '.' counter(lst-ctn-kix_list_5-3, decimal) '.' counter(lst-ctn-kix_list_5-4, decimal) ' ';
	}

	ol.lst-kix_list_6-8 {
		list-style-type: none;
	}

	.lst-kix_list_5-5 > li:before {
		content: '(' counter(lst-ctn-kix_list_5-5, lower-latin) ') ';
	}

	ol.lst-kix_list_6-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_6-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_6-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_6-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_14-1.start {
		counter-reset: lst-ctn-kix_list_14-1 0;
	}

	ol.lst-kix_list_12-5.start {
		counter-reset: lst-ctn-kix_list_12-5 0;
	}

	.lst-kix_list_6-1 > li:before {
		content: '(' counter(lst-ctn-kix_list_6-1, lower-roman) ') ';
	}

	.lst-kix_list_6-3 > li:before {
		content: '(' counter(lst-ctn-kix_list_6-3, upper-roman) ') ';
	}

	.lst-kix_list_6-5 > li {
		counter-increment: lst-ctn-kix_list_6-5;
	}

	.lst-kix_list_6-8 > li {
		counter-increment: lst-ctn-kix_list_6-8;
	}

	.lst-kix_list_6-0 > li:before {
		content: '(' counter(lst-ctn-kix_list_6-0, lower-latin) ') ';
	}

	.lst-kix_list_6-4 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_14-8.start {
		counter-reset: lst-ctn-kix_list_14-8 0;
	}

	.lst-kix_list_3-6 > li {
		counter-increment: lst-ctn-kix_list_3-6;
	}

	.lst-kix_list_6-2 > li:before {
		content: '(' counter(lst-ctn-kix_list_6-2, upper-latin) ') ';
	}

	ol.lst-kix_list_15-0.start {
		counter-reset: lst-ctn-kix_list_15-0 0;
	}

	.lst-kix_list_2-5 > li {
		counter-increment: lst-ctn-kix_list_2-5;
	}

	.lst-kix_list_2-8 > li {
		counter-increment: lst-ctn-kix_list_2-8;
	}

	ol.lst-kix_list_3-2.start {
		counter-reset: lst-ctn-kix_list_3-2 0;
	}

	.lst-kix_list_6-8 > li:before {
		content: ' ';
	}

	.lst-kix_list_6-5 > li:before {
		content: ' ';
	}

	.lst-kix_list_6-7 > li:before {
		content: ' ';
	}

	.lst-kix_list_6-6 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_10-6.start {
		counter-reset: lst-ctn-kix_list_10-6 0;
	}

	.lst-kix_list_7-4 > li:before {
		content: '(' counter(lst-ctn-kix_list_7-4, upper-roman) ') ';
	}

	.lst-kix_list_7-6 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_6-2.start {
		counter-reset: lst-ctn-kix_list_6-2 0;
	}

	.lst-kix_list_15-5 > li {
		counter-increment: lst-ctn-kix_list_15-5;
	}

	.lst-kix_list_7-2 > li:before {
		content: '(' counter(lst-ctn-kix_list_7-2, lower-roman) ') ';
	}

	.lst-kix_list_7-6 > li {
		counter-increment: lst-ctn-kix_list_7-6;
	}

	.lst-kix_list_8-6 > li {
		counter-increment: lst-ctn-kix_list_8-6;
	}

	.lst-kix_list_12-6 > li {
		counter-increment: lst-ctn-kix_list_12-6;
	}

	ol.lst-kix_list_4-6.start {
		counter-reset: lst-ctn-kix_list_4-6 0;
	}

	ol.lst-kix_list_9-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-8 {
		list-style-type: none;
	}

	.lst-kix_list_13-7 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_3-0.start {
		counter-reset: lst-ctn-kix_list_3-0 0;
	}

	ol.lst-kix_list_9-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-4 {
		list-style-type: none;
	}

	.lst-kix_list_5-7 > li {
		counter-increment: lst-ctn-kix_list_5-7;
	}

	ol.lst-kix_list_9-5 {
		list-style-type: none;
	}

	.lst-kix_list_7-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_9-6 {
		list-style-type: none;
	}

	.lst-kix_list_15-6 > li {
		counter-increment: lst-ctn-kix_list_15-6;
	}

	.lst-kix_list_4-7 > li {
		counter-increment: lst-ctn-kix_list_4-7;
	}

	ol.lst-kix_list_9-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-5.start {
		counter-reset: lst-ctn-kix_list_2-5 0;
	}

	.lst-kix_list_15-5 > li:before {
		content: '(' counter(lst-ctn-kix_list_15-5, upper-latin) ') ';
	}

	.lst-kix_list_9-8 > li {
		counter-increment: lst-ctn-kix_list_9-8;
	}

	.lst-kix_list_4-1 > li:before {
		content: '' counter(lst-ctn-kix_list_4-0, decimal) '.' counter(lst-ctn-kix_list_4-1, decimal) ' ';
	}

	.lst-kix_list_15-7 > li:before {
		content: ' ';
	}

	.lst-kix_list_4-3 > li:before {
		content: '(' counter(lst-ctn-kix_list_4-3, lower-latin) ') ';
	}

	.lst-kix_list_4-5 > li:before {
		content: '(' counter(lst-ctn-kix_list_4-5, upper-latin) ') ';
	}

	.lst-kix_list_1-8 > li {
		counter-increment: lst-ctn-kix_list_1-8;
	}

	.lst-kix_list_10-5 > li {
		counter-increment: lst-ctn-kix_list_10-5;
	}

	.lst-kix_list_15-1 > li:before {
		content: '' counter(lst-ctn-kix_list_15-0, decimal) '.' counter(lst-ctn-kix_list_15-1, decimal) ' ';
	}

	ol.lst-kix_list_1-4.start {
		counter-reset: lst-ctn-kix_list_1-4 0;
	}

	.lst-kix_list_3-5 > li {
		counter-increment: lst-ctn-kix_list_3-5;
	}

	.lst-kix_list_15-3 > li:before {
		content: '(' counter(lst-ctn-kix_list_15-3, lower-latin) ') ';
	}

	ol.lst-kix_list_4-4.start {
		counter-reset: lst-ctn-kix_list_4-4 0;
	}

	.lst-kix_list_6-4 > li {
		counter-increment: lst-ctn-kix_list_6-4;
	}

	ol.lst-kix_list_9-2.start {
		counter-reset: lst-ctn-kix_list_9-2 0;
	}

	.lst-kix_list_9-3 > li {
		counter-increment: lst-ctn-kix_list_9-3;
	}

	.lst-kix_list_11-2 > li {
		counter-increment: lst-ctn-kix_list_11-2;
	}

	ol.lst-kix_list_15-2.start {
		counter-reset: lst-ctn-kix_list_15-2 0;
	}

	ol.lst-kix_list_2-8.start {
		counter-reset: lst-ctn-kix_list_2-8 0;
	}

	ol.lst-kix_list_8-8 {
		list-style-type: none;
	}

	.lst-kix_list_12-3 > li:before {
		content: '(' counter(lst-ctn-kix_list_12-3, upper-latin) ') ';
	}

	ol.lst-kix_list_7-6.start {
		counter-reset: lst-ctn-kix_list_7-6 0;
	}

	ol.lst-kix_list_8-4 {
		list-style-type: none;
	}

	.lst-kix_list_12-1 > li:before {
		content: '(' counter(lst-ctn-kix_list_12-1, lower-latin) ') ';
	}

	ol.lst-kix_list_8-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_8-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_8-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_8-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_8-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_8-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_8-3 {
		list-style-type: none;
	}

	.lst-kix_list_10-4 > li {
		counter-increment: lst-ctn-kix_list_10-4;
	}

	ol.lst-kix_list_15-3.start {
		counter-reset: lst-ctn-kix_list_15-3 0;
	}

	.lst-kix_list_14-1 > li {
		counter-increment: lst-ctn-kix_list_14-1;
	}

	.lst-kix_list_13-3 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_5-8.start {
		counter-reset: lst-ctn-kix_list_5-8 0;
	}

	.lst-kix_list_1-3 > li {
		counter-increment: lst-ctn-kix_list_1-3;
	}

	.lst-kix_list_13-5 > li:before {
		content: ' ';
	}

	.lst-kix_list_12-5 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_12-2.start {
		counter-reset: lst-ctn-kix_list_12-2 0;
	}

	.lst-kix_list_12-7 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_6-0.start {
		counter-reset: lst-ctn-kix_list_6-0 0;
	}

	.lst-kix_list_4-2 > li {
		counter-increment: lst-ctn-kix_list_4-2;
	}

	.lst-kix_list_13-1 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_3-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-4.start {
		counter-reset: lst-ctn-kix_list_3-4 0;
	}

	.lst-kix_list_5-1 > li {
		counter-increment: lst-ctn-kix_list_5-1;
	}

	ol.lst-kix_list_3-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-8 {
		list-style-type: none;
	}

	.lst-kix_list_7-1 > li {
		counter-increment: lst-ctn-kix_list_7-1;
	}

	ol.lst-kix_list_11-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-6.start {
		counter-reset: lst-ctn-kix_list_2-6 0;
	}

	.lst-kix_list_3-0 > li:before {
		content: '(' counter(lst-ctn-kix_list_3-0, decimal) ') ';
	}

	ol.lst-kix_list_11-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_11-1 {
		list-style-type: none;
	}

	.lst-kix_list_4-0 > li {
		counter-increment: lst-ctn-kix_list_4-0;
	}

	.lst-kix_list_8-0 > li {
		counter-increment: lst-ctn-kix_list_8-0;
	}

	.lst-kix_list_10-0 > li {
		counter-increment: lst-ctn-kix_list_10-0;
	}

	.lst-kix_list_3-4 > li:before {
		content: ' ';
	}

	.lst-kix_list_3-3 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_3-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-6 {
		list-style-type: none;
	}

	.lst-kix_list_8-0 > li:before {
		content: 'Appendix ' counter(lst-ctn-kix_list_8-0, decimal) ' ';
	}

	ol.lst-kix_list_3-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-8 {
		list-style-type: none;
	}

	.lst-kix_list_8-7 > li:before {
		content: ' ';
	}

	.lst-kix_list_3-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_10-7.start {
		counter-reset: lst-ctn-kix_list_10-7 0;
	}

	ol.lst-kix_list_15-8.start {
		counter-reset: lst-ctn-kix_list_15-8 0;
	}

	.lst-kix_list_8-3 > li:before {
		content: '' counter(lst-ctn-kix_list_8-1, decimal) '.' counter(lst-ctn-kix_list_8-2, decimal) '.' counter(lst-ctn-kix_list_8-3, decimal) ' ';
	}

	ul.lst-kix_list_13-5 {
		list-style-type: none;
	}

	ul.lst-kix_list_13-4 {
		list-style-type: none;
	}

	ul.lst-kix_list_13-3 {
		list-style-type: none;
	}

	ul.lst-kix_list_13-2 {
		list-style-type: none;
	}

	ul.lst-kix_list_13-1 {
		list-style-type: none;
	}

	.lst-kix_list_3-7 > li:before {
		content: ' ';
	}

	.lst-kix_list_8-4 > li:before {
		content: '(' counter(lst-ctn-kix_list_8-4, lower-latin) ') ';
	}

	.lst-kix_list_10-2 > li {
		counter-increment: lst-ctn-kix_list_10-2;
	}

	ol.lst-kix_list_8-5.start {
		counter-reset: lst-ctn-kix_list_8-5 0;
	}

	ul.lst-kix_list_13-8 {
		list-style-type: none;
	}

	.lst-kix_list_11-1 > li:before {
		content: '(' counter(lst-ctn-kix_list_11-1, lower-latin) ') ';
	}

	ul.lst-kix_list_13-7 {
		list-style-type: none;
	}

	ul.lst-kix_list_13-6 {
		list-style-type: none;
	}

	.lst-kix_list_11-0 > li:before {
		content: '(' counter(lst-ctn-kix_list_11-0, decimal) ') ';
	}

	ol.lst-kix_list_9-3.start {
		counter-reset: lst-ctn-kix_list_9-3 0;
	}

	.lst-kix_list_8-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_2-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_7-2.start {
		counter-reset: lst-ctn-kix_list_7-2 0;
	}

	ol.lst-kix_list_2-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-1 {
		list-style-type: none;
	}

	.lst-kix_list_4-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_12-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-6 {
		list-style-type: none;
	}

	.lst-kix_list_4-7 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_12-7 {
		list-style-type: none;
	}

	.lst-kix_list_14-2 > li {
		counter-increment: lst-ctn-kix_list_14-2;
	}

	ol.lst-kix_list_12-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_4-8.start {
		counter-reset: lst-ctn-kix_list_4-8 0;
	}

	.lst-kix_list_8-4 > li {
		counter-increment: lst-ctn-kix_list_8-4;
	}

	ol.lst-kix_list_3-3.start {
		counter-reset: lst-ctn-kix_list_3-3 0;
	}

	ol.lst-kix_list_10-8.start {
		counter-reset: lst-ctn-kix_list_10-8 0;
	}

	ol.lst-kix_list_2-6 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-8 {
		list-style-type: none;
	}

	.lst-kix_list_11-3 > li {
		counter-increment: lst-ctn-kix_list_11-3;
	}

	ol.lst-kix_list_7-1.start {
		counter-reset: lst-ctn-kix_list_7-1 0;
	}

	ol.lst-kix_list_8-6.start {
		counter-reset: lst-ctn-kix_list_8-6 0;
	}

	ol.lst-kix_list_8-0.start {
		counter-reset: lst-ctn-kix_list_8-0 0;
	}

	.lst-kix_list_7-0 > li:before {
		content: ' ';
	}

	.lst-kix_list_2-2 > li {
		counter-increment: lst-ctn-kix_list_2-2;
	}

	ol.lst-kix_list_4-7.start {
		counter-reset: lst-ctn-kix_list_4-7 0;
	}

	ol.lst-kix_list_5-0 {
		list-style-type: none;
	}

	.lst-kix_list_3-7 > li {
		counter-increment: lst-ctn-kix_list_3-7;
	}

	ol.lst-kix_list_5-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_9-7.start {
		counter-reset: lst-ctn-kix_list_9-7 0;
	}

	ol.lst-kix_list_5-2 {
		list-style-type: none;
	}

	.lst-kix_list_2-4 > li:before {
		content: ' ';
	}

	.lst-kix_list_2-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_13-0 {
		list-style-type: none;
	}

	.lst-kix_list_6-6 > li {
		counter-increment: lst-ctn-kix_list_6-6;
	}

	ol.lst-kix_list_15-7.start {
		counter-reset: lst-ctn-kix_list_15-7 0;
	}

	.lst-kix_list_7-3 > li:before {
		content: '(' counter(lst-ctn-kix_list_7-3, upper-latin) ') ';
	}

	.lst-kix_list_10-0 > li:before {
		content: 'The Appendix ';
	}

	.lst-kix_list_9-7 > li {
		counter-increment: lst-ctn-kix_list_9-7;
	}

	.lst-kix_list_13-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_14-6.start {
		counter-reset: lst-ctn-kix_list_14-6 0;
	}

	ol.lst-kix_list_5-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_5-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_5-3 {
		list-style-type: none;
	}

	.lst-kix_list_8-7 > li {
		counter-increment: lst-ctn-kix_list_8-7;
	}

	ol.lst-kix_list_5-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-8.start {
		counter-reset: lst-ctn-kix_list_3-8 0;
	}

	ol.lst-kix_list_5-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_5-6 {
		list-style-type: none;
	}

	.lst-kix_list_7-7 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_8-1.start {
		counter-reset: lst-ctn-kix_list_8-1 0;
	}

	.lst-kix_list_15-4 > li:before {
		content: '(' counter(lst-ctn-kix_list_15-4, lower-roman) ') ';
	}

	.lst-kix_list_9-5 > li {
		counter-increment: lst-ctn-kix_list_9-5;
	}

	.lst-kix_list_5-8 > li {
		counter-increment: lst-ctn-kix_list_5-8;
	}

	.lst-kix_list_10-4 > li:before {
		content: '(' counter(lst-ctn-kix_list_10-4, lower-latin) ') ';
	}

	.lst-kix_list_10-8 > li:before {
		content: ' ';
	}

	.lst-kix_list_4-0 > li:before {
		content: '' counter(lst-ctn-kix_list_4-0, decimal) ' ';
	}

	.lst-kix_list_15-0 > li:before {
		content: '' counter(lst-ctn-kix_list_15-0, decimal) ' ';
	}

	.lst-kix_list_15-8 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_14-3.start {
		counter-reset: lst-ctn-kix_list_14-3 0;
	}

	.lst-kix_list_15-7 > li {
		counter-increment: lst-ctn-kix_list_15-7;
	}

	.lst-kix_list_4-4 > li:before {
		content: '(' counter(lst-ctn-kix_list_4-4, lower-roman) ') ';
	}

	ol.lst-kix_list_2-2.start {
		counter-reset: lst-ctn-kix_list_2-2 0;
	}

	.lst-kix_list_9-3 > li:before {
		content: '' counter(lst-ctn-kix_list_9-2, decimal) '.' counter(lst-ctn-kix_list_9-3, decimal) ' ';
	}

	ol.lst-kix_list_7-0.start {
		counter-reset: lst-ctn-kix_list_7-0 0;
	}

	.lst-kix_list_12-8 > li {
		counter-increment: lst-ctn-kix_list_12-8;
	}

	ol.lst-kix_list_4-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_4-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_4-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_4-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_14-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_14-4.start {
		counter-reset: lst-ctn-kix_list_14-4 0;
	}

	ol.lst-kix_list_14-8 {
		list-style-type: none;
	}

	.lst-kix_list_9-7 > li:before {
		content: '(' counter(lst-ctn-kix_list_9-7, upper-latin) ') ';
	}

	.lst-kix_list_2-4 > li {
		counter-increment: lst-ctn-kix_list_2-4;
	}

	ol.lst-kix_list_14-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_3-6.start {
		counter-reset: lst-ctn-kix_list_3-6 0;
	}

	ol.lst-kix_list_14-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_14-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_14-6 {
		list-style-type: none;
	}

	.lst-kix_list_11-4 > li:before {
		content: ' ';
	}

	.lst-kix_list_15-2 > li {
		counter-increment: lst-ctn-kix_list_15-2;
	}

	ol.lst-kix_list_14-0 {
		list-style-type: none;
	}

	.lst-kix_list_12-4 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_14-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_14-2 {
		list-style-type: none;
	}

	.lst-kix_list_5-3 > li {
		counter-increment: lst-ctn-kix_list_5-3;
	}

	ol.lst-kix_list_4-8 {
		list-style-type: none;
	}

	.lst-kix_list_7-4 > li {
		counter-increment: lst-ctn-kix_list_7-4;
	}

	.lst-kix_list_1-0 > li:before {
		content: '\002022   ';
	}

	ol.lst-kix_list_4-4 {
		list-style-type: none;
	}

	.lst-kix_list_11-8 > li:before {
		content: ' ';
	}

	.lst-kix_list_12-3 > li {
		counter-increment: lst-ctn-kix_list_12-3;
	}

	ol.lst-kix_list_4-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_2-0.start {
		counter-reset: lst-ctn-kix_list_2-0 0;
	}

	ol.lst-kix_list_4-6 {
		list-style-type: none;
	}

	.lst-kix_list_12-0 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_4-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_8-4.start {
		counter-reset: lst-ctn-kix_list_8-4 0;
	}

	.lst-kix_list_1-4 > li:before {
		content: ' ';
	}

	.lst-kix_list_13-0 > li:before {
		content: '(' counter(lst-ctn-kix_list_13-0, decimal) ') ';
	}

	ol.lst-kix_list_3-5.start {
		counter-reset: lst-ctn-kix_list_3-5 0;
	}

	.lst-kix_list_1-6 > li {
		counter-increment: lst-ctn-kix_list_1-6;
	}

	ol.lst-kix_list_13-0.start {
		counter-reset: lst-ctn-kix_list_13-0 0;
	}

	.lst-kix_list_14-4 > li {
		counter-increment: lst-ctn-kix_list_14-4;
	}

	.lst-kix_list_13-4 > li:before {
		content: ' ';
	}

	.lst-kix_list_10-7 > li {
		counter-increment: lst-ctn-kix_list_10-7;
	}

	.lst-kix_list_2-0 > li:before {
		content: '' counter(lst-ctn-kix_list_2-0, upper-latin) ' ';
	}

	ol.lst-kix_list_2-1.start {
		counter-reset: lst-ctn-kix_list_2-1 0;
	}

	ol.lst-kix_list_8-3.start {
		counter-reset: lst-ctn-kix_list_8-3 0;
	}

	.lst-kix_list_11-5 > li {
		counter-increment: lst-ctn-kix_list_11-5;
	}

	.lst-kix_list_4-5 > li {
		counter-increment: lst-ctn-kix_list_4-5;
	}

	ol.lst-kix_list_14-5.start {
		counter-reset: lst-ctn-kix_list_14-5 0;
	}

	ol.lst-kix_list_9-8.start {
		counter-reset: lst-ctn-kix_list_9-8 0;
	}

	.lst-kix_list_1-8 > li:before {
		content: ' ';
	}

	.lst-kix_list_12-8 > li:before {
		content: ' ';
	}

	.lst-kix_list_8-2 > li {
		counter-increment: lst-ctn-kix_list_8-2;
	}

	.lst-kix_list_4-1 > li {
		counter-increment: lst-ctn-kix_list_4-1;
	}

	ul.lst-kix_list_1-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-6.start {
		counter-reset: lst-ctn-kix_list_12-6 0;
	}

	.lst-kix_list_8-1 > li {
		counter-increment: lst-ctn-kix_list_8-1;
	}

	ol.lst-kix_list_8-2.start {
		counter-reset: lst-ctn-kix_list_8-2 0;
	}

	ol.lst-kix_list_3-1.start {
		counter-reset: lst-ctn-kix_list_3-1 0;
	}

	.lst-kix_list_15-0 > li {
		counter-increment: lst-ctn-kix_list_15-0;
	}

	ol.lst-kix_list_6-6.start {
		counter-reset: lst-ctn-kix_list_6-6 0;
	}

	.lst-kix_list_7-0 > li {
		counter-increment: lst-ctn-kix_list_7-0;
	}

	.lst-kix_list_11-0 > li {
		counter-increment: lst-ctn-kix_list_11-0;
	}

	ul.lst-kix_list_1-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-5.start {
		counter-reset: lst-ctn-kix_list_1-5 0;
	}

	ol.lst-kix_list_9-6.start {
		counter-reset: lst-ctn-kix_list_9-6 0;
	}

	.lst-kix_list_2-3 > li {
		counter-increment: lst-ctn-kix_list_2-3;
	}

	ol.lst-kix_list_4-5.start {
		counter-reset: lst-ctn-kix_list_4-5 0;
	}

	.lst-kix_list_1-2 > li {
		counter-increment: lst-ctn-kix_list_1-2;
	}

	ol.lst-kix_list_14-7.start {
		counter-reset: lst-ctn-kix_list_14-7 0;
	}

	ol.lst-kix_list_11-2.start {
		counter-reset: lst-ctn-kix_list_11-2 0;
	}

	.lst-kix_list_5-2 > li {
		counter-increment: lst-ctn-kix_list_5-2;
	}

	.lst-kix_list_9-2 > li {
		counter-increment: lst-ctn-kix_list_9-2;
	}

	ol.lst-kix_list_8-7.start {
		counter-reset: lst-ctn-kix_list_8-7 0;
	}

	.lst-kix_list_14-3 > li {
		counter-increment: lst-ctn-kix_list_14-3;
	}

	ol.lst-kix_list_15-6.start {
		counter-reset: lst-ctn-kix_list_15-6 0;
	}

	.lst-kix_list_10-3 > li {
		counter-increment: lst-ctn-kix_list_10-3;
	}

	.lst-kix_list_12-1 > li {
		counter-increment: lst-ctn-kix_list_12-1;
	}

	.lst-kix_list_3-0 > li {
		counter-increment: lst-ctn-kix_list_3-0;
	}

	ol.lst-kix_list_4-0.start {
		counter-reset: lst-ctn-kix_list_4-0 0;
	}

	ol.lst-kix_list_11-7.start {
		counter-reset: lst-ctn-kix_list_11-7 0;
	}

	ol.lst-kix_list_14-2.start {
		counter-reset: lst-ctn-kix_list_14-2 0;
	}

	ol.lst-kix_list_2-4.start {
		counter-reset: lst-ctn-kix_list_2-4 0;
	}

	ol.lst-kix_list_1-3 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-4 {
		list-style-type: none;
	}

	.lst-kix_list_2-7 > li:before {
		content: ' ';
	}

	.lst-kix_list_2-7 > li {
		counter-increment: lst-ctn-kix_list_2-7;
	}

	ol.lst-kix_list_1-5 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-6 {
		list-style-type: none;
	}

	.lst-kix_list_2-5 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_1-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_10-3.start {
		counter-reset: lst-ctn-kix_list_10-3 0;
	}

	ol.lst-kix_list_9-4.start {
		counter-reset: lst-ctn-kix_list_9-4 0;
	}

	.lst-kix_list_14-6 > li {
		counter-increment: lst-ctn-kix_list_14-6;
	}

	.lst-kix_list_10-1 > li:before {
		content: '' counter(lst-ctn-kix_list_10-1, decimal) ' ';
	}

	.lst-kix_list_7-7 > li {
		counter-increment: lst-ctn-kix_list_7-7;
	}

	ol.lst-kix_list_15-1.start {
		counter-reset: lst-ctn-kix_list_15-1 0;
	}

	ol.lst-kix_list_15-4.start {
		counter-reset: lst-ctn-kix_list_15-4 0;
	}

	ol.lst-kix_list_4-3.start {
		counter-reset: lst-ctn-kix_list_4-3 0;
	}

	ol.lst-kix_list_1-7 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-8 {
		list-style-type: none;
	}

	.lst-kix_list_10-7 > li:before {
		content: '(' counter(lst-ctn-kix_list_10-7, upper-roman) ') ';
	}

	.lst-kix_list_7-8 > li {
		counter-increment: lst-ctn-kix_list_7-8;
	}

	.lst-kix_list_10-5 > li:before {
		content: '(' counter(lst-ctn-kix_list_10-5, lower-roman) ') ';
	}

	.lst-kix_list_10-3 > li:before {
		content: '' counter(lst-ctn-kix_list_10-1, decimal) '.' counter(lst-ctn-kix_list_10-2, decimal) '.' counter(lst-ctn-kix_list_10-3, decimal) ' ';
	}

	.lst-kix_list_15-4 > li {
		counter-increment: lst-ctn-kix_list_15-4;
	}

	.lst-kix_list_2-6 > li {
		counter-increment: lst-ctn-kix_list_2-6;
	}

	ol.lst-kix_list_7-3.start {
		counter-reset: lst-ctn-kix_list_7-3 0;
	}

	.lst-kix_list_11-7 > li {
		counter-increment: lst-ctn-kix_list_11-7;
	}

	.lst-kix_list_9-2 > li:before {
		content: '' counter(lst-ctn-kix_list_9-2, decimal) ' ';
	}

	ol.lst-kix_list_5-7.start {
		counter-reset: lst-ctn-kix_list_5-7 0;
	}

	ol.lst-kix_list_14-0.start {
		counter-reset: lst-ctn-kix_list_14-0 0;
	}

	.lst-kix_list_12-5 > li {
		counter-increment: lst-ctn-kix_list_12-5;
	}

	.lst-kix_list_5-5 > li {
		counter-increment: lst-ctn-kix_list_5-5;
	}

	.lst-kix_list_9-0 > li:before {
		content: 'The Schedule ';
	}

	.lst-kix_list_3-4 > li {
		counter-increment: lst-ctn-kix_list_3-4;
	}

	ol.lst-kix_list_10-7 {
		list-style-type: none;
	}

	.lst-kix_list_9-6 > li:before {
		content: '(' counter(lst-ctn-kix_list_9-6, lower-roman) ') ';
	}

	ol.lst-kix_list_10-8 {
		list-style-type: none;
	}

	ol.lst-kix_list_10-3 {
		list-style-type: none;
	}

	.lst-kix_list_9-4 > li:before {
		content: '' counter(lst-ctn-kix_list_9-2, decimal) '.' counter(lst-ctn-kix_list_9-3, decimal) '.' counter(lst-ctn-kix_list_9-4, decimal) ' ';
	}

	ol.lst-kix_list_10-4 {
		list-style-type: none;
	}

	ol.lst-kix_list_10-5 {
		list-style-type: none;
	}

	.lst-kix_list_11-3 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_10-6 {
		list-style-type: none;
	}

	.lst-kix_list_6-3 > li {
		counter-increment: lst-ctn-kix_list_6-3;
	}

	ol.lst-kix_list_10-0 {
		list-style-type: none;
	}

	ol.lst-kix_list_10-1 {
		list-style-type: none;
	}

	ol.lst-kix_list_1-3.start {
		counter-reset: lst-ctn-kix_list_1-3 0;
	}

	ol.lst-kix_list_10-2 {
		list-style-type: none;
	}

	ol.lst-kix_list_12-1.start {
		counter-reset: lst-ctn-kix_list_12-1 0;
	}

	ol.lst-kix_list_1-2.start {
		counter-reset: lst-ctn-kix_list_1-2 0;
	}

	.lst-kix_list_11-5 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_6-1.start {
		counter-reset: lst-ctn-kix_list_6-1 0;
	}

	.lst-kix_list_9-8 > li:before {
		content: '(' counter(lst-ctn-kix_list_9-8, upper-roman) ') ';
	}

	.lst-kix_list_1-1 > li:before {
		content: '\002013   ';
	}

	.lst-kix_list_11-7 > li:before {
		content: ' ';
	}

	.lst-kix_list_8-5 > li {
		counter-increment: lst-ctn-kix_list_8-5;
	}

	.lst-kix_list_1-3 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_10-5.start {
		counter-reset: lst-ctn-kix_list_10-5 0;
	}

	.lst-kix_list_4-8 > li {
		counter-increment: lst-ctn-kix_list_4-8;
	}

	.lst-kix_list_1-7 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_2-7.start {
		counter-reset: lst-ctn-kix_list_2-7 0;
	}

	.lst-kix_list_1-5 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_9-1.start {
		counter-reset: lst-ctn-kix_list_9-1 0;
	}

	.lst-kix_list_14-7 > li {
		counter-increment: lst-ctn-kix_list_14-7;
	}

	.lst-kix_list_5-6 > li {
		counter-increment: lst-ctn-kix_list_5-6;
	}

	ol.lst-kix_list_7-5.start {
		counter-reset: lst-ctn-kix_list_7-5 0;
	}

	.lst-kix_list_2-1 > li:before {
		content: '(' counter(lst-ctn-kix_list_2-1, lower-latin) ') ';
	}

	.lst-kix_list_2-3 > li:before {
		content: ' ';
	}

	.lst-kix_list_11-8 > li {
		counter-increment: lst-ctn-kix_list_11-8;
	}

	.lst-kix_list_3-1 > li {
		counter-increment: lst-ctn-kix_list_3-1;
	}

	.lst-kix_list_9-1 > li {
		counter-increment: lst-ctn-kix_list_9-1;
	}

	ol.lst-kix_list_7-7.start {
		counter-reset: lst-ctn-kix_list_7-7 0;
	}

	.lst-kix_list_3-1 > li:before {
		content: '(' counter(lst-ctn-kix_list_3-1, decimal) ') ';
	}

	.lst-kix_list_3-2 > li:before {
		content: ' ';
	}

	.lst-kix_list_14-0 > li {
		counter-increment: lst-ctn-kix_list_14-0;
	}

	.lst-kix_list_8-1 > li:before {
		content: '' counter(lst-ctn-kix_list_8-1, decimal) ' ';
	}

	ol.lst-kix_list_1-8.start {
		counter-reset: lst-ctn-kix_list_1-8 0;
	}

	.lst-kix_list_8-2 > li:before {
		content: '' counter(lst-ctn-kix_list_8-1, decimal) '.' counter(lst-ctn-kix_list_8-2, decimal) ' ';
	}

	.lst-kix_list_6-0 > li {
		counter-increment: lst-ctn-kix_list_6-0;
	}

	.lst-kix_list_3-5 > li:before {
		content: ' ';
	}

	.lst-kix_list_12-0 > li {
		counter-increment: lst-ctn-kix_list_12-0;
	}

	ol.lst-kix_list_12-3.start {
		counter-reset: lst-ctn-kix_list_12-3 0;
	}

	ol.lst-kix_list_11-5.start {
		counter-reset: lst-ctn-kix_list_11-5 0;
	}

	.lst-kix_list_8-5 > li:before {
		content: '(' counter(lst-ctn-kix_list_8-5, lower-roman) ') ';
	}

	.lst-kix_list_11-1 > li {
		counter-increment: lst-ctn-kix_list_11-1;
	}

	.lst-kix_list_8-6 > li:before {
		content: ' ';
	}

	.lst-kix_list_2-0 > li {
		counter-increment: lst-ctn-kix_list_2-0;
	}

	.lst-kix_list_15-1 > li {
		counter-increment: lst-ctn-kix_list_15-1;
	}

	.lst-kix_list_3-6 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_5-0.start {
		counter-reset: lst-ctn-kix_list_5-0 0;
	}

	.lst-kix_list_11-2 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_4-2.start {
		counter-reset: lst-ctn-kix_list_4-2 0;
	}

	ol.lst-kix_list_11-6.start {
		counter-reset: lst-ctn-kix_list_11-6 0;
	}

	ol.lst-kix_list_12-4.start {
		counter-reset: lst-ctn-kix_list_12-4 0;
	}

	ol.lst-kix_list_10-1.start {
		counter-reset: lst-ctn-kix_list_10-1 0;
	}

	.lst-kix_list_4-4 > li {
		counter-increment: lst-ctn-kix_list_4-4;
	}

	ol.lst-kix_list_6-4.start {
		counter-reset: lst-ctn-kix_list_6-4 0;
	}

	ol.lst-kix_list_5-6.start {
		counter-reset: lst-ctn-kix_list_5-6 0;
	}

	ol.lst-kix_list_4-1.start {
		counter-reset: lst-ctn-kix_list_4-1 0;
	}

	.lst-kix_list_7-3 > li {
		counter-increment: lst-ctn-kix_list_7-3;
	}

	.lst-kix_list_15-3 > li {
		counter-increment: lst-ctn-kix_list_15-3;
	}

	ol.lst-kix_list_7-8.start {
		counter-reset: lst-ctn-kix_list_7-8 0;
	}

	.lst-kix_list_12-4 > li {
		counter-increment: lst-ctn-kix_list_12-4;
	}

	ol.lst-kix_list_11-0.start {
		counter-reset: lst-ctn-kix_list_11-0 0;
	}

	.lst-kix_list_3-3 > li {
		counter-increment: lst-ctn-kix_list_3-3;
	}

	ol.lst-kix_list_6-3.start {
		counter-reset: lst-ctn-kix_list_6-3 0;
	}

	ol.lst-kix_list_10-2.start {
		counter-reset: lst-ctn-kix_list_10-2 0;
	}

	.lst-kix_list_12-7 > li {
		counter-increment: lst-ctn-kix_list_12-7;
	}

	ol.lst-kix_list_5-5.start {
		counter-reset: lst-ctn-kix_list_5-5 0;
	}

	.lst-kix_list_6-2 > li {
		counter-increment: lst-ctn-kix_list_6-2;
	}

	.lst-kix_list_2-6 > li:before {
		content: ' ';
	}

	.lst-kix_list_14-5 > li {
		counter-increment: lst-ctn-kix_list_14-5;
	}

	.lst-kix_list_7-1 > li:before {
		content: '(' counter(lst-ctn-kix_list_7-1, lower-latin) ') ';
	}

	.lst-kix_list_7-5 > li:before {
		content: ' ';
	}

	.lst-kix_list_9-6 > li {
		counter-increment: lst-ctn-kix_list_9-6;
	}

	ol.lst-kix_list_5-4.start {
		counter-reset: lst-ctn-kix_list_5-4 0;
	}

	ol.lst-kix_list_11-1.start {
		counter-reset: lst-ctn-kix_list_11-1 0;
	}

	.lst-kix_list_13-6 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_5-1.start {
		counter-reset: lst-ctn-kix_list_5-1 0;
	}

	.lst-kix_list_6-7 > li {
		counter-increment: lst-ctn-kix_list_6-7;
	}

	.lst-kix_list_10-6 > li {
		counter-increment: lst-ctn-kix_list_10-6;
	}

	.lst-kix_list_11-6 > li {
		counter-increment: lst-ctn-kix_list_11-6;
	}

	.lst-kix_list_1-7 > li {
		counter-increment: lst-ctn-kix_list_1-7;
	}

	ol.lst-kix_list_10-0.start {
		counter-reset: lst-ctn-kix_list_10-0 0;
	}

	.lst-kix_list_7-5 > li {
		counter-increment: lst-ctn-kix_list_7-5;
	}

	.lst-kix_list_15-6 > li:before {
		content: '(' counter(lst-ctn-kix_list_15-6, upper-roman) ') ';
	}

	.lst-kix_list_11-4 > li {
		counter-increment: lst-ctn-kix_list_11-4;
	}

	.lst-kix_list_3-8 > li {
		counter-increment: lst-ctn-kix_list_3-8;
	}

	ol.lst-kix_list_6-8.start {
		counter-reset: lst-ctn-kix_list_6-8 0;
	}

	.lst-kix_list_10-2 > li:before {
		content: '' counter(lst-ctn-kix_list_10-1, decimal) '.' counter(lst-ctn-kix_list_10-2, decimal) ' ';
	}

	.lst-kix_list_4-6 > li {
		counter-increment: lst-ctn-kix_list_4-6;
	}

	ol.lst-kix_list_1-7.start {
		counter-reset: lst-ctn-kix_list_1-7 0;
	}

	.lst-kix_list_1-5 > li {
		counter-increment: lst-ctn-kix_list_1-5;
	}

	ol.lst-kix_list_6-5.start {
		counter-reset: lst-ctn-kix_list_6-5 0;
	}

	.lst-kix_list_4-2 > li:before {
		content: '' counter(lst-ctn-kix_list_4-0, decimal) '.' counter(lst-ctn-kix_list_4-1, decimal) '.' counter(lst-ctn-kix_list_4-2, decimal) ' ';
	}

	.lst-kix_list_4-6 > li:before {
		content: '(' counter(lst-ctn-kix_list_4-6, upper-roman) ') ';
	}

	.lst-kix_list_15-2 > li:before {
		content: '' counter(lst-ctn-kix_list_15-0, decimal) '.' counter(lst-ctn-kix_list_15-1, decimal) '.' counter(lst-ctn-kix_list_15-2, decimal) ' ';
	}

	.lst-kix_list_10-8 > li {
		counter-increment: lst-ctn-kix_list_10-8;
	}

	.lst-kix_list_10-6 > li:before {
		content: '(' counter(lst-ctn-kix_list_10-6, upper-latin) ') ';
	}

	.lst-kix_list_9-1 > li:before {
		content: 'Part ' counter(lst-ctn-kix_list_9-1, upper-latin) ' ';
	}

	ol.lst-kix_list_12-7.start {
		counter-reset: lst-ctn-kix_list_12-7 0;
	}

	.lst-kix_list_15-8 > li {
		counter-increment: lst-ctn-kix_list_15-8;
	}

	ol.lst-kix_list_6-7.start {
		counter-reset: lst-ctn-kix_list_6-7 0;
	}

	.lst-kix_list_12-2 > li {
		counter-increment: lst-ctn-kix_list_12-2;
	}

	.lst-kix_list_9-5 > li:before {
		content: '(' counter(lst-ctn-kix_list_9-5, lower-latin) ') ';
	}

	.lst-kix_list_12-2 > li:before {
		content: '(' counter(lst-ctn-kix_list_12-2, lower-roman) ') ';
	}

	ol.lst-kix_list_12-8.start {
		counter-reset: lst-ctn-kix_list_12-8 0;
	}

	.lst-kix_list_11-6 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_11-3.start {
		counter-reset: lst-ctn-kix_list_11-3 0;
	}

	.lst-kix_list_1-2 > li:before {
		content: ' ';
	}

	.lst-kix_list_10-1 > li {
		counter-increment: lst-ctn-kix_list_10-1;
	}

	.lst-kix_list_8-8 > li {
		counter-increment: lst-ctn-kix_list_8-8;
	}

	.lst-kix_list_1-6 > li:before {
		content: ' ';
	}

	li.li-bullet-0:before {
		margin-left: -36pt;
		white-space: nowrap;
		display: inline-block;
		min-width: 36pt;
	}

	.lst-kix_list_12-6 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_11-4.start {
		counter-reset: lst-ctn-kix_list_11-4 0;
	}

	.lst-kix_list_2-2 > li:before {
		content: '(' counter(lst-ctn-kix_list_2-2, lower-roman) ') ';
	}

	.lst-kix_list_13-2 > li:before {
		content: ' ';
	}

	ol.lst-kix_list_5-2.start {
		counter-reset: lst-ctn-kix_list_5-2 0;
	}

	ol {
		margin: 0;
		padding: 0;
	}

	table td,
	table th {
		padding: 0;
	}

	.c2 {
		margin-left: 18pt;
		padding-top: 0pt;
		padding-left: 18pt;
		padding-bottom: 6pt;
		line-height: 1;
		orphans: 2;
		widows: 2;
		text-align: justify;
	}

	.c0 {
		margin-left: 54pt;
		padding-top: 0pt;
		padding-left: 18pt;
		padding-bottom: 6pt;
		line-height: 1;
		orphans: 2;
		widows: 2;
		text-align: justify;
	}

	.c10 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10pt;
		font-family: 'Roboto';
		font-style: italic;
	}

	.c4 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c8 {
		color: #000000;
		font-weight: 400;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 8pt;
		font-family: 'Arial';
		font-style: normal;
	}

	.c9 {
		padding-top: 0pt;
		padding-bottom: 0pt;
		line-height: 0.75;
		orphans: 2;
		widows: 2;
		text-align: center;
		height: 10pt;
	}

	.c6 {
		margin-left: 36pt;
		padding-top: 0pt;
		padding-bottom: 6pt;
		line-height: 1;
		orphans: 2;
		widows: 2;
		text-align: justify;
	}

	.c5 {
		color: #000000;
		font-weight: 700;
		text-decoration: none;
		vertical-align: baseline;
		font-size: 10pt;
		font-family: 'Roboto';
		font-style: normal;
	}

	.c3 {
		background-color: #ffffff;
		max-width: 476.2pt;
		padding: 62.4pt 59.5pt 62.4pt 59.5pt;
	}

	.c1 {
		padding: 0;
		margin: 0;
	}

	.c11 {
		font-weight: 400;
		font-family: 'Roboto';
	}

	.c7 {
		page-break-after: avoid;
	}

	.title {
		padding-top: 12pt;
		color: #000000;
		font-weight: 700;
		font-size: 16pt;
		padding-bottom: 3pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		orphans: 2;
		widows: 2;
		text-align: center;
	}

	.subtitle {
		padding-top: 12pt;
		color: #000000;
		font-weight: 700;
		font-size: 10pt;
		padding-bottom: 12pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: center;
	}

	li {
		color: #000000;
		font-size: 10pt;
		font-family: 'Arial';
	}

	p {
		margin: 0;
		color: #000000;
		font-size: 10pt;
		font-family: 'Arial';
	}

	h1 {
		padding-top: 12pt;
		color: #000000;
		font-weight: 700;
		font-size: 10pt;
		padding-bottom: 12pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: justify;
	}

	h2 {
		padding-top: 12pt;
		color: #000000;
		font-size: 13pt;
		padding-bottom: 12pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: justify;
	}

	h3 {
		padding-top: 12pt;
		color: #000000;
		font-size: 13pt;
		padding-bottom: 12pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: justify;
	}

	h4 {
		padding-top: 12pt;
		color: #000000;
		font-size: 13pt;
		padding-bottom: 12pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: justify;
	}

	h5 {
		padding-top: 11pt;
		color: #000000;
		font-weight: 700;
		font-size: 11pt;
		padding-bottom: 2pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}

	h6 {
		padding-top: 10pt;
		color: #000000;
		font-weight: 700;
		font-size: 10pt;
		padding-bottom: 2pt;
		font-family: 'Arial';
		line-height: 1.1500000000000001;
		page-break-after: avoid;
		orphans: 2;
		widows: 2;
		text-align: left;
	}
</style>
