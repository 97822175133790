<template>
	<v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
		<v-card tile>
			<v-toolbar flat dark color="primary" class="pl-3 pr-3 flex-grow-0">
				<v-row>
					<v-col cols="12" md="2" class="d-flex pa-0">
						<v-toolbar-title class="align-self-center">
							Change log
						</v-toolbar-title>
					</v-col>
					<v-spacer />
					<v-col cols="12" md="2" class="d-flex pa-0 justify-end">
						<v-btn icon dark class="align-self-center" @click="dialog = false">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-col>
				</v-row>
			</v-toolbar>
			<v-container fluid class="pa-6 ma-0 mx-auto" style="max-width: 1200px">
				<v-sheet>
					<v-timeline style="margin-left: -70%">
						<v-timeline-item v-for="(item) in logs" :key="item.id" right small color="primary">
							<template #opposite>
								<div class="text-body-2 font-weight-bold primary--text ma-0">
									v{{ item.version }}
								</div>
							</template>
							<v-sheet>
								<p class="text-h6 mb-2">
									{{ item.title }}
								</p>
								<div v-html="item.content" />
								<p class="text-body-2 info--text font-italic">
									Posted: {{ new Date(item.posted).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) }}
								</p>
							</v-sheet>
						</v-timeline-item>
					</v-timeline>
				</v-sheet>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		data() {
			return {
				dialog: false
			};
		},

		computed: {
			...mapState('App', ['logs'])
		},

		methods: {
			open() {
				this.dialog = true;
			}
		}
	};
</script>
