<template>
	<div>
		<v-app-bar v-if="$route" app clipped-left color="white" class="elevation-1">
			<v-toolbar-title class="pl-2">
				<span>
					LLL Navigator
					<span class="text-caption blue-grey--text d-inline-block">v{{ version }}</span>
				</span>
			</v-toolbar-title>
			<v-spacer />
			<v-toolbar-title class="pr-2 text-right">
				<a class="text-body-1 text-decoration-underline" @click="$refs.changelog?.open()"> Change log </a>
				<span class="text-caption blue-grey--text d-block"> Last Update: {{ latestLogDate }} </span>
			</v-toolbar-title>
		</v-app-bar>
		<changelog-dialog ref="changelog" />
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import ChangelogDialog from '@/components/app/changelog';

	export default {
		name: 'app-layout-header',

		components: {
			ChangelogDialog
		},

		computed: {
			...mapState('App', ['logs']),

			version() {
				return process.env.PACKAGE_VERSION;
			},

			latestLog() {
				const max = this.logs.reduce((prev, current) => {
					console.log(prev);
					console.log(current);
					const date1 = new Date(prev.posted);
					const date2 = new Date(current.posted);
					return date1 > date2 ? prev : current;
				});
				return max;
			},

			latestLogDate() {
				return new Date(this.latestLog?.posted)?.toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
			}
		}
	};
</script>
