<!--
	@name app-layout
	@description Front page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<div v-if="isBlank">
			<router-view />
		</div>

		<div v-else>
			<!-- header -->
			<layout-header />

			<!-- sidebar -->
			<!-- <layout-sidebar ref="sidebar" /> -->

			<!-- content -->
			<v-main :style="`min-height: calc(100vh - ${footerHeight}px)`">
				<!-- breadcrumb -->
				<layout-breadcrumb />

				<div class="px-6" style="padding-bottom: 120px">
					<router-view />
				</div>
			</v-main>
			<!-- footer -->
			<layout-footer ref="footer" />
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import LayoutHeader from './header';
	import LayoutFooter from './footer';
	import LayoutBreadcrumb from './breadcrumb';

	export default {
		components: {
			LayoutHeader,
			LayoutFooter,
			LayoutBreadcrumb
		},

		data() {
			return {
				footerHeight: 0
			};
		},

		computed: {
			...mapState('App', ['site']),

			isBlank() {
				return this.$route.meta?.layout === 'blank';
			}
		},

		created() {
			this.$vuetify.theme.themes.light = this.site.theme;
		},

		mounted() {
			this.footerHeight = this.$refs.footer.$el?.offsetHeight;
		}
	};
</script>
