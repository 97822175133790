<template>
	<div>
		<v-sheet elevation="3" rounded class="pa-6">
			<div class="d-flex mb-1">
				<p class="text-h6 primary--text mb-0">
					Affordability
				</p>
				<v-spacer />
				<p class="text-body-2 primary--text mb-0">
					Step 2 of 3
				</p>
			</div>

			<p class="mb-6">
				Based on the details provided in step one, we've calculated the available disposable income for loan payments. Now, please ask the customer about their preferred monthly repayment amount, taking into consideration their ability to sustain it both before and after
				retirement.
			</p>

			<v-sheet rounded class="pa-6 grey lighten-5" style="background: linear-gradient(135deg, rgb(245, 245, 245) 35%, rgba(25, 118, 210, 0.15) 100%)">
				<v-row no-gutters>
					<v-col class="grow">
						<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
							Customer 1 - Disposable Income
						</p>
					</v-col>

					<dynamic-natural-language-form v-if="!isCustomerRetired" ref="customDisposableIncome" :form-schema="incomePreRetirementForm" :form-data="formData" :validated="validation.customDisposableIncome" :submit-button="false" @input="customDisposableIncomeUpdated" />
					<dynamic-natural-language-form
						ref="customDisposableIncomeRetirement"
						:form-schema="incomePostRetirementForm"
						:form-data="formData"
						:validated="validation.customDisposableIncomeRetirement"
						:submit-button="false"
						@input="customDisposableIncomeRetirementUpdated"
					/>

					<v-row v-if="numberOfClients === 2" no-gutters class="mt-6">
						<v-col class="grow">
							<p class="text-body-1 font-weight-medium text-uppercase primary--text mb-1">
								Customer 2 - Disposable Income
							</p>

							<dynamic-natural-language-form
								v-if="!isCustomer2Retired"
								ref="customDisposableIncome2"
								:form-schema="incomePreRetirement2Form"
								:form-data="formData"
								:validated="validation.customDisposableIncome2"
								:submit-button="false"
								@input="customDisposableIncome2Updated"
							/>
							<dynamic-natural-language-form
								ref="customDisposableIncomeRetirement2"
								:form-schema="incomePostRetirement2Form"
								:form-data="formData"
								:validated="validation.customDisposableIncomeRetirement2"
								:submit-button="false"
								@input="customDisposableIncomeRetirement2Updated"
							/>
						</v-col>
					</v-row>
				</v-row>
				<div class="mt-6">
					<validation-observer ref="notesObserver">
						<validation-provider v-slot="{ errors }" name="Notes" :rules="'max:1000'">
							<v-textarea v-model="formData.customerIncomeNotes" hint="Use this section to record the customers preferred monthly repayments and why there is a change where applicable." persistent-hint :error-messages="errors" label="Notes" outlined rows="3" />
						</validation-provider>
					</validation-observer>
				</div>
			</v-sheet>

			<div class="d-flex mt-6">
				<v-btn color="primary" large outlined @click="$emit('go-back')">
					Back
				</v-btn>
				<v-spacer />
				<v-btn color="primary" large :disabled="!isAllValid" @click="nextStep">
					Next Step
				</v-btn>
			</div>
		</v-sheet>

		<common-dialog ref="helpDialog" :max-width="800" color="primary">
			<template #header>
				Affordability
			</template>
			<template #body>
				<v-sheet class="pt-5">
					<p class="text-body-1">
						In step two we capture how much your customer(s) would be comfortable committing to pre retirement (where applicable) and post retirement.
					</p>
					<p class="text-body-1">
						Again you are presented with a series of questions and like step one, any fields presented with
						<span class="inline-field error--text">red underlined font</span> need to be interacted with before being able to proceed to the next step.
					</p>
					<p class="text-body-1">
						You will also see that the
						<v-chip class="text-center" label small color="grey lighten-2">
							<span> Customer Preferred Affordability </span>
						</v-chip>
						indicator on the right will change colour depending on the information entered.
					</p>
					<p class="text-body-1 mb-0">
						Remember to expand the mini table
						<v-btn icon class="">
							<v-icon color="primary">
								mdi-table-eye
							</v-icon>
						</v-btn>
						once this step is completed to see a full breakdown of your customer(s) eligibility.
					</p>
				</v-sheet>
			</template>
			<template #footer>
				<v-card-actions class="pa-4">
					<v-checkbox v-model="hideHelpDialog" hide-details label="Do not show this again" class="ma-0 pa-0" @change="helpDialogChanged" />
				</v-card-actions>
			</template>
		</common-dialog>
	</div>
</template>

<script>
	import { $localStorage } from '@/utils/storage';
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import CommonDialog from '@/components/common/dialog';
	import DynamicNaturalLanguageForm from '@/components/common/dynamic-natural-language-form';

	export default {
		components: {
			ValidationObserver,
			ValidationProvider,
			CommonDialog,
			DynamicNaturalLanguageForm
		},

		props: {
			estimatedDisposableIncome1: { type: Number, required: true },
			estimatedDisposableIncome2: { type: Number, required: true },
			estimatedDisposableIncomeRetirement1: { type: Number, required: true },
			estimatedDisposableIncomeRetirement2: { type: Number, required: true },
			isCustomerRetired: { type: Boolean, required: true },
			isCustomer2Retired: { type: Boolean, required: true },
			numberOfClients: { type: Number, required: true },
			formData: { type: Object, required: true },
			validation: { type: Object, required: true }
		},

		data() {
			return {
				hideHelpDialog: false
			};
		},

		computed: {
			/**
			 * @name incomePreRetirementForm
			 * @description Returns the form schema for the pre-retirement income form for customer 1
			 * @returns {Object}
			 */
			incomePreRetirementForm() {
				return {
					text: `<strong>Pre-Retirement</strong>, your customer's <strong>monthly</strong> disposable income is calculated to be <strong>£${this.$options.filters.numFormat(
						this.estimatedDisposableIncome1,
						'0,00.00'
					)}</strong>, during which time, they're comfortable committing to monthly repayments of {customDisposableIncome}.`,
					fields: [{ type: 'number', name: 'customDisposableIncome', prefix: '£', display: 'Preferred pre-retirement amount', rules: 'required|decimal:2', format: '0,00.00', currency: true }]
				};
			},

			/**
			 * @name incomePostRetirementForm
			 * @description Returns the form schema for the post-retirement income form for customer 1
			 * @returns {Object}
			 */
			incomePostRetirementForm() {
				return {
					text: `<strong>Post-Retirement</strong>, your customer's <strong>monthly</strong> disposable income is calculated to be <strong>£${this.$options.filters.numFormat(
						this.estimatedDisposableIncomeRetirement1,
						'0,00.00'
					)}</strong>, during which time, they're comfortable committing to monthly repayments of {customDisposableIncomeRetirement}.`,
					fields: [{ type: 'number', name: 'customDisposableIncomeRetirement', prefix: '£', display: 'Preferred post-retirement amount', rules: 'required|decimal:2', format: '0,00.00', currency: true }]
				};
			},

			/**
			 * @name incomePreRetirement2Form
			 * @description Returns the form schema for the pre-retirement income form for customer 2
			 * @returns {Object}
			 */
			incomePreRetirement2Form() {
				return {
					text: `<strong>Pre-Retirement</strong>, your customer's <strong>monthly</strong> disposable income is calculated to be <strong>£${this.$options.filters.numFormat(
						this.estimatedDisposableIncome2,
						'0,00.00'
					)}</strong>, during which time, they're comfortable committing to monthly repayments of {customDisposableIncome2}.`,
					fields: [{ type: 'number', name: 'customDisposableIncome2', prefix: '£', display: 'Preferred pre-retirement amount', rules: 'required|decimal:2', format: '0,00.00', currency: true }]
				};
			},

			/**
			 * @name incomePostRetirement2Form
			 * @description Returns the form schema for the post-retirement income form for customer 2
			 * @returns {Object}
			 */
			incomePostRetirement2Form() {
				return {
					text: `<strong>Post-Retirement</strong>, your customer's <strong>monthly</strong> disposable income is calculated to be <strong>£${this.$options.filters.numFormat(
						this.estimatedDisposableIncomeRetirement2,
						'0,00.00'
					)}</strong>, during which time, they're comfortable committing to monthly repayments of {customDisposableIncomeRetirement2}.`,
					fields: [{ type: 'number', name: 'customDisposableIncomeRetirement2', prefix: '£', display: 'Preferred post-retirement amount', rules: 'required|decimal:2', format: '0,00.00', currency: true }]
				};
			},

			/**
			 * @name isAllValid
			 * @description Returns true if all validation states are valid
			 * @returns {Boolean}
			 */
			isAllValid() {
				let validation = {
					customDisposableIncome: this.isCustomerRetired || this.validation.customDisposableIncome,
					customDisposableIncomeRetirement: this.validation.customDisposableIncomeRetirement,
					customDisposableIncome2: this.numberOfClients === 1 || this.isCustomer2Retired || this.validation.customDisposableIncome2,
					customDisposableIncomeRetirement2: this.numberOfClients === 1 || this.validation.customDisposableIncomeRetirement2
				};

				return Object.keys(validation).every((key) => !!validation[key]);
			}
		},

		mounted() {
			this.$vuetify.goTo(0, { duration: 0 });
			if (!$localStorage.get('hideStep3HelpDialog')) this.$refs.helpDialog.open();
		},

		methods: {
			/**
			 * @name customDisposableIncomeUpdated
			 * @description Updates the validation state of the customDisposableIncome field
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			customDisposableIncomeUpdated(formData, isValid) {
				this.$set(this.validation, 'customDisposableIncome', isValid);
			},

			/**
			 * @name customDisposableIncome2Updated
			 * @description Updates the validation state of the customDisposableIncome2 field
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			customDisposableIncome2Updated(formData, isValid) {
				this.$set(this.validation, 'customDisposableIncome2', isValid);
			},

			/**
			 * @name customDisposableIncomeRetirementUpdated
			 * @description Updates the validation state of the customDisposableIncomeRetirement field
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			customDisposableIncomeRetirementUpdated(formData, isValid) {
				this.$set(this.validation, 'customDisposableIncomeRetirement', isValid);
			},

			/**
			 * @name customDisposableIncomeRetirement2Updated
			 * @description Updates the validation state of the customDisposableIncomeRetirement2 field
			 * @param {Object} formData
			 * @param {Boolean} isValid
			 */
			customDisposableIncomeRetirement2Updated(formData, isValid) {
				this.$set(this.validation, 'customDisposableIncomeRetirement2', isValid);
			},

			/**
			 * @name nextStep
			 * @description Emits the next-step event
			 */
			async nextStep() {
				let isValid = await this.$refs.notesObserver.validate();
				if (isValid) this.$emit('next-step');
			},

			/**
			 * @name helpDialogChanged
			 * @description Updates the hideHelpDialog state
			 */
			helpDialogChanged() {
				$localStorage.set('hideStep3HelpDialog', this.hideHelpDialog);
			}
		}
	};
</script>

<style scoped lang="scss">
	.inline-field {
		display: inline-block;
		font-weight: 600;
		border-bottom: 2px dotted #979797;
		line-height: 1.3;
		cursor: pointer;
	}
</style>
