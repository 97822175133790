<template>
	<div>
		<v-speed-dial v-model="fab" top right direction="bottom" absolute fixed open-on-hover style="top: 84px">
			<template #activator>
				<v-btn v-model="fab" color="blue darken-2" dark large fab>
					<v-icon v-if="fab">
						mdi-close
					</v-icon>
					<v-icon v-else>
						mdi-tools
					</v-icon>
				</v-btn>
			</template>
			<v-btn bottom color="pink" fab dark small @click="helpDialogOpen">
				<v-icon>mdi-help-box</v-icon>
			</v-btn>
			<v-btn bottom color="deep-orange" fab dark small @click="disclaimerDialogOpen">
				<v-icon>mdi-alert-box</v-icon>
			</v-btn>
			<v-btn bottom color="teal" fab dark small @click="supportingMaterialDialogOpen">
				<v-icon>mdi-link</v-icon>
			</v-btn>
		</v-speed-dial>

		<supporting-material-dialog ref="supportingMaterialDialog" />
		<help-dialog ref="helpDialog" />
		<disclaimer-dialog ref="disclaimerDialog" />
	</div>
</template>

<script>
	import HelpDialog from './help-dialog';
	import DisclaimerDialog from './disclaimer-dialog';
	import SupportingMaterialDialog from './supporting-material-dialog';

	export default {
		name: 'app-eligibility-priorities-toolbox',

		components: {
			HelpDialog,
			DisclaimerDialog,
			SupportingMaterialDialog
		},

		data() {
			return {
				fab: false
			};
		},

		methods: {
			/**
			 * @name helpDialogOpen
			 * @description Opens the help dialog
			 */
			helpDialogOpen() {
				this.$refs.helpDialog.open();
			},

			/**
			 * @name disclaimerDialogOpen
			 * @description Opens the disclaimer dialog
			 */
			disclaimerDialogOpen() {
				this.$refs.disclaimerDialog.open();
			},

			/**
			 * @name supportingMaterialDialogOpen
			 * @description Opens the supporting material dialog
			 */
			supportingMaterialDialogOpen() {
				this.$refs.supportingMaterialDialog.open();
			}
		}
	};
</script>

<style></style>
