<!--
	@name app-layout-account-footer
	@description Footer for account page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div>
		<v-footer class="ma-0 pa-0 flex-column" inset>
			<v-row no-gutters class="pa-6 mx-auto">
				<v-col cols="9" class="mb-0">
					<p class="text-caption text-left mb-0">
						This assessment tool is the property of and provided by Answers in Retirement Ltd t/a Air for use by intermediaries only on a free of charge basis subject
						to full terms and conditions of use which are available <a @click="openTerms">here</a>. It provides an indication only as to affordability/products and does not constitute
						financial advice under applicable law, nor is it or any of its outputs intended to be provided direct to customers. Answers in Retirement Ltd is not
						regulated by the Financial Conduct Authority. All trademarks and copyright on this page are owned by Answers in Retirement Ltd. Any personal data submitted
						via the tool will be treated in accordance with our applicable <a @click="openPrivacy">privacy notice</a>. Please ensure you have your customer's consent
						and/or correct permissions to share their personal data before submission. Answers in Retirement Limited, Registered in England number 09991416 with
						registered office PO Box GL4 4QT, Southgate House, Southgate Street, Gloucester, Gloucestershire, United Kingdom, GL1 1UD.
					</p>
				</v-col>
				<v-col cols="3" class="mb-0 text-right">
					<div class="flex">
						<img width="140px" height="auto" :src="'logo/site/powered-by-air.svg' | urlize" style="vertical-align: bottom;" />
					</div>
				</v-col>
			</v-row>
			<v-divider width="100%" />
			<!-- <v-row no-gutters class="pa-4 text-caption font-weight-bold">
				<p class="mb-0">
					T: {{ site?.contact?.number }}
				</p>
				<v-divider vertical class="mx-3" />
				<p class="mb-0">
					E: <a :href="`mailto:${site?.contact?.email}`">{{ site?.contact?.email }}</a>
				</p>
			</v-row> -->
		</v-footer>

		<app-common-terms ref="terms" @open-privacy="openPrivacy" />
		<app-common-privacy ref="privacy" @open-terms="openTerms" />
	</div>
</template>

<script>
	import AppCommonTerms from '@/components/common/terms';
	import AppCommonPrivacy from '@/components/common/privacy-policy';
	import { mapState } from 'vuex';

	export default {
		name: 'app-layout-account-footer',

		components: {
			AppCommonTerms,
			AppCommonPrivacy
		},

		computed: {
			...mapState('App', ['site']),

			year() {
				return new Date().getFullYear();
			}
		},

		methods: {
			openTerms() {
				this.$refs.terms.open();
			},

			openPrivacy() {
				this.$refs.privacy.open();
			}
		}
	};
</script>
