import VeeValidate from 'vee-validate';
// import { AccountUserDetail, AccountUserIdentity } from '@/service';
import { isNil } from 'lodash';

// const isUserEmailUnique = (email) =>
// 	AccountUserDetail.validate({ email }).then((response) => ({
// 		valid: response.data.valid
// 	}));

// const isUserIdentityUnique = (type, identity) =>
// 	AccountUserIdentity.validate({ type, identity }).then((response) => ({
// 		valid: response.data.valid
// 	}));

const isNilOrEmpty = (value) => isNil(value) || value === '';

/**
 * @name min_length
 * @description Minimum length validation for array type models
 */
VeeValidate.Validator.extend('min_length', {
	getMessage: (field, [ruleValue]) => `Please select at least ${ruleValue} ${field}`,
	validate: (value, [ruleValue]) => value.length >= ruleValue
});

/**
 * @name verify_password
 * @description Password strength validation
 */
VeeValidate.Validator.extend('verify_password', {
	getMessage: () => `The password must be at least 8 characters and must contain an uppercase letter, a lowercase letter, a number, and a special character.`,
	validate: (value) => {
		var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*\"'()+,-./:;<=>?[\]^_`{|}~])(?=.{8,})/;
		var passwordRegex = new RegExp(regex);
		// this regex checks if one upper/lower/number/special new RegExp('^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$');
		console.log(passwordRegex.test(value));
		return passwordRegex.test(value);
	}
});

/**
 * @name verify_password
 * @description Password strength validation
 */
VeeValidate.Validator.extend('required_true', {
	getMessage: () => `You must confirm to proceed`,
	validate: (value) => !!value
});

/**
 * @name verify_username
 * @description Username validation to allow anything but whitespace
 */
VeeValidate.Validator.extend('verify_username', {
	getMessage: () => `The username cannot contain whitespace.`,
	validate: (value) => {
		var regex = /^[^\s]+$/;
		var usernameRegex = new RegExp(regex);
		return usernameRegex.test(value);
	}
});

// /**
//  * @name is_unique_email
//  * @description Existing e-mail address validation
//  */
// VeeValidate.Validator.extend('is_unique_email', {
// 	validate: (value) => isUserEmailUnique(value),
// 	getMessage: () => 'Invalid e-mail address'
// });

// /**
//  * @name is_unique_username
//  * @description Existing username validation
//  */
// VeeValidate.Validator.extend('is_unique_username', {
// 	validate: (value) => isUserIdentityUnique('username', value),
// 	getMessage: () => 'Invalid username'
// });

/**
 * @name greater_than
 * @description Custom comparative validator
 */
VeeValidate.Validator.extend(
	'greater_than',
	{
		getMessage: () => 'The max value should be greater than or equal to the min value',
		validate: (value, [otherValue]) => value >= otherValue
	},
	{ hasTarget: true }
);

/**
 * @name less_than
 * @description Custom comparative validator
 */
VeeValidate.Validator.extend(
	'less_than',
	{
		getMessage: () => 'The min value should be less than or equal to the max value',
		validate: (value, [otherValue]) => (!isNaN(otherValue) ? value <= otherValue : true)
	},
	{ hasTarget: true }
);

VeeValidate.Validator.extend('is_directly_authorised', {
	validate: (value) => value === true,
	getMessage: () => 'You must be either a member of a Network or Directly Authorised to register'
});

/**
 * @name postcode
 * @description Postcode validation
 */
VeeValidate.Validator.extend('postcode', {
	getMessage: () => `The postcode field should contain a valid postcode`,
	validate: (value) => {
		const postcodeRegex =
			/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
		return postcodeRegex.test(value);
	}
});

/**
 * @name required_if_not
 * @description Required if target field is falsy
 */
VeeValidate.Validator.extend(
	'required_if_not',
	{
		validate: (value, [otherValue]) => {
			return {
				valid: !(isNilOrEmpty(otherValue) && isNilOrEmpty(value)),
				data: {
					required: isNilOrEmpty(otherValue)
				}
			};
		}
	},
	{ hasTarget: true, computesRequired: true }
);

export default VeeValidate;
