<template>
	<v-container v-if="loaded" style="max-width: 1200px">
		<v-row align="start" no-gutters>
			<v-col>
				<p v-if="step > 1" class="text-h5 font-weight-bold px-3 py-4">
					<span class="primary--text"> Customer Reference:</span> {{ customerReference }}
				</p>

				<v-sheet v-if="step === 1" elevation="3" rounded class="pa-8 pt-2">
					<v-timeline reverse class="pt-6">
						<p class="text-body-1 font-weight-medium mb-10">
							Let's get started
						</p>
						<v-slide-y-transition group>
							<template v-for="(message, index) in messages">
								<!-- Text input for the other steps -->
								<v-timeline-item v-if="message.id && message.id === 1 && chatStep === 0" :key="index" color="primary" small :right="message.isBot" :left="!message.isBot">
									<v-card :color="!message.isBot ? 'grey lighten-4' : 'white'" class="py-2 px-6" width="400">
										<div class="d-flex flex-row align-center">
											<div class="flex-grow-1">
												<validation-observer ref="referenceObserver">
													<validation-provider v-slot="{ errors }" name="Customer Reference" :rules="'required'">
														<v-text-field v-model="customerReference" :error-messages="errors" placeholder="Type Something" @keypress.enter="submitReference" />
													</validation-provider>
												</validation-observer>
											</div>
											<v-btn icon class="ml-4" @click="submitReference">
												<v-icon>mdi-send</v-icon>
											</v-btn>
										</div>
									</v-card>
								</v-timeline-item>
								<v-timeline-item v-else-if="message?.id === 2 && chatStep === 1" :key="index" color="primary" small :right="message.isBot" :left="!message.isBot">
									<v-card :color="!message.isBot ? 'grey lighten-4' : 'white'" class="py-2 px-6" width="400">
										<div class="d-flex flex-row align-center">
											<div class="flex-grow-1">
												<validation-observer ref="fcaNumberObserver">
													<validation-provider v-slot="{ errors }" name="Firm Reference Number (FRN)" :rules="'required|numeric|min:6|max:7'">
														<v-text-field v-model="fcaNumber" :error-messages="errors" placeholder="Firm Reference Number (FRN)" @keypress.enter="submitFcaNumber" />
													</validation-provider>
												</validation-observer>
											</div>
											<v-btn icon class="ml-4" @click="submitFcaNumber">
												<v-icon>mdi-send</v-icon>
											</v-btn>
										</div>
									</v-card>
								</v-timeline-item>
								<v-timeline-item v-else :key="index" color="primary" small :right="message.isBot" :left="!message.isBot">
									<v-avatar v-if="message.isBot" size="56" class="mr-6 mt-1" rounded="0">
										<img alt="Avatar" :src="'logo/site/chatbot.png' | urlize" />
									</v-avatar>
									<v-card :color="!message.isBot ? 'grey lighten-4' : 'white'">
										<v-card-text v-if="message.text" class="text-body-1 grey--text text--darken-2">
											{{ message.text }}
										</v-card-text>
										<v-card-text v-if="message.posted" class="text-body-2 font-italic pt-0">
											{{ message.posted }}
										</v-card-text>
									</v-card>
								</v-timeline-item>
							</template>
						</v-slide-y-transition>
					</v-timeline>

					<div v-if="chatStep === 2" class="text-center">
						<v-divider class="mt-6 mb-10" />
						<v-btn color="primary" x-large @click="chatNextStep">
							Next Step
						</v-btn>
					</div>
				</v-sheet>

				<v-sheet v-else-if="step === 2">
					<v-row class="mb-1">
						<v-col cols="3">
							<v-sheet max-width="400">
								<validation-observer ref="borrowingObserver">
									<validation-provider v-slot="{ errors }" name="Borrowing Amount" rules="decimal">
										<v-text-field v-model.number="borrowing" label="Borrowing Amount" step="any" type="number" :error-messages="errors" prefix="£" dense outlined hide-details />
									</validation-provider>
								</validation-observer>
							</v-sheet>
						</v-col>
						<v-col cols="3">
							<v-sheet max-width="400">
								<validation-observer ref="budgetObserver">
									<validation-provider v-slot="{ errors }" name="Customer Budget" rules="decimal">
										<v-text-field v-model.number="customerBudget" label="Customer Budget" step="any" type="number" :error-messages="errors" prefix="£" dense outlined hide-details />
									</validation-provider>
								</validation-observer>
							</v-sheet>
						</v-col>

						<v-col cols="6" class="text-right">
							<v-sheet class="text-body-2 grey--text">
								<div class="mb-0">
									Estimated cost of borrowing comparison over 15 years
								</div>
								<div>Please note that the figures below are for illustrative purposes only</div>
							</v-sheet>
						</v-col>
					</v-row>

					<v-simple-table class="elevation-2 productTable">
						<thead>
							<tr>
								<th
									v-for="(column, index) in cols"
									:key="index"
									width="300"
									class="primary white--text text-body-2 font-weight-bold py-4"
									:class="{ 'text-center': index }"
									style="border-bottom: 1px solid #ffffff !important"
									:style="index === 0 ? '' : 'border-left: 1px solid #ffffff !important;'"
								>
									{{ column.title }}
									<div v-if="column.subtitle" class="text-caption">
										{{ column.subtitle }}
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, rowIndex) in rows" :key="row.value" :class="row.class">
								<td v-for="(column, index) in cols" :key="column" class="text-center" width="170">
									<div v-if="index === 0" class="mx-n4 px-3 py-5" style="background-color: #1976d2">
										<div v-if="rowIndex < 4" class="font-weight-bold text-body-2 white--text">
											{{ row.title }}
											<div class="text-caption">
												{{ row.subtitle }}
											</div>
										</div>
										<div v-else class="text-body-2 white--text">
											<validation-observer v-if="activeInput.row === rowIndex && activeInput.column === index" ref="titleObserver">
												<validation-provider v-slot="{ errors }" name="title" :rules="''">
													<v-text-field ref="activeInput" v-model="rows[rowIndex].title" color="white" class="pt-2" :error-messages="errors" dense dark @blur="resetActiveInput($event, errors, index, rowIndex)" />
												</validation-provider>
											</validation-observer>
											<a v-else class="text-body-1 font-weight-bold text-body-2 text-center px-3 white--text text-decoration-underline edit d-block" style="position: relative; height: 20px" @click="openInput(rowIndex, index)">
												<span>
													{{ rows[rowIndex].title }}
													<v-icon color="white" right small style="position: absolute; right: 0; bottom: 0.1rem"> mdi-pencil </v-icon>
												</span>
											</a>
											<div class="text-caption">
												{{ row.subtitle }}
											</div>
											<v-btn color="error" class="mt-1" x-small @click="removeRow(rowIndex)">
												Remove
											</v-btn>
										</div>
									</div>
									<div v-else>
										<span v-if="isNotApplicable(rowIndex, index)" class="text-body-1 font-italic"> N/A </span>
										<span v-else-if="isMonthlyRepaymentFixed(rowIndex, index)" class="text-body-1 font-italic"> £{{ rowIndex === 0 ? monthlyRepaymentFixedCapitalInterest.monthlyRepayment : monthlyRepaymentFixed[1] | numFormat('0,00.00') }} </span>
										<span v-else-if="isMonthlyRepaymentSvr(rowIndex, index)" class="text-body-1 font-italic"> £{{ rowIndex === 0 ? monthlyRepaymentSvrCapitalInterest : monthlyRepaymentSvr[1] | numFormat('0,00.00') }} </span>
										<span v-else-if="index === 5 && !row.value.includes('custom')" class="text-h6 font-italic">
											£{{ rowIndex === 0 ? totalCostCapitalInterest : rowIndex === 1 ? totalCost[1] : rowIndex === 2 ? totalCostLifetimeOptionalPayments : totalCostLifetime | numFormat('0,00.00') }}
										</span>

										<div v-else-if="(column.type === 'input' || row.value.includes('custom')) && activeInput.row === rowIndex && activeInput.column === index" class="d-flex align-center">
											<validation-observer ref="paymentObserver">
												<validation-provider v-slot="{ errors }" name="Monthly Repayment" rules="max_value:10000">
													<v-text-field
														ref="activeInput"
														v-model.number="input[column.value][rowIndex]"
														class="pt-2"
														step="any"
														type="number"
														:disabled="input[column.value][rowIndex] === false"
														:error-messages="errors"
														:suffix="row.suffix"
														:prefix="row.prefix"
														dense
														@blur="resetActiveInput($event, errors, index, rowIndex)"
													/>
												</validation-provider>
											</validation-observer>
											<div v-if="row.value.includes('custom')">
												<v-tooltip bottom>
													<template #activator="{ on }">
														<v-btn v-if="input[column.value][rowIndex] === false" color="success" icon name="inputModifier" v-on="on" @click="enableInput(column.value, rowIndex)">
															<v-icon small>
																mdi-lock-open-variant-outline
															</v-icon>
														</v-btn>
														<v-btn v-else color="grey" icon name="inputModifier" v-on="on" @click="disableInput(column.value, rowIndex)">
															<v-icon small>
																mdi-lock-off-outline
															</v-icon>
														</v-btn>
													</template>
													<span>{{ input[column.value][rowIndex] === false ? 'Enable' : 'Disable' }}</span>
												</v-tooltip>
											</div>
										</div>

										<a v-else class="text-decoration-underline edit" :class="row.value.includes('custom') && index === 5 ? 'text-h6 font-italic' : 'text-body-1'" style="position: relative" @click="openInput(rowIndex, index)">
											<v-tooltip bottom :disabled="column.value !== 'aer'">
												<template #activator="{ on }">
													<span v-on="on">
														<span v-if="input[column.value][rowIndex] === false"> N/A </span>
														<template v-else-if="column.prefix"> {{ column.prefix }}{{ input[column.value][rowIndex] | numFormat('0,00.00') }} </template>
														<template v-else-if="column.suffix"> {{ input[column.value][rowIndex] | numFormat('0.00') }}{{ column.suffix }} </template>
														<v-icon color="primary" right small style="position: absolute; right: -1.2em; bottom: 0.1rem"> mdi-pencil </v-icon>
													</span>
												</template>
												<span>AER</span>
											</v-tooltip>
										</a>
									</div>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
					<v-sheet v-if="rows.length < 6" class="mt-4">
						<v-tooltip bottom>
							<template #activator="{ on }">
								<v-btn color="success" @click="addRow" v-on="on">
									Add custom product
								</v-btn>
							</template>
							<span>Add custom product</span>
						</v-tooltip>
					</v-sheet>

					<v-divider inset class="my-10" />

					<p class="text-body-1 font-weight-medium text-uppercase primary--text">
						Additional information
					</p>
					<v-textarea v-model="nextSteps" label="Additional information" hint="Section for the Adviser to document any additional information" outlined persistent-hint rows="3" />

					<div class="d-flex mt-10">
						<v-spacer />

						<v-btn color="primary" large @click="generatePdf">
							Save as PDF
						</v-btn>
					</div>
				</v-sheet>
			</v-col>
		</v-row>
		<supporting-material-dialog ref="supportingMaterialDialog" />
		<help-dialog ref="helpDialog" />
		<disclaimer-dialog ref="disclaimerDialog" />
		<v-speed-dial v-model="fab" top right direction="bottom" absolute fixed open-on-hover style="top: 84px">
			<template #activator>
				<v-btn v-model="fab" color="blue darken-2" dark large fab>
					<v-icon v-if="fab">
						mdi-close
					</v-icon>
					<v-icon v-else>
						mdi-tools
					</v-icon>
				</v-btn>
			</template>
			<v-btn bottom color="pink" fab dark small @click="helpDialogOpen">
				<v-icon>mdi-help-box</v-icon>
			</v-btn>
			<v-btn bottom color="deep-orange" fab dark small @click="disclaimerDialogOpen">
				<v-icon>mdi-alert-box</v-icon>
			</v-btn>
			<v-btn bottom color="teal" fab dark small @click="supportingMaterialDialogOpen">
				<v-icon>mdi-link</v-icon>
			</v-btn>
		</v-speed-dial>
	</v-container>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from 'vee-validate';
	import { v4 as uuid } from 'uuid';
	import HelpDialog from './help-dialog';
	import DisclaimerDialog from './disclaimer-dialog';
	import SupportingMaterialDialog from './supporting-material-dialog';
	import { mapActions, mapState } from 'vuex';

	const MAX_BORROWING = 1000000;

	export default {
		components: {
			ValidationObserver,
			ValidationProvider,
			HelpDialog,
			DisclaimerDialog,
			SupportingMaterialDialog
		},

		data() {
			return {
				loaded: false,
				id: null,
				messages: [],
				chatStep: 0,
				step: 1,
				customerReference: null,
				fcaNumber: null,
				nextSteps: null,
				fab: false,
				customerBudget: null,
				borrowing: null,
				input: {
					aer: [0, 0, 0, 0, 0, 0],
					svr: [0, 0, 0, 0, 0, 0],
					budget: [0, 0, 0, 0, 0, 0],
					repaymentFixed: [0, 0, 0, 0, 0, 0],
					repaymentSvr: [0, 0, 0, 0, 0, 0],
					total: [0, 0, 0, 0, 0, 0]
				},
				activeInput: {
					row: null,
					column: null
				},

				rows: [
					{ title: 'Standard residential', subtitle: '(Fixed rate 5 year C&I)', value: 'standard' },
					{ title: 'RIO', subtitle: '(Fixed rate 5 year IO)', value: 'rio' },
					{ title: 'Lifetime', subtitle: '(Optional Payments)', value: 'lifetimeOptional' },
					{ title: 'Lifetime', subtitle: '(No Payments)', value: 'lifetimeNo' }
					// { title: 'Custom', subtitle: 'Custom Row', value: 'custom' }
				],

				cols: [
					{ title: '' },
					{ title: 'Fixed Rate', subtitle: '(%)', suffix: '%', value: 'aer', type: 'input' },
					{ title: 'SVR', subtitle: '(%)', suffix: '%', value: 'svr', type: 'input' },
					{ title: 'Monthly repayment', subtitle: '(fixed)', prefix: '£', value: 'repaymentFixed', type: 'input' },
					{ title: 'Monthly repayment', subtitle: '(SVR)', prefix: '£', value: 'repaymentSvr' },
					{ title: 'Total cost of borrowing', value: 'total', prefix: '£', class: 'grey lighten-2' }
				]
			};
		},

		computed: {
			...mapState('App', ['site']),

			/**
			 * @name monthlyRepaymentFixed
			 * @description Returns the monthly repayment for the fixed rate
			 */
			monthlyRepaymentFixed() {
				let arr = [];

				this.rows.forEach((row, index) => {
					if (index > 0) {
						let monthlyRepayment = 0;
						let borrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
						let aer = this.input.aer[index - 1] / 100 / 12;

						if (borrowing > 0) {
							monthlyRepayment = borrowing * aer;
						}

						arr.push(monthlyRepayment);
					}
				});

				return arr;
			},

			/**
			 * @name monthlyRepaymentSvr
			 * @description Returns the monthly repayment for the svr rate
			 */
			monthlyRepaymentSvr() {
				let arr = [];

				this.rows.forEach((row, index) => {
					if (index > 0) {
						let monthlyRepayment = 0;
						let borrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
						let svr = this.input.svr[index - 1] / 100 / 12;

						if (borrowing > 0) {
							monthlyRepayment = borrowing * svr;
						}

						arr.push(monthlyRepayment);
					}
				});

				return arr;
			},

			/**
			 * @name monthlyRepaymentFixedCapitalInterest
			 * @description Returns the monthly repayment of capital and interest product in the fixed rate
			 */
			monthlyRepaymentFixedCapitalInterest() {
				let monthlyRepayment = 0;
				let borrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
				let remainingBalance = borrowing;
				let aer = this.input.aer[0] / 100 / 12;

				if (!aer) return { monthlyRepayment, remainingBalance };

				for (let i = 0; i < 60; i++) {
					let interest = remainingBalance * aer;
					let totalPayment = this.pmt(aer, 180 - i, -remainingBalance);
					let principalPayment = totalPayment - interest;
					remainingBalance -= principalPayment;
					if (i === 59) monthlyRepayment = totalPayment;
					if (remainingBalance <= 0) break;
				}

				return { monthlyRepayment, remainingBalance };
			},

			/**
			 * @name monthlyRepaymentSvrCapitalInterest
			 * @description Returns the monthly repayment of capital and interest product in the svr rate
			 */
			monthlyRepaymentSvrCapitalInterest() {
				let monthlyRepayment = 0;
				let borrowing = this.monthlyRepaymentFixedCapitalInterest.remainingBalance;
				let svr = this.input.svr[0] / 100 / 12;

				if (borrowing > 0) {
					monthlyRepayment = this.pmt(svr, 120, -borrowing);
				}

				return monthlyRepayment || 0;
			},

			/**
			 * @name totalCostCapitalInterest
			 * @description Returns the total cost of borrowing for the capital and interest product
			 */
			totalCostCapitalInterest() {
				let totalCost = 0;
				let borrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
				let totalInterestAer = this.monthlyRepaymentFixedCapitalInterest.monthlyRepayment * 60;
				let totalInterestSvr = this.monthlyRepaymentSvrCapitalInterest * 120;

				if (borrowing > 0) {
					totalCost = totalInterestAer + totalInterestSvr;
				}

				return totalCost || borrowing;
			},

			/**
			 * @name totalCost
			 * @description Returns the total cost of borrowing for the fixed and svr products
			 */
			totalCost() {
				let arr = [];

				this.rows.forEach((row, index) => {
					if (index > 0) {
						let totalCost = 0;
						let borrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
						let totalInterestAer = this.monthlyRepaymentFixed[index - 1] * 60;
						let totalInterestSvr = this.monthlyRepaymentSvr[index - 1] * 120;

						if (borrowing > 0) {
							totalCost = borrowing + totalInterestAer + totalInterestSvr;
						}

						arr.push(totalCost);
					}
				});

				return arr;
			},

			/**
			 * @name totalCostLifetime
			 * @description Returns the total cost of borrowing for the lifetime product
			 */
			totalCostLifetime() {
				let borrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
				let interest = this.input.aer[3];
				let totalCost = borrowing * Math.pow(1 + interest / 100, 15);
				return totalCost;
			},

			/**
			 * @name totalCostLifetimeOptionalPayments
			 * @description Calculates the total cost of borrowing for the lifetime optional payments product.
			 */
			totalCostLifetimeOptionalPayments() {
				const initialBorrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
				const loanTermYears = 15;
				const fixedVoluntaryPayment = this.input.repaymentFixed[2];
				const aer = this.input.aer[2];
				let dataSet = [];
				let payments = [];
				let loanCost;

				for (let index = 0; index < loanTermYears; index++) {
					let loanBalanceFirst = index === 0 ? parseFloat(initialBorrowing) : dataSet[index - 1].loanBalanceFinal;
					let interest = 0;
					let payment = 0;

					if (index + 1 <= 15) {
						let obj = this.createInterestPaymentsObject(loanBalanceFirst, aer, loanTermYears, fixedVoluntaryPayment * 12, false);
						interest = obj.interest;
						payment = payments[index] = obj.payment;
					}

					let loanBalanceFinal = Number(loanBalanceFirst + interest - payment);
					if (loanBalanceFinal < 0) loanBalanceFinal = 0;
					loanCost = (index === 0 ? parseFloat(loanBalanceFirst) : dataSet[index - 1].loanCost) + interest;

					dataSet.push({
						payment,
						loanBalanceFirst,
						interest,
						loanBalanceFinal,
						loanCost
					});
				}

				return loanCost;
			},

			/**
			 * @name currentDateTime
			 * @description Returns the current date and time
			 */
			currentDateTime() {
				return this.$moment().format('DD/MM/YYYY HH:mm');
			},

			/**
			 * @name optionalPaymentsAer
			 * @description Returns the aer for the lifetime optional payments product
			 */
			optionalPaymentsAer() {
				return this.input.aer[3];
			},

			/**
			 * @name optionalPaymentsBorrowing
			 * @description Returns the borrowing for the lifetime optional payments product
			 */
			optionalPaymentsBorrowing() {
				return this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
			},

			/**
			 * @name scheduledMonthlyPayment
			 * @description Returns the scheduled monthly payment for the lifetime optional payments product
			 */
			scheduledMonthlyPayment() {
				let monthlyRepayment = 0;
				let borrowing = this.borrowing > MAX_BORROWING ? MAX_BORROWING : this.borrowing;
				let aer = this.input.aer[2] / 100 / 12;

				if (borrowing > 0) {
					monthlyRepayment = borrowing * aer;
				}

				return monthlyRepayment.toFixed(2);
			},

			MAX_BORROWING() {
				return MAX_BORROWING;
			},

			/**
			 * @name s3Key
			 * @description S3 key for form data
			 * @returns {Object}
			 */
			s3Key() {
				if (!this.id) return null;
				return `product-comparison/form/${this.id}.json`;
			},

			/**
			 * @name payload
			 * @description Constructs the payload for the API request
			 * @returns {Object}
			 */
			payload() {
				const payload = {
					id: this.id,
					reference: this.customerReference,
					fcaNumber: this.fcaNumber,
					step: this.step
				};

				if (this.step > 1) {
					payload.input = this.input;
					payload.nextSteps = this.nextSteps;
					payload.rows = this.rows;
					payload.customerBudget = this.customerBudget;
					payload.borrowing = this.borrowing;
				}

				return payload;
			}
		},

		watch: {
			optionalPaymentsAer: {
				handler() {
					this.input.repaymentFixed[2] = this.scheduledMonthlyPayment;
				},
				deep: true
			},

			optionalPaymentsBorrowing: {
				handler() {
					this.input.repaymentFixed[2] = this.scheduledMonthlyPayment;
				},
				deep: true
			}
		},

		mounted() {
			this.init();
		},

		methods: {
			...mapActions('App', ['getJson', 'updateJson', 'productComparisonPdf']),

			/**
			 * @name init
			 * @description Initialises the component
			 */
			async init() {
				if (this.$route.query.id) {
					this.id = this.$route.query.id;
					try {
						const { data } = await this.getJson({ s3Key: this.s3Key, filename: `${this.id}.json` });
						this.setData(data);
					} catch (error) {
						alert('Failed to load existing session'); // TODO: Use snackbar?
					}
				} else {
					this.id = uuid();

					setTimeout(() => {
						this.messages.push({
							text: 'First things first, lets add a reference for your customer - most people tend to use a surname or a numerical reference.',
							posted: 'Just now',
							isBot: true
						});
						this.messages.push({ id: 1, isBot: false });
					}, 250);
				}

				this.loaded = true;
			},

			/**
			 * @name setData
			 * @description Gets the data from API and populates the fields
			 */
			setData(data) {
				this.step = data.step || 1;
				this.customerReference = data.reference;
				this.fcaNumber = data.fcaNumber;

				if (this.step === 1) {
					this.messages = [
						{ text: 'First things first, lets add a reference for your customer - most people tend to use a surname or a numerical reference.', posted: 'Just now', isBot: true },
						{ text: data.reference, isBot: false },
						{ text: `Thanks, we'll use "${data.reference}" as your reference. Next, can you enter your FCA Firm Reference Number?`, posted: 'Just now', isBot: true },
						{ text: data.fcaNumber, isBot: false },
						{ text: `Thanks for providing your Firm Reference Number. When you're ready, go to next step to load up the calculator. Remember to save a PDF for your records as this session will not be saved.`, posted: 'Just now', isBot: true }
					];
					if (data.step) {
						this.step++; // Only go to next step if available in the form data from s3
						if (!data.input) this.setDefaultRates(); // If no input data, set default rates
					}
					this.chatStep = 2;
					return;
				}

				if (data.input) this.input = data.input;
				if (data.nextSteps) this.nextSteps = data.nextSteps;
				if (data.rows) this.rows = data.rows;
				if (data.customerBudget) this.customerBudget = data.customerBudget;
				if (data.borrowing) this.borrowing = data.borrowing;
			},

			/**
			 * @name submitReference
			 * @description Submits the answer to the bot
			 */
			async submitReference() {
				let success = await this.$refs.referenceObserver[0].validate();
				if (!success) return;

				this.chatStep++;
				this.messages.push({ text: this.customerReference, isBot: false });
				this.messages = this.messages.filter((msg) => msg.id !== 1);
				this.messages.push({
					text: `Thanks, we'll use "${this.customerReference}" as your reference. Next, can you enter your FCA Firm Reference Number?`,
					posted: 'Just now',
					isBot: true
				});
				this.messages.push({ id: 2, isBot: false });
			},

			/**
			 * @name submitFcaNumber
			 * @description Submits the answer to the bot
			 */
			async submitFcaNumber() {
				let success = await this.$refs.fcaNumberObserver[0].validate();
				if (!success) return;

				this.chatStep++;
				this.messages.push({ text: this.fcaNumber, isBot: false });
				this.messages = this.messages.filter((msg) => msg.id !== 2);
				this.messages.push({
					text: `Thanks for providing your Firm Reference Number. When you're ready, go to next step to load up the calculator. Remember to save a PDF for your records as this session will not be saved.`,
					posted: 'Just now',
					isBot: true
				});
			},

			/**
			 * @name chatNextStep
			 * @description Event handler for the next step button
			 */
			chatNextStep() {
				this.setDefaultRates();
				this.step++;
				this.updateJson({ s3Key: this.s3Key, payload: this.payload });
			},

			/**
			 * @name addRow
			 * @description Adds a new row
			 */
			addRow() {
				this.rows.push({ title: 'Custom', subtitle: `(Custom Product ${this.rows.length - 3})`, value: `custom${this.rows.length - 3}` });
			},

			/**
			 * @name removeRow
			 * @description Removes a row
			 */
			removeRow(index) {
				this.rows.splice(index, 1);

				this.input.aer.splice(index, 1);
				this.input.aer.push(0);

				this.input.svr.splice(index, 1);
				this.input.svr.push(0);

				// this.input.borrowing.splice(index, 1);
				// this.input.borrowing.push(0);

				this.input.repaymentFixed.splice(index, 1);
				this.input.repaymentFixed.push(0);

				this.input.repaymentSvr.splice(index, 1);
				this.input.repaymentSvr.push(0);

				this.input.total.splice(index, 1);
				this.input.total.push(0);
			},

			/**
			 * @name setDefaultRates
			 * @description Sets the default rates
			 */
			setDefaultRates() {
				this.input.aer = [this.site.data.standard.aer, this.site.data.rio.aer, this.site.data.lifetimeOptional.aer, this.site.data.lifetime.aer, 0, 0];
				this.input.svr = [this.site.data.standard.svr, this.site.data.rio.svr, 0, 0, 0, 0];
			},

			/**
			 * @name pmt
			 * @description Calculates the monthly repayment
			 */
			pmt(rate, nper, pv) {
				return (rate * pv * Math.pow(1 + rate, nper)) / (1 - Math.pow(1 + rate, nper));
			},

			/**
			 * @name helpDialogOpen
			 * @description Opens the help dialog
			 */
			helpDialogOpen() {
				this.$refs.helpDialog.open();
			},

			/**
			 * @name disclaimerDialogOpen
			 * @description Opens the disclaimer dialog
			 */
			disclaimerDialogOpen() {
				this.$refs.disclaimerDialog.open();
			},

			/**
			 * @name supportingMaterialDialogOpen
			 * @description Opens the supporting material dialog
			 */
			supportingMaterialDialogOpen() {
				this.$refs.supportingMaterialDialog.open();
			},

			/**
			 * @name openInput
			 * @description Opens the input field
			 */
			openInput(row, column) {
				this.activeInput.row = row;
				this.activeInput.column = column;

				this.$nextTick(() => {
					const btn = this.$refs.activeInput;
					btn[0].focus();
				});
			},

			disableInput(value, index) {
				this.$set(this.input[value], index, false);

				this.activeInput.row = null;
				this.activeInput.column = null;
			},

			enableInput(value, index) {
				this.$set(this.input[value], index, null);

				this.$nextTick(() => {
					const btn = this.$refs.activeInput;
					btn[0].focus();
				});
			},

			/**
			 * @name resetActiveInput
			 * @description Resets the active input
			 */
			resetActiveInput($event, errors, index) {
				if (errors?.length && index === 4) {
					this.$set(this.input.repaymentFixed, 2, this.scheduledMonthlyPayment);
				}

				if ($event.relatedTarget?.name !== 'inputModifier') {
					this.activeInput.row = null;
					this.activeInput.column = null;
				}
			},

			isNotApplicable(row, column) {
				return ([2, 3].includes(row) && column === 2) || ([3].includes(row) && column === 3) || ([2, 3].includes(row) && column === 4);
			},

			isMonthlyRepaymentFixed(row, column) {
				return column === 3 && (row === 0 || row === 1);
			},

			isMonthlyRepaymentSvr(row, column) {
				return column === 4 && (row === 0 || row === 1);
			},

			/**
			 * @name createInterestPaymentsObject
			 * @description Creates an object containing the interest and payment for the lifetime optional payments product.
			 */
			createInterestPaymentsObject(balance, rate, term, payment) {
				let interest = 0;

				for (var i = 0; i < 12; i++) {
					let _interest = (balance * parseFloat(rate)) / 12 / 100;
					balance = balance + _interest - payment / 12;
					interest += _interest;
				}

				return { interest, payment };
			},

			/**
			 * @name generatePdf
			 * @description Generates the PDF
			 */
			async generatePdf() {
				// update payload

				const payload = {
					id: this.id,
					customerReference: this.customerReference,
					fcaNumber: this.fcaNumber,
					currentDateTime: this.currentDateTime,
					input: this.input,
					monthlyRepaymentFixedCapitalInterest: this.monthlyRepaymentFixedCapitalInterest,
					monthlyRepaymentFixed: this.monthlyRepaymentFixed,
					monthlyRepaymentSvrCapitalInterest: this.monthlyRepaymentSvrCapitalInterest,
					monthlyRepaymentSvr: this.monthlyRepaymentSvr,
					totalCostCapitalInterest: this.totalCostCapitalInterest || 0,
					totalCost: this.totalCost,
					totalCostLifetimeOptionalPayments: this.totalCostLifetimeOptionalPayments || 0,
					totalCostLifetime: this.totalCostLifetime,
					nextSteps: this.nextSteps,
					rows: this.rows,
					customerBudget: this.customerBudget || 0,
					borrowing: this.borrowing || 0
				};

				try {
					const { filename, base64 } = await Promise.all([this.updateJson({ s3Key: this.s3Key, payload: this.payload }), this.productComparisonPdf(payload)]).then((responses) => responses[1].data);

					const downloadLink = document.createElement('a');
					downloadLink.href = `data:application/pdf;base64,${base64}`;
					downloadLink.download = filename;
					downloadLink.click();
					downloadLink.remove();

					if (window.opener) {
						window.opener.postMessage({ [this.id]: base64 }, '*');
						setTimeout(window.close, 100);
					}
				} catch (error) {
					alert(error.message); // TODO: Use snackbar?
				}
			}
		}
	};
</script>

<style scoped lang="scss">
	a.edit {
		.v-icon {
			display: none;
		}
		&:hover {
			.v-icon {
				display: block;
			}
		}
	}
	.productTable {
		font-size: 0.9rem;
		thead {
			background-color: #ddd;
		}
	}

	.v-timeline::before,
	.v-timeline::after {
		height: 0;
	}

	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(odd):not(.v-timeline-item--after) .v-timeline-item__body > .v-card::after,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--before .v-timeline-item__body > .v-card::after {
		left: -10px;
		right: initial;
		transform: rotate(0deg);
	}

	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before) .v-timeline-item__body > .v-card::after,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card:not(.v-card--link)::before,
	.v-application--is-ltr .v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body > .v-card::after {
		right: -10px;
		left: initial;
		transform: rotate(180deg);
	}

	::v-deep {
		.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item:nth-child(even):not(.v-timeline-item--before),
		.v-timeline--reverse:not(.v-timeline--dense) .v-timeline-item--after {
			.v-timeline-item__body {
				justify-content: flex-end;
			}
		}

		.v-timeline-item__body {
			display: flex;
			max-width: calc(70% - 48px) !important;
			align-items: center;
		}
		.v-timeline-item__divider {
			display: none;
		}
	}
</style>
